import { newRegistrationModel, servicesRegistrationModel, subagentRegistrationModel, vehicleRegistrationModel } from '../models'
import { cloneDeep } from 'lodash'
import { RegistrationOrigin } from '@mapit/core-dynamodb/dist/types'
import {SET_EX_DEALER_BIKE} from "@/vuex/mutation-types"
import coreApi from "@/api/core.api";

const getInitialState = () => newRegistrationModel()
const state = {
  ...getInitialState(),
  exDealerBike: null
}

const getters = {
  getRegistration: (state) => state,
  getCurrentStep: (state) => state.currentStep,
  getVehicle: (state) => state.vehicle,
  getDevice: (state) => state.device,
  getVinStatus: (state) => state.vehicle.vin.status,
  getServices: (state) => state.services,
  getAccount: (state) => state.account,
  getHasHondaMapitService: (state) => state.services['honda-mapit'].selected,
  getId: (state) => state.id,
  getOfferNumberValue: (state) => state.offerNumber.value,
  getOfferNumber: (state) => state.offerNumber,
  getHasGoModel: (state) => state.hasGoModel,
  getExDealerBike: (state) => state.exDealerBike
}

const actions = {
  resetRegistration: ({commit, dispatch}) => {
    return Promise.all([
      commit("resetRegistration"),
      dispatch("startRegistration"),
      dispatch("changeCurrentStep", 1)
    ]);
  },
  startRegistration: ({commit, rootState}) => {
    commit("startRegistration", {
      id: null,
      dealerId: (rootState.dealerMid?.includes('"') ? JSON.parse(rootState.dealerMid): rootState.dealerMid) || null
    })
  },
  setDealerId: ({commit}, dealerId) => {
    commit("setDealerId", dealerId )
  },
  changeCurrentStep: ({commit}, nextStep) => {
    commit("changeCurrentStep", {
      currentStep: nextStep
    })
  },
  changeOfferNumber: ({commit}, offerNumber) => {
    commit("changeOfferNumber", offerNumber)
  },
  addVehicle: ({commit}, vehicle ) => {
    commit("addVehicleToRegistration", vehicle)
  },
  addServices: ({commit}, services ) => {
    commit("addServicesToRegistration", services)
  },
  addAccount: ({commit}, account ) => {
    commit("addAccountToRegistration", account)
  },
  addDevice: ({commit}, device ) => {
    commit("addDeviceToRegistration", device)
  },
  addSubagent: ({commit}, subagent ) => {
    commit("addSubagentToRegistration", subagent)
  },
  setId({commit}, id) {
    commit("setId", id)
  },
  setDemoBike({commit}, demoBike) {
    commit("setDemoBike", demoBike)
  },
  setHasGoModel({commit}, hasGoModel) {
    commit("setHasGoModel", hasGoModel)
  },
  initTransferRegistration({commit}, selectedTransfer) {
    const newVehicle = vehicleRegistrationModel()
    const newSubagent = subagentRegistrationModel()
    const newServices = servicesRegistrationModel()

    //vehicle
    newVehicle.vin.value = selectedTransfer.vehicle.vin
    newVehicle.model.value = selectedTransfer.vehicle.model
    newVehicle.registrationNumber.value = selectedTransfer.vehicle.registrationNumber
    commit("addVehicleToRegistration", newVehicle)
    newSubagent.exist = false
    commit("addSubagentToRegistration", newSubagent)

    //services and mapit
    newServices['honda-mapit'].selected = true
    newServices['honda-mapit'].value = selectedTransfer.device.imei
    commit("addServicesToRegistration", newServices)
    commit("addDeviceToRegistration", selectedTransfer.device)

    //transfer
    commit("setVehicleTransferId", selectedTransfer.id)

    //change step
    commit("changeCurrentStep", { currentStep: 3 })

  },
  loadVehicleByRegistrationNumber: ({commit}, registrationNumber) => {
    return coreApi
      .getVehicleByRegistrationNumber(registrationNumber)
      .catch(e => {
        console.error(e)
        throw e
      })
  },
}

const mutations = {
  resetRegistration: (state) => {
    Object.assign(state, newRegistrationModel())
  },
  startRegistration: (state, payload) => {
    state.id = payload.id
    state.dealerId = payload.dealerId
  },
  setDealerId: (state, dealerId) => {
    state.dealerId = dealerId
  },
  changeCurrentStep: (state, payload) => {
    state.currentStep = payload.currentStep
  },
  changeOfferNumber: (state, offerNumber) => {
    state.offerNumber.value = offerNumber
  },
  addVehicleToRegistration: (state, vehicle) => {
    state.vehicle = cloneDeep(vehicle)
  },
  addDeviceToRegistration: (state, device) => {
    state.device = cloneDeep(device)
  },
  addServicesToRegistration: (state, services) => {
    state.services = cloneDeep(services)
  },
  addSubagentToRegistration: (state, subagent) => {
    state.subagent = cloneDeep(subagent)
  },
  addAccountToRegistration: (state, account) => {
    state.account = cloneDeep(account)
  },
  setId: (state, id) => {
    state.id = id
  },
  setDemoBike: (state, demoBike) => {
    state.vehicle.demoBike = demoBike
  },
  setVehicleTransferId: (state, vehicleTransferId) => {
    state.origin = vehicleTransferId ? RegistrationOrigin.Resale : RegistrationOrigin.Sale
    state.deviceTransfer.id = vehicleTransferId
  },
  setHasGoModel: (state, hasGoModel) => {
    state.hasGoModel = hasGoModel
  },
  [SET_EX_DEALER_BIKE]: (state, exDealerBike) => {
    state.exDealerBike = exDealerBike

    if (exDealerBike) {
      state.vehicle.registrationNumber.value = exDealerBike.vehicle.registrationNumber
      state.device = exDealerBike.device
    }
  },
  RESET_STORE(state) {
    const initialState = getInitialState()
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key]
    })
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations
}
