var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"id":"users-table-list","loading":_vm.loading,"loading-text":_vm.$t('honda.users.list.loading'),"headers":_vm.headers,"items":_vm.filteredUsers,"item-class":_vm.getRowClass,"items-per-page":10,"sort-by":['created'],"sort-desc":[true],"footer-props":{
    showFirstLastPage: true,
    firstIcon: 'fas fa-angle-double-left',
    lastIcon: 'fas fa-angle-double-right',
    prevIcon: 'fas fa-angle-left',
    nextIcon: 'fas fa-angle-right',
    'items-per-page-text': _vm.$t('honda.registration.list.footer_num_x_page_client'),
    'items-per-page-options': [10, 25, 50, -1],
  },"no-data-text":_vm.$t(_vm.usersList && _vm.usersList.length > 0 ? 'honda.users.list.no_data_search' : 'honda.users.list.no_data', {search: _vm.search}),"no-results-text":_vm.$t('honda.users.list.no_data_search'),"data-cy":"users-table-list"},on:{"click:row":_vm.selectUser},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" "+_vm._s(_vm.$t('honda.registration.list.of'))+" "+_vm._s(props.itemsLength)+" ")]}},{key:"item.created",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(_vm.getCreatedData(item.created)))])]}},{key:"item.ownerLogin",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"customer py-3 lbl-registration-bold",attrs:{"data-cy":"customer-name-email"}},[_vm._v(" "+_vm._s(item.fullName)),_c('br'),_c('span',{staticClass:"customer-email"},[_vm._v(_vm._s(item.ownerLogin))])])]}},{key:"item.phone",fn:function(ref){
  var item = ref.item;
return [_c('m-phone-label',{staticClass:"text-item",attrs:{"data-cy":"customer-phone","value":item.phone}})]}},{key:"item.deviceCount",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.deviceCount))])]}},{key:"item.containerCount",fn:function(ref){
  var item = ref.item;
return [_c('span',{staticClass:"text-item"},[_vm._v(_vm._s(item.containerCount))])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }