


























import moment from 'moment'
import { computed, defineComponent, onMounted, provide, ref, watch } from '@vue/composition-api'
import { OfferType, ProbabilityOfSale } from '@mapit/honda-offers-builder/dist/types'

import { Status } from '@mapit/honda-offers-builder/dist/types'
import { useGtm } from '@/plugins/gtm'
import { useI18n } from '@/i18n'
import { useModels } from '@/composables'
import apiOffers from '@/api/offers.api'
import ridoApi from '@/api/rido.api'

import SimpleOfferForm from './forms/SimpleOfferForm.vue'
import StepperOfferForm from './forms/StepperOfferForm.vue'

export default defineComponent({
  name: 'CreateOfferMotorbike',
  components: { StepperOfferForm, SimpleOfferForm },
  props: {
    id: {
      type: String,
      required: false,
    },
    leadId: {
      type: String,
      required: false,
    },
  },
  setup(props, { root }) {
    const { t } = useI18n()
    const gtm = useGtm()

    const loading = ref(false)
    const isOfferCreating = ref(false)
    const mainLoading = ref(false)
    const hideDetails = ref(true)
    const resumeOffer = ref<any>(null)
    const isEditing = ref(false)
    const focus = ref<string | null>(null)

    const branch = computed(() => {
      return root.$store.getters.getBranch
    })

    const complementsList = computed(() => {
      return root.$store.getters.getComplements
    })

    const campaign = computed(() => {
      return root.$store.getters.getCampaign
    })

    const group = computed(() => {
      return root.$store.getters.getGroup
    })

    const offer = computed(() => {
      return root.$store.getters.getOffer
    })

    const subagentName = computed(() => {
      return root.$store.getters.getSubagentName
    })

    const hasAttendedBy = computed(() => {
      return root.$store.getters.getAttendedBy != null && root.$store.getters.getAttendedBy != ''
    })

    const attendedBy = computed(() => {
      return root.$store.getters.getAttendedBy
    })

    const useSimpleForm = computed(() => {
      return root.$route.params.id
    })

    const offerType = computed(() => {
      return root.$store.getters.getOfferType
    })

    const isSubagent = computed(() => {
      return offerType.value === OfferType.subagent || false
    })

    const {
      models,
      loading: isModelsLoading,
      summaryLoading: isModelsSummaryLoading,
      lastImportTimestamp,
      getSummaryByIdMaterial,
    } = useModels({
      branch: branch.value,
    })

    provide('models', models)
    provide('isModelsLoading', isModelsLoading)
    provide('isModelsSummaryLoading', isModelsSummaryLoading)
    provide('lastImportTimestamp', lastImportTimestamp)
    provide('getSummaryByIdMaterial', getSummaryByIdMaterial)
    provide('isEditing', isEditing)
    provide('leadId', props.leadId)

    provide(
      'mainLoading',
      computed(() => mainLoading.value),
    )

    watch(
      [isModelsLoading, isModelsSummaryLoading],
      ([isModelsLoading, isModelsSummaryLoading]) => {
        loading.value = isModelsLoading || isModelsSummaryLoading
      },
    )

    watch(mainLoading, async () => {
      const collapseStatus = focus.value === 'email' ? 'decollapsePanel' : 'collapsePanel'
      await root.$store.dispatch(collapseStatus, 1)
    })

    onMounted(async () => {
      mainLoading.value = true

      const focusQuery = root.$route.query?.focus as string | null
      focus.value = focusQuery ?? null

      if (root.$route.params.id) {
        await root.$store.dispatch('clearOffer')
        await root.$store.dispatch('setGroupToOffer', group.value)
        await root.$store.dispatch('loadOfferById', root.$route.params.id)

        hideDetails.value = false
        isEditing.value = true
        if (!offer.value.id) root.$router.push({ name: 'Offers' })
      } else {
        await root.$store.dispatch('initNewOffer', { leadId: props.leadId })
        await root.$store.dispatch('setGroupToOffer', group.value)
        await root.$store.dispatch('setClientSearchToOffer')

        isEditing.value = false
      }

      mainLoading.value = false
    })

    function createOffer(redirect: any, cb: any) {
      isOfferCreating.value = true
      offer.value.probabilityOfSale = ProbabilityOfSale.Low // TODO: remove when cleared from builder

      const offerResult = offer.value.toOffer()

      if (isSubagent.value) {
        root.$store.dispatch('addSubagentSuggestion', subagentName.value)
      }

      hasAttendedBy.value && root.$store.dispatch('addAttendedBySuggestions', attendedBy.value)
      campaign.value && root.$store.dispatch('addDealerCampaignSuggestions', campaign.value)

      if (complementsList.value && complementsList.value.length > 0) {
        complementsList.value.forEach((item: any) => {
          root.$store.dispatch('addComplementSuggestions', item.name)
        })
      }

      if (!offerResult.services?.insurance) {
        // clear default DoB if no insurance
        offerResult.client.dob = undefined
      }

      if (root.$route.params.id) {
        apiOffers
          .editOffer(root.$route.params.id, offerResult, group.value.dealerId)
          .then(() => {
            /* Show success popup before redirect */
            if (redirect) {
              root.$router.push({
                name: 'ResumeOfferMotorbike',
                params: {
                  id: offerResult.id,
                },
              })
            } else {
              resumeOffer.value = { ...offerResult }
              cb()
            }
          })
          .catch((err) => console.log(err) /* Show error popup */)
          .finally(() => {
            isOfferCreating.value = false
            gtm?.trackEvent({
              category: 'Offers',
              action: 'EditOffer',
              label: offerType.value,
            })
          })
      } else {
        // update the accepted/refused timestamp if accepted or refused on creation
        if (offerResult.status === Status.Accepted) {
          offerResult.updatedAtStatusAccepted = moment()
        } else if (offerResult.status === Status.Refused) {
          offerResult.updatedAtStatusRefused = moment()
        }

        // save the offer
        apiOffers
          .createNewOffer(offerResult, group.value.dealerId)
          .then((result) => {
            /* Show success popup before redirect */
            if (props.leadId) {
              ridoApi.addOffer(props.leadId, result.data.id)
            }
            if (redirect) {
              root.$router.push({
                name: 'ResumeOfferMotorbike',
                params: {
                  id: result.data.id,
                  // @ts-ignore
                  leadId: props.leadId,
                },
              })
            } else {
              resumeOffer.value = { ...result.data }
              cb()
            }
          })
          .catch((err) => console.log(err) /* Show error popup */)
          .finally(() => {
            isOfferCreating.value = false
            gtm?.trackEvent({
              category: 'Offers',
              action: 'CreateOffer',
              label: offerType.value,
            })
          })
      }
    }

    return {
      attendedBy,
      campaign,
      complementsList,
      createOffer,
      focus,
      group,
      hasAttendedBy,
      hideDetails,
      isModelsLoading,
      isOfferCreating,
      loading,
      mainLoading,
      offer,
      resumeOffer,
      store: root.$store,
      subagentName,
      t,
      useSimpleForm,
    }
  },
})
