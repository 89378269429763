import { OfferType, Product, Status } from '@mapit/honda-offers-builder/dist/types'
export { OfferType, Product, Status }

// TODO: update new types in @mapit/honda-offers-builder/dist/types
export const OfferTypePortal = {
  customer: OfferType.customer,
  subagent: OfferType.subagent,
  demobike: 'DEMO_BIKE',
  fleet: 'FLEET',
}

export const offerTypes = [
  OfferTypePortal.customer,
  OfferTypePortal.subagent,
  OfferTypePortal.demobike,
  OfferTypePortal.fleet,
]
