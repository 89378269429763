import moment from 'moment'
import { DateTime } from 'luxon'
import { find, get } from 'lodash'

import apiOffers from '@/api/offers.api'
import { inRange, reverseItemInArray } from '@/helpers/utils'
import { refreshSession } from '@/router'
import { findSecondLevelRegionByName, findSecondLevelRegionByPostalCode } from '@/utils/administrative-region-helper'
import { searchContactAndLeadByLeadId } from '@/utils/leads-helper'
import { InsuranceModality } from '@mapit/honda-catalog-dynamodb/dist/types'
import { InsuranceType, ProbabilityOfSale } from '@mapit/honda-offers-builder/dist/types'

import HondaOfferBuilder from '@mapit/honda-offers-builder'
import { Product, Status } from '@/types/offers'
import { generateCampaignId } from "@/composables/campaigns";

const offer = new HondaOfferBuilder()
const persitedKeys = ['attendedBySuggestions', 'complementSuggestions', 'dealerCampaignSuggestions']

const getOfferInitialState = (offer) => ({
  selectedModel: null,
  offer,
  attendedBy: offer.attendedBy, //test
  createDate: offer.createDate, //test
  clientName: offer.clientName, //test
  clientSurname: offer.clientSurname, //test
  clientEmail: offer.clientEmail, //test
  clientPhone: offer.clientPhone, //test
  clientProvince: offer.clientAddressProvince,
  clientAddressCountry: offer.clientAddressCountry,
  offerContact: null,
  offerSearchedContact: null,
  privacyAgreement: offer.privacyAgreement,
  consentAgreement: offer.consentAgreement,
  clientPostalCode: null,
  vehicleModelName: offer.vehicleModelName, //test
  vehicleModelSubname: offer.vehicleModelSubname, //test
  vehicleModelPacks: [],
  vehicleModelPriceRate: offer.vehicleModelPriceRate, //test
  vehicleModelGroupId: offer.vehicleModelGroupId, //test
  vehicleModelIdRate: offer.vehicleModelIdRate,
  vehicleModelInsurance: offer.vehicleModelInsurance,
  ccaaRates: null,
  delalerCampaignId: offer.delalerCampaignId,
  dealerCampaignName: offer.dealerCampaignName,
  dealerCampaignValue: offer.dealerCampaignValue,
  hondaCampaign: offer.campaign,
  vehiclePriceRateCCAA: offer.vehiclePriceRateCCAA, //test
  vehicleDiscountPriceRate: offer.vehicleDiscountPriceRate,
  vehicleDiscountPriceRatePercent: offer.vehicleDiscountPriceRatePercent,
  additionalDiscountPriceRate: offer.additionalDiscountPriceRate,
  servicesProducts: offer.servicesProducts,
  servicesDiscount: offer.servicesDiscount,
  servicesDiscountPercent: offer.servicesDiscountPercent,
  servicesTotalDiscount: offer.getServicesTotalDiscount(),
  servicesTotalPrice: offer.getServicesTotalPrice(),
  servicesPrice: offer.getServicesPrice(),
  servicesInsuranceType: offer.servicesInsuranceType,
  clientDob: offer.clientDob,
  clientDrivingLicenceDate: offer.clientDrivingLicenceDate,
  clientAge: null,
  status: offer.status, //test
  initialStatus: offer.status, //test
  probabilityOfSale: ProbabilityOfSale.Low, //test
  annotation: offer.annotation, //test
  registrationPrice: offer.registrationPrice, //test
  registrationDiscount: offer.registrationDiscount, //test
  registrationDiscountPercent: offer.registrationDiscountPercent, //test
  deliveredBrand: offer.deliveredBrand, //test
  deliveredModel: offer.deliveredModel, //test
  deliveredAmount: offer.deliveredAmount, //test
  complements: offer.complementsComplements,
  packs: offer.packsPacks,
  complementsTotalPrice: offer.getComplementsTotalPrice(),
  registrationTotalPrice: offer.getRegistrationTotalPrice(), //test
  vehicleTotalPrice: offer.getVehicleTotalPrice(),
  totalPrice: offer.getTotalPrice(),
  finance: offer.getFinance(),
  type: offer.type,
  subagentName: offer.subagent,
  pdfUrl: null,
  loadingPacks: false,
  offerCode: null,
  offerStatus: null,
  offerEmail: null,
  offerLoading: false,
  isFinanceUpdated: false,
  commentLog: offer.commentLog,
})

const getInitialState = (offer) => ({
  offersList: [],
  attendedBySuggestions: [],
  complementSuggestions: [],
  dealerCampaignSuggestions: [],
  vehicleModelsOptions: [],
  selectedModel: null,
  decollapsedPanels: [0, 2, 7, 8, 10],
  offerListCount: null,
  ...getOfferInitialState(offer),
  servicesDiscountError: null,
  servicesDiscountPercentError: null,
  sortBy: { column: 'createDate', sort: 'desc' },
})

const state = getInitialState(offer)

const getters = {
  getOffer: (state) => state.offer,
  getOffersListCount: (state) => state.offerListCount,
  getAttendedBy: (state) => state.attendedBy, //test
  getAttendedBySuggestions: (state) => state.attendedBySuggestions,
  getComplementSuggestions: (state) => state.complementSuggestions,
  getDealerCampaignSuggestions: (state) => state.dealerCampaignSuggestions,
  getCreateDate: (state) => state.createDate, //test
  getClientName: (state) => state.clientName, //test
  getClientSurname: (state) => state.clientSurname, //test
  getClientEmail: (state) => state.clientEmail, //test
  getClientPhone: (state) => state.clientPhone, //test
  getClientProvince: (state) => state.clientProvince, //test
  getConsentAgreement: (state) => state.consentAgreement, //test
  getPrivacyAgreement: (state) => state.privacyAgreement, //test
  getClientPostalCode: (state) => state.clientPostalCode,
  getOfferContact: (state) => state.offerContact,
  getOfferSearchedContact: (state) => state.offerSearchedContact,
  getVehicleModelName: (state) => state.vehicleModelName, //test
  getVehicleModelSubname: (state) => state.vehicleModelSubname, //test
  getVehicleModelPacks: (state) =>
    state.vehicleModelPacks.map((item) => {
      item.partNoPortal = `(${item.partNo})`
      return item
    }), //test
  getVehicleModelPriceRate: (state) => state.vehicleModelPriceRate || 0, //test
  getVehicleModelGroupId: (state) => state.vehicleModelGroupId, //test
  getVehicleModelIdRate: (state) => state.vehicleModelIdRate,
  getVehicleModelInsurance: (state) => state.vehicleModelInsurance,
  getVehicleModelsOptions: (state) => state.vehicleModelsOptions, //test
  getDealerCampaignValue: (state) => state.dealerCampaignValue,
  getDealerCampaignName: (state) => state.dealerCampaignName || '',
  getHondaCampaign: (state) => state.hondaCampaign,
  getHondaCampaignValue: (state) => state.hondaCampaign?.value,
  getHondaCampaignStartDate: (state) =>
    state.hondaCampaign?.campaignInitDate ? moment(state.hondaCampaign.campaignInitDate).format('DD/MM/YYYY') : undefined,
  getHondaCampaignEndDate: (state) =>
    state.hondaCampaign?.campaignEndDate ? moment(state.hondaCampaign.campaignEndDate).format('DD/MM/YYYY') : undefined,
  getVehiclePriceRateCCAA: (state) => state.vehiclePriceRateCCAA, //test
  getVehicleDiscountPriceRate: (state) => state.vehicleDiscountPriceRate,
  getVehicleDiscountPriceRatePercent: (state) => state.vehicleDiscountPriceRatePercent,
  getAdditionalDiscountPriceRate: (state) => state.additionalDiscountPriceRate,
  getServicesProducts: (state) => state.servicesProducts,
  getServicesDiscount: (state) => state.servicesDiscount,
  getServicesDiscountPercent: (state) => state.servicesDiscountPercent,
  getServicesDiscountError: (state) => state.servicesDiscountError,
  getServicesDiscountPercentError: (state) => state.servicesDiscountPercentError,
  getServicesTotalDiscount: (state) => state.servicesTotalDiscount,
  getServicesTotalPrice: (state) => state.servicesTotalPrice,
  getServicesPrice: (state) => state.servicesPrice,
  getServicesInsuranceType: (state) => state.servicesInsuranceType,
  getClientDob: (state) => state.clientDob,
  getClientDrivingLicenceDate: (state) => state.clientDrivingLicenceDate,
  getClientAge: (state) => state.clientAge,
  getVehicleTotalPrice: (state) => state.vehicleTotalPrice || state.offer.getVehicleTotalPrice(),
  getFinance: (state) => state.finance,
  getTotalPrice: (state) => (state.totalPrice ? state.totalPrice : state.offer.getTotalPrice()), // get total price
  getStatus: (state) => state.status, //test
  getInitialStatus: (state) => state.initialStatus, //test
  getProbabilityOfSale: () => ProbabilityOfSale.Low, //test
  getAnnotation: (state) => state.annotation, //test
  getRegistrationPrice: (state) => state.registrationPrice, //test
  getRegistrationDiscount: (state) => state.registrationDiscount, //test
  getRegistrationDiscountPercent: (state) => state.registrationDiscountPercent, //test
  getRegistrationTotalPrice: (state) =>
    state.registrationTotalPrice ? state.registrationTotalPrice : state.offer.getRegistrationTotalPrice(), //test
  getDeliveredBrand: (state) => state.deliveredBrand, //test
  getDeliveredModel: (state) => state.deliveredModel, //test
  getDeliveredAmount: (state) => state.deliveredAmount, //test
  getComplements: (state) => state.complements || state.offer.complementsComplements,
  getPacks: (state) => state.packs || state.offer.packsPacks,
  getComplementsTotalPrice: (state) => state.complementsTotalPrice,
  getOfferType: (state) => state.type,
  getSubagentName: (state) => state.subagentName,
  getPdfUrl: (state) => state.pdfUrl,
  getDecollapsedPanels: (state) => state.decollapsedPanels,
  getCanHaveHP: (state) => state.selectedModel && (state.selectedModel?.hpConfig?.HP?.available || false),
  getCanHaveHPGo: (state) => state.selectedModel && (state.selectedModel?.hpConfig?.HPG?.available || false),
  isLoadingPacks: (state) => state.loadingPacks,
  isOfferLoading: (state) => state.offerLoading,
  getOfferId: (state) => state.offer?.id,
  getOfferCode: (state) => (state.offerCode ? state.offerCode.split('-')[1] : null),
  getOfferStatus: (state) => state.offerStatus,
  getOfferEmail: (state) => state.offerEmail,
  getSelectedModel: (state) => state.selectedModel,
  getSortBy: (state) => state.sortBy,
  isFinanceUpdated: (state) => state.isFinanceUpdated,
}

const actions = {
  initNewOffer: async ({ commit, getters }, payload) => {
    if (payload?.leadId) {
      const { leadId } = payload
      const { contact, lead } = await searchContactAndLeadByLeadId(leadId)
      commit('SET_OFFER_SEARCHED_CONTACT', contact)

      const offerFromLead = new HondaOfferBuilder()
        .setProbabilityOfSale(ProbabilityOfSale.Low)
        .setDealer(lead.dealer)
        .setCommentLog(lead.commentLog)

      commit('INIT_NEW_OFFER', offerFromLead)
    } else {
      commit('INIT_NEW_OFFER', new HondaOfferBuilder().setProbabilityOfSale(ProbabilityOfSale.Low))
    }
    commit('SET_COGNITO_USER', await refreshSession({ bypassCache: true }))
    commit('SET_ATTENDED_BY', getters.getCognitoUserName)
  }, //test
  loadOfferById: async ({ commit, getters }, id) => {
    commit('SET_OFFER_LOADING', true)
    commit('INIT_NEW_OFFER', new HondaOfferBuilder()) //clean
    return new Promise((resolve, reject) => {
      apiOffers
        .getOfferById(id, getters.getGroup.dealerId)
        .then(async (res) => {
          commit('INIT_NEW_OFFER', new HondaOfferBuilder().fromOffer(res.data))
          commit('SET_COGNITO_USER', await refreshSession({ bypassCache: true }))
          commit('SET_ATTENDED_BY', getters.getCognitoUserName)
          commit('SET_OFFER_LOADING', false)
          resolve(res.data)
        })
        .catch(async (e) => {
          commit('SET_COGNITO_USER', await refreshSession({ bypassCache: true }))
          commit('SET_ATTENDED_BY', getters.getCognitoUserName)
          commit('SET_OFFER_LOADING', false)
          reject(e)
        })
    })
  },
  loadOfferSummaryById: ({ commit }, { id, dealerId }) => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        apiOffers
          .getOfferSummaryById(id, dealerId)
          .then((res) => {
            if (res.data && res.data.pdf) {
              commit('SET_OFFER_CODE', res.data.code)
              commit('SET_OFFER_STATUS', res.data.status)
              commit('SET_OFFER_PDF', res.data.pdf)
              commit('SET_OFFER_EMAIL', res.data.email)
              resolve(res.data)
              clearInterval(interval)
            }
          })
          .catch((e) => {
            reject(e)
          })
      }, 1200)
    })
  },
  loadOffersList: ({ commit }, dealerId) => {
    return new Promise((resolve, reject) => {
      apiOffers
        .getOffersListByDealerId({
          dealer: { id: dealerId },
          pagination: { size: 1 },
          params: { statuses: [Status.Pending, Status.Accepted] },
        })
        .then((res) => {
          commit('SET_OFFER_LIST_COUNT', res.total.value)
          resolve(res.total.value)
        })
        .catch((e) => {
          console.error('Problem retrieving offer list', e)
          commit('SET_OFFER_LIST_COUNT', 0)
          reject(e)
        })
    })
  },
  setGroupToOffer: ({ commit, rootState }, group) => {
    commit('SET_GROUP_TO_OFFER', group)
    const shop = group.profile.shop
    if (shop.address && shop.address.postalCode) {
      commit('SET_PROVINCE', {
        province: findSecondLevelRegionByPostalCode(shop.address.postalCode, rootState.group.country)?.name,
        country: rootState.group.country,
      })
      commit('SET_POSTAL_CODE', shop.address.postalCode.substr(0, 2))
    }
  }, //test
  prepareOfferContact: ({ commit, rootState }, contact) => {
    commit('SET_OFFER_CONTACT', contact)
    commit('SET_CLIENT_NAME', contact?.firstName)
    commit('SET_CLIENT_SURNAME', contact?.lastName)
    commit('SET_CLIENT_EMAIL', contact?.email)
    commit('SET_CLIENT_PHONE', contact?.phone)
    commit('SET_CLIENT_COUNTRY', contact?.address?.addressCountry || rootState.group.country)
    commit('SET_PROVINCE', {
      province:
        contact?.address?.addressRegion ||
        findSecondLevelRegionByPostalCode(rootState.group.profile.shop.address.postalCode, rootState.group.country)?.name,
      country: rootState.group.country,
    })
  },
  setClientSearchToOffer: ({ commit, state }) => {
    commit('SET_OFFER_CONTACT', state.offerSearchedContact)
    commit('SET_CLIENT_SEARCH_TO_OFFER')
  },
  setAttendedBy: ({ commit }, attendedBy) => {
    commit('SET_ATTENDED_BY', attendedBy)
  }, //test
  addAttendedBySuggestions: ({ commit }, newSuggestion) => {
    if (typeof newSuggestion !== 'string' || state.attendedBySuggestions.indexOf(newSuggestion) !== -1) return
    commit('ADD_ATTENDED_BY_SUGGESTIONS', newSuggestion)
  },
  addComplementSuggestions: ({ commit }, newSuggestion) => {
    if (typeof newSuggestion !== 'string' || state.complementSuggestions.indexOf(newSuggestion) !== -1) return
    commit('ADD_COMPLEMENT_SUGGESTIONS', newSuggestion)
  },
  addDealerCampaignSuggestions: ({ commit }, newSuggestion) => {
    if (typeof newSuggestion !== 'string' || state.dealerCampaignSuggestions.indexOf(newSuggestion) !== -1) return
    commit('ADD_DEALER_CAMPAIGN_SUGGESTIONS', newSuggestion)
  }, //test
  setCreateDate: ({ commit }, date) => {
    commit('SET_CREATE_DATE', date)
  }, //test
  //CLIENT
  setClientName: ({ commit }, name) => {
    commit('SET_CLIENT_NAME', name)
  }, //test
  setClientSurname: ({ commit }, surname) => {
    commit('SET_CLIENT_SURNAME', surname)
  }, //test
  setClientEmail: ({ commit }, email) => {
    commit('SET_CLIENT_EMAIL', email)
  }, //test
  setClientPhone: ({ commit }, phone) => {
    commit('SET_CLIENT_PHONE', phone)
  }, //test
  setClientProvince: ({ commit, rootState }, province) => {
    commit('SET_PROVINCE', { province, country: rootState.group.country })
  }, //test //test
  setClientCountry: ({ commit, rootState }, country) => {
    commit('SET_CLIENT_COUNTRY', country || rootState.group.country)
  }, //test
  setPrivacyAgreement: ({ commit }, privacyAgreement) => {
    commit('SET_PRIVACY_AGREEMENT', privacyAgreement)
  },
  setConsentAgreement: ({ commit }, consentAgreement) => {
    commit('SET_CONSENT_AGREEMENT', consentAgreement)
  },
  setVehicleModelPriceRate: ({ commit }, priceRate) => {
    commit('SET_VEHICLE_MODEL_PRICE_RATE', priceRate)
  },
  setClientPostalCode: ({ commit }, postalCode) => {
    commit('SET_POSTAL_CODE', postalCode)
  }, //test
  setVehicleModelName: ({ commit }, name) => {
    commit('SET_VEHICLE_MODEL_NAME', name)
  }, //test
  setVehicleModelBySubname: async ({ commit, rootState }, payload) => {
    if (payload) {
      const { summary, model, material, rates, needsUpdate, isEditing } = payload
      commit('LOAD_PACKS_FROM_OFFER', { packs: summary.packs })
      commit('SET_VEHICLE_MODEL_BY_SUBNAME', {
        country: rootState.group.country,
        summary,
        model,
        material,
        rates,
        needsUpdate,
        isEditing,
      })
    } else {
      commit('SET_VEHICLE_MODEL_BY_SUBNAME_CLEAN')
    }
  }, //test
  setCampaignClean: ({ commit }) => {
    commit('SET_CAMPAIGN_CLEAN')
  },
  // HONDA CAMPAIGN
  setHondaCampaign: ({ commit }, campaign) => {
    commit('SET_HONDA_CAMPAIGN', campaign)
  },
  // DEALER CAMPAIGN ( setDealerCampaignName, setVehicleDiscountPriceRate or Percent )
  setVehicleDiscountPriceRate: ({ commit }, discount) => {
    commit('SET_VEHICLE_DISCOUNT_PRICE_RATE', discount)
  },
  setVehicleDiscountPriceRatePercent: ({ commit }, discount) => {
    commit('SET_VEHICLE_DISCOUNT_PRICE_RATE_PERCENT', discount)
  },
  setDealerCampaignName: ({ commit }, name) => {
    commit('SET_DEALER_CAMPAIGN_NAME', name)
  },
  setVehiclePriceRateCCAA: ({ commit }, price) => {
    commit('SET_VEHICLE_PRICE_RATE_CCAA', price)
  },
  setStatus: ({ commit }, status) => {
    commit('SET_STATUS', status)
  }, //test
  setProbabilityOfSale: ({ commit }, probabilityOfSale) => {
    commit('SET_PROBABILITY_OF_SALE', probabilityOfSale)
  }, //test
  setAdditionalDiscountPriceRate: ({ commit }, discount) => {
    commit('SET_ADDITIONAL_DISCOUNT_PRICE_RATE', discount)
  },
  setAnnotation: ({ commit }, annotation) => {
    commit('SET_ANNOTATION', annotation)
  }, //test
  // VEHICLE REGISTRATION
  setRegistrationPrice: ({ commit }, price) => {
    commit('SET_REGISTRATION_PRICE', price)
  }, //test
  setRegistrationDiscount: ({ commit }, discount) => {
    commit('SET_REGISTRATION_DISCOUNT', discount)
  }, //test
  setRegistrationDiscountPercent: ({ commit }, discount) => {
    commit('SET_REGISTRATION_DISCOUNT_PERCENT', discount)
  }, //test
  //SERVICES
  setServicesProduct: ({ commit, rootState }, product) => {
    commit('SET_SERVICES_PRODUCT', { product, country: rootState.group.country })
  },
  setServicesDiscount: ({ commit }, discount) => {
    commit('SET_SERVICES_DISCOUNT', discount)
  },
  setServicesDiscountPercent: ({ commit }, discount) => {
    commit('SET_SERVICES_DISCOUNT_PERCENT', discount)
  },
  setServicesInsuranceType: ({ commit, rootState }, { type, needsUpdate }) => {
    commit('SET_SERVICES_INSURANCE_TYPE', { type, country: rootState.group.country, needsUpdate })
  },
  setDrivingLicenceDate: ({ commit, rootState }, drivingLicenceDate) => {
    commit('SET_DRIVING_LICENCE_DATE', { drivingLicenceDate, country: rootState.group.country })
  },
  setDeliveredBrand: ({ commit }, brand) => {
    commit('SET_DELIVERED_BRAND', brand)
  }, //test
  setDeliveredModel: ({ commit }, model) => {
    commit('SET_DELIVERED_MODEL', model)
  }, //test
  setDeliveredAmount: ({ commit }, amount) => {
    commit('SET_DELIVERED_AMOUNT', amount)
  }, //test
  addComplement: ({ commit }) => {
    commit('ADD_COMPLEMENT')
  },
  setOfferType: ({ commit }, payload) => {
    commit('SET_TYPE', payload)
  },
  setSubagentName: ({ commit }, payload) => {
    commit('SET_SUBAGENT_NAME', payload)
  },
  collapsePanel: ({ commit }, panel) => {
    commit('COLLAPSE_PANEL', panel)
  },
  decollapsePanel: ({ commit }, panel) => {
    commit('DECOLLAPSE_PANEL', panel)
  },
  setDecollapsedPanels: ({ commit }, panels) => {
    commit('SET_DECOLLAPSED_PANELS', panels)
  },
  setCatalogCCAARatesOptions: ({ commit }, options) => {
    commit('SET_CATALOG_CCAA_RATES_OPTIONS', options)
  },
  setSortBy: ({ commit }, payload) => {
    commit('SET_SORT_BY', payload)
  },
  cleanPacksAndServices: ({ commit }) => {
    commit('CLEAN_PACKS_AND_SERVICES')
  },
  clearOfferContact: ({ commit }) => {
    commit('CLEAR_OFFER_CONTACT')
  },
  resetIsFinanceUpdated: ({ commit }) => {
    commit('SET_IS_FINANCE_UPDATED', false)
  },
  clearOffer: ({ commit }) => {
    commit('RESET_OFFER')
  },
  clearOfferPdf: ({ commit }) => {
    commit('CLEAR_OFFER_PDF')
  },
}

const mutations = {
  LOAD_PACKS_FROM_OFFER: (state, data = { packs: [] }) => {
    state.packs = state.packs
      .filter((item) => item.partNo)
      .map((item) => {
        const findItemInCatalog = data.packs.find((p) => p.partNo === item.partNo)
        item.removed = !findItemInCatalog || (findItemInCatalog && findItemInCatalog.price !== item.price)
        return item
      })
    state.vehicleModelPacks = [...data.packs, ...state.packs.filter((item) => item.partNo)]

    // adds empty pack if no packs
    if (state.packs.length === 0 && state.offer.complementsComplements.length < 15) {
      state.offer.clearPacks()
      state.offer.addPack()
      state.packs = state.offer.packsPacks
    }
    state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  INIT_NEW_OFFER: (state, newOffer) => {
    state.offer = newOffer
    state.attendedBy = newOffer.attendedBy
    state.offerContact = {
      firstName: newOffer.clientName,
      lastName: newOffer.clientSurname,
      email: newOffer.clientEmail,
      phone: newOffer.clientPhone,
      gdprConsent: newOffer.consentAgreement || false,
      privacyTermsAccepted: newOffer.privacyAgreement || false,
      address: {
        addressRegion: newOffer.clientAddressProvince || state.clientProvince,
        addressCountry: newOffer.clientAddressCountry || state.clientCountry,
      },
      drivingLicenceDate: newOffer.clientDrivingLicenceDate,
    }
    state.createDate = newOffer.createDate
    state.vehicleModelName = newOffer.vehicleModelName
    state.vehicleModelSubname = newOffer.vehicleModelSubname
    state.vehicleModelPriceRate = newOffer.vehicleModelPriceRate
    state.vehicleModelGroupId = newOffer.vehicleModelGroupId
    state.vehicleModelIdRate = newOffer.vehicleModelIdRate
    state.dealerCampaignId = newOffer.dealerCampaignId
    state.dealerCampaignName = newOffer.dealerCampaignName
    state.dealerCampaignValue = newOffer.dealerCampaignValue
    const campaign = newOffer.getCampaign()
    state.hondaCampaign = campaign? {...campaign, id: generateCampaignId(campaign)} : undefined
    state.vehiclePriceRateCCAA = newOffer.vehiclePriceRateCCAA
    state.vehicleDiscountPriceRate = newOffer.vehicleDiscountPriceRate
    state.vehicleDiscountPriceRatePercent = newOffer.vehicleDiscountPriceRatePercent
    state.additionalDiscountPriceRate = newOffer.additionalDiscountPriceRate
    state.status = newOffer.status
    state.initialStatus = newOffer.status
    state.probabilityOfSale = ProbabilityOfSale.Low // TODO: Remove when cleared from builder
    state.annotation = newOffer.annotation
    state.servicesProducts = newOffer.servicesProducts
    state.servicesDiscount = newOffer.servicesDiscount
    state.servicesDiscountPercent = newOffer.servicesDiscountPercent
    state.servicesTotalDiscount = newOffer.getServicesTotalDiscount()
    state.servicesTotalPrice = newOffer.getServicesTotalPrice()
    state.servicesPrice = newOffer.getServicesPrice()
    state.servicesInsuranceType = newOffer.servicesInsuranceType
    state.clientDob = newOffer.clientDob
    state.clientDrivingLicenceDate = newOffer.clientDrivingLicenceDate
    state.registrationPrice = newOffer.registrationPrice
    state.registrationDiscount = newOffer.registrationDiscount
    state.registrationDiscountPercent = newOffer.registrationDiscountPercent
    state.deliveredBrand = newOffer.deliveredBrand
    state.deliveredModel = newOffer.deliveredModel
    state.deliveredAmount = newOffer.deliveredAmount
    if (
      (!state.offer.complementsComplements || state.offer.complementsComplements.length === 0) &&
      state.offer.packsPacks.length < 15
    )
    state.offer.addComplement()
    state.complements = state.offer.complementsComplements
    state.complementsTotalPrice = state.offer.getComplementsTotalPrice()
    state.registrationTotalPrice = newOffer.getRegistrationTotalPrice()
    state.vehicleTotalPrice = newOffer.getVehicleTotalPrice()
    state.totalPrice = newOffer.getTotalPrice()
    state.finance = newOffer.getFinance()
    state.type = newOffer.type
    state.subagentName = newOffer.subagentName
    state.pdfUrl = null
    state.decollapsedPanels = [0, 2, 7, 8, 10]
    state.packs = newOffer.packsPacks.filter((item) => item.partNo)
    state.clientDrivingLicenceDate = newOffer.clientDrivingLicenceDate
    state.offer.clientDrivingLicenceDate = newOffer.clientDrivingLicenceDate
    state.vehicleTotalPrice = newOffer.getVehicleTotalPrice()
    state.totalPrice = newOffer.getTotalPrice()
    state.commentLog = newOffer.commentLog
  },
  SET_CLIENT_SEARCH_TO_OFFER: (state) => {
    state.clientName = state.offerSearchedContact.firstName
    state.clientSurname = state.offerSearchedContact.lastName
    state.clientEmail = state.offerSearchedContact.email
    state.clientPhone = state.offerSearchedContact.phone
    state.clientProvince = state.offerSearchedContact.address?.addressRegion
    state.clientCountry = state.offerSearchedContact.address?.addressCountry
    state.offer.clientName = state.clientName
    state.offer.clientSurname = state.clientSurname
    state.offer.clientEmail = state.clientEmail
    state.offer.clientPhone = state.clientPhone
    state.offer.clientAddressProvince = state.clientProvince
    state.offer.clientAddressCountry = state.clientCountry
    state.offer.clientDrivingLicenceDate = state.clientCountry
  },
  SET_OFFER_LOADING: (state, loading) => {
    state.offerLoading = loading
  },
  SET_OFFER_PDF: (state, pdfUrl) => {
    state.pdfUrl = pdfUrl
  },
  SET_OFFER_CODE: (state, code) => {
    state.offerCode = code
  },
  SET_OFFER_STATUS: (state, status) => {
    state.offerStatus = status
  },
  SET_OFFER_EMAIL: (state, email) => {
    state.offerEmail = email
  },
  SET_OFFER_LIST_COUNT: (state, count) => {
    state.offerListCount = count
  },
  SET_ATTENDED_BY: (state, attendedBy) => {
    state.attendedBy = attendedBy
    state.offer.attendedBy = attendedBy
  }, //test
  ADD_ATTENDED_BY_SUGGESTIONS: (state, newSuggestion) => {
    state.attendedBySuggestions.push(newSuggestion)
  },
  ADD_COMPLEMENT_SUGGESTIONS: (state, newSuggestion) => {
    state.complementSuggestions.push(newSuggestion)
  },
  ADD_DEALER_CAMPAIGN_SUGGESTIONS: (state, newSuggestion) => {
    state.dealerCampaignSuggestions.push(newSuggestion)
  }, //test
  SET_CREATE_DATE: (state, date) => {
    state.createDate = date
    state.offer.createDate = moment(date).format('YYYY-MM-DD')
  }, //test
  SET_OFFER_CONTACT: (state, contact) => {
    state.offerContact = contact
  },
  SET_OFFER_SEARCHED_CONTACT: (state, contact) => {
    state.offerSearchedContact = contact
  },
  SET_CLIENT_NAME: (state, name) => {
    state.clientName = name
    state.offer.clientName = name
  }, //test
  SET_CLIENT_SURNAME: (state, surname) => {
    state.clientSurname = surname
    state.offer.clientSurname = surname
  }, //test
  SET_CLIENT_EMAIL: (state, email) => {
    state.clientEmail = email
    state.offer.clientEmail = email
  }, //test
  SET_CLIENT_PHONE: (state, phone) => {
    state.clientPhone = phone
    state.offer.clientPhone = phone
  }, //test
  SET_PROVINCE: (state, { province, country }) => {
    state.clientProvince = province
    state.offer.clientAddressProvince = province
    if (state.offerContact) {
      state.offerContact.address.addressRegion = province
    }
    if (state.selectedModel != null) {
      state.vehiclePriceRateCCAA = calculateFirstLevelRegionPrice(
        country,
        state.selectedModel,
        state.clientProvince,
        state.ccaaRates,
      )
    }
    if (state.vehicleModelInsurance != null && state.offer.servicesInsuranceType != null) {
      const priceByProvince = getPriceByProvince(state, state.servicesInsuranceType, country)
      if (priceByProvince) state.offer.setServicesInsurance(state.servicesInsuranceType, priceByProvince)
      state.servicesPrice = state.offer.getServicesPrice()
      state.servicesTotalPrice = state.offer.getServicesTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    }
  }, //test
  SET_CLIENT_COUNTRY: (state, addressCountry) => {
    state.clientAddressCountry = addressCountry
    state.offer.clientAddressCountry = addressCountry
    if (state.offerContact) {
      state.offerContact.address.addressCountry = addressCountry
    }
  },
  SET_PRIVACY_AGREEMENT: (state, privacyAgreement) => {
    state.privacyAgreement = privacyAgreement
    state.offer.privacyAgreement = privacyAgreement
  },
  SET_CONSENT_AGREEMENT: (state, consentAgreement) => {
    state.consentAgreement = consentAgreement
    state.offer.consentAgreement = consentAgreement
  },
  SET_POSTAL_CODE: (state, postalCode) => {
    state.clientPostalCode = postalCode
  }, //test
  SET_VEHICLE_MODEL_NAME: (state, name) => {
    state.vehicleModelName = name
    state.offer.vehicleModelName = name
    state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_VEHICLE_MODEL_PACKS: (state, packs) => {
    state.vehicleModelPacks = packs
  },
  SET_LOADING_PACKS: (state, loading) => {
    state.loadingPacks = loading
  },
  SET_VEHICLE_MODEL_BY_SUBNAME: (state, { country, summary, model, material, rates, needsUpdate, isEditing }) => {
    state.vehicleModelName = model?.description
    state.vehicleModelSubname = material?.description
    state.vehicleModelPacks = summary?.packs
    state.vehicleModelGroupId = summary?.model.group
    state.vehicleModelIdRate = summary?.co2IdRate
    state.vehicleModelInsurance = summary?.insurance

    if (needsUpdate) state.vehicleModelPriceRate = summary?.price?.amount
    if (needsUpdate)
      state.vehiclePriceRateCCAA = calculateFirstLevelRegionPrice(country, summary, state.clientProvince, rates)
    if (needsUpdate) state.offer.vehiclePriceRateCCAA = state.vehiclePriceRateCCAA
    if (needsUpdate) state.offer.cleanVehicleDiscountPriceRate()

    state.offer.setVehicleModel({
      name: model?.description,
      subname: material?.description,
      groupid: summary?.model?.group,
      price: needsUpdate ? summary?.price?.amount : state.vehicleModelPriceRate,
    })

    if (isEditing) {
      const finance = state.offer.getFinance()
      if (finance) {
        finance.mode = mapFinanceMode(finance, summary?.finance)
        state.offer.setFinance(finance)
        state.finance = state.offer.getFinance()
        state.isFinanceUpdated = true
      }
    }

    if (needsUpdate) state.offer.setServicesHondaPlusPrice(summary?.hpConfig?.HP.price)

    state.selectedModel = summary
    state.vehicleDiscountPriceRate = state.offer.vehicleDiscountPriceRate
    state.vehicleDiscountPriceRatePercent = state.offer.vehicleDiscountPriceRatePercent
    state.servicesPrice = state.offer.getServicesPrice()
    state.servicesTotalPrice = state.offer.getServicesTotalPrice()
    state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_VEHICLE_MODEL_BY_SUBNAME_CLEAN: (state) => {
    state.vehicleModelName = undefined
    state.vehicleModelSubname = undefined
    state.vehicleModelPacks = []
    state.vehicleModelPriceRate = 0
    state.vehicleModelGroupId = undefined
    state.vehicleModelIdRate = undefined
    state.vehicleModelInsurance = undefined
    state.vehiclePriceRateCCAA = 0
    state.vehicleDiscountPriceRate = 0
    state.vehicleDiscountPriceRatePercent = 0
    state.vehicleTotalPrice = 0
    state.totalPrice = 0
    state.hondaCampaign = undefined
    state.clientDob = undefined
    state.clientDrivingLicenceDate = undefined
    state.offer.vehicleModelName = undefined
    state.offer.vehicleModelSubname = undefined
    state.offer.vehicleModelPriceRate = 0
    state.offer.vehicleModelGroupId = undefined
    state.offer.vehiclePriceRateCCAA = 0
    state.offer.vehicleDiscountPriceRate = 0
    state.offer.vehicleDiscountPriceRatePercent = 0
    state.offer.clientDob = undefined
    state.offer.clientDrivingLicenceDate = undefined
    state.offer.clearPacks()
    state.offer.campaign = undefined
    state.offer.addPack()
    state.packs = state.offer.packsPacks
    state.finance = null
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_DEALER_CAMPAIGN_NAME: (state, dealerCampaignName) => {
    state.dealerCampaignId = dealerCampaignName
    state.dealerCampaignName = dealerCampaignName
    state.offer.dealerCampaignName = dealerCampaignName
    state.offer.dealerCampaignId = dealerCampaignName
  },
  SET_CAMPAIGN_CLEAN: (state) => {
    state.hondaCampaign = undefined
    state.dealerCampaignName = undefined
    state.dealerCampaignValue = undefined
    state.dealerCampaignId = undefined
    state.offer.campaign = undefined
    state.offer.dealerCampaignName = undefined
    state.offer.dealerCampaignId = undefined
    state.offer.dealerCampaignValue = undefined
  },
  SET_HONDA_CAMPAIGN: (state, hondaCampaign) => {
    state.hondaCampaign = hondaCampaign
    state.offer.campaign = hondaCampaign
    state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_VEHICLE_PRICE_RATE_CCAA: (state, price) => {
    state.vehiclePriceRateCCAA = price
    if (price) {
      state.offer.vehiclePriceRateCCAA = typeof price === 'string' ? parseFloat(price) : price
      try {
        state.offer.vehicleDiscountPriceRatePercent = state.vehicleDiscountPriceRatePercent || 0
      } catch (err) {
        state.offer.cleanVehicleDiscountPriceRate()
      }
      state.vehicleDiscountPriceRate = state.offer.vehicleDiscountPriceRate
      state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
      try {
        state.offer.additionalDiscountPriceRate = state.additionalDiscountPriceRate || 0
      } catch (err) {
        state.offer.cleanAdditionalDiscountPriceRate()
      }
      state.totalPrice = state.offer.getTotalPrice()
      state.servicesPrice = state.offer.getServicesPrice()
      state.servicesTotalPrice = state.offer.getServicesTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    } else {
      state.offer.vehiclePriceRateCCAA = null
      state.offer.cleanVehicleDiscountPriceRate()
      state.vehicleDiscountPriceRatePercent = 0
      state.vehicleDiscountPriceRate = 0
      state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    }
  }, //test
  SET_VEHICLE_DISCOUNT_PRICE_RATE: (state, discount) => {
    if (typeof discount === 'string' ? parseFloat(discount) : discount <= parseFloat(state.vehiclePriceRateCCAA)) {
      state.vehicleDiscountPriceRate = discount
      state.dealerCampaignValue = discount
      state.offer.dealerCampaignValue = discount
      state.offer.vehicleDiscountPriceRate = typeof discount === 'string' ? parseFloat(discount) : discount
      state.vehicleDiscountPriceRatePercent = state.offer.vehicleDiscountPriceRatePercent
      state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    }
    if (!discount || discount === 0 || discount === '0') {
      state.offer.cleanVehicleDiscountPriceRate()
      state.vehicleDiscountPriceRate = state.offer.vehicleDiscountPriceRate
      state.dealerCampaignValue = undefined
      state.offer.dealerCampaignValue = state.offer.vehicleDiscountPriceRate
      state.vehicleDiscountPriceRatePercent = state.offer.vehicleDiscountPriceRatePercent
      state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    }
  },
  SET_VEHICLE_DISCOUNT_PRICE_RATE_PERCENT: (state, discount) => {
    if (
      typeof discount === 'string'
        ? parseFloat(discount) >= 0 && parseFloat(discount) <= 100
        : discount >= 0 && discount <= 100
    ) {
      state.vehicleDiscountPriceRatePercent = discount
      state.offer.vehicleDiscountPriceRatePercent = typeof discount === 'string' ? parseFloat(discount) : discount
      state.vehicleDiscountPriceRate = state.offer.vehicleDiscountPriceRate
      state.dealerCampaignValue = state.offer.vehicleDiscountPriceRate
      state.offer.dealerCampaignValue = state.offer.vehicleDiscountPriceRate
      state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    }
    if (!discount || discount === 0 || discount === '0') {
      state.offer.cleanVehicleDiscountPriceRate()
      state.vehicleDiscountPriceRate = state.offer.vehicleDiscountPriceRate
      state.dealerCampaignValue = state.offer.vehicleDiscountPriceRate
      state.offer.dealerCampaignValue = state.offer.vehicleDiscountPriceRate
      state.vehicleDiscountPriceRatePercent = state.offer.vehicleDiscountPriceRatePercent
      state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    }
  },
  SET_STATUS: (state, status) => {
    state.status = status
    state.offer.status = status
    if (status !== Status.Pending) {
      state.probabilityOfSale = ProbabilityOfSale.Low // TODO: Remove when cleared from builder
      state.offer.probabilityOfSale = null
    }
  }, //test
  SET_PROBABILITY_OF_SALE: (state, probabilityOfSale) => {
    state.probabilityOfSale = ProbabilityOfSale.Low // TODO: Remove when cleared from builder
    state.offer.probabilityOfSale = probabilityOfSale
  }, //test
  SET_GROUP_TO_OFFER: (state, group) => {
    state.offer.setDealer({
      id: group.dealerId,
      code: group.code,
    })
  }, //test
  SET_CATALOG_CCAA_RATES_OPTIONS: (state, ccaaRates) => {
    state.ccaaRates = ccaaRates
  },
  SET_ANNOTATION: (state, annotation) => {
    state.annotation = annotation
    state.offer.annotation = annotation
  }, //test
  SET_REGISTRATION_PRICE: (state, price) => {
    state.registrationPrice = price
    state.offer.registrationPrice = !price ? 0 : typeof price === 'string' ? parseFloat(price) : price
    if (!price || (typeof price === 'string' ? parseFloat(price) : price) === 0) {
      state.offer.cleanRegistrationDiscount()
      state.registrationDiscount = state.offer.registrationDiscount
      state.registrationDiscountPercent = state.offer.registrationDiscountPercent
    }
    state.registrationTotalPrice = state.offer.getRegistrationTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_REGISTRATION_DISCOUNT: (state, discount) => {
    if (!discount || (typeof discount === 'string' ? parseFloat(discount) : discount) === 0) {
      state.offer.cleanRegistrationDiscount()
      state.registrationDiscount = state.offer.registrationDiscount
      state.registrationDiscountPercent = state.offer.registrationDiscountPercent
    } else {
      state.registrationDiscount = discount
      try {
        state.offer.registrationDiscount = typeof discount === 'string' ? parseFloat(discount) : discount
      } catch (err) {
        console.error(err)
      }
      state.registrationDiscountPercent = state.offer.registrationDiscountPercent
    }
    state.registrationTotalPrice = state.offer.getRegistrationTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_REGISTRATION_DISCOUNT_PERCENT: (state, discount) => {
    if (!discount || (typeof discount === 'string' ? parseFloat(discount) : discount) === 0) {
      state.offer.cleanRegistrationDiscount()
      state.registrationDiscount = state.offer.registrationDiscount
      state.registrationDiscountPercent = state.offer.registrationDiscountPercent
    } else {
      state.registrationDiscountPercent = discount
      try {
        let percentage = typeof discount === 'string' && discount !== '' ? parseFloat(discount) : discount
        state.offer.registrationDiscountPercent = percentage >= 100 ? 100 : percentage
      } catch (err) {
        console.error(err)
      }
      state.registrationDiscount = state.offer.registrationDiscount
    }
    state.registrationTotalPrice = state.offer.getRegistrationTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  SET_SERVICES_PRODUCT: (state, { product, country }) => {
    var products = reverseItemInArray(product, state.servicesProducts) //select or unselect service and get new array

    //unselect forced services HP & HPGo
    if (products.includes(Product.HondaPlus) && product === Product.HondaPlusGo) {
      products.splice(products.indexOf(Product.HondaPlus), 1)
    } else if (products.includes(Product.HondaPlusGo) && product === Product.HondaPlus) {
      products.splice(products.indexOf(Product.HondaPlusGo), 1)
    }

    if (state.selectedModel) {
      state.vehicleModelInsurance = state.selectedModel.insurance
      state.offer.setServicesHondaPlusPrice(
        products.includes(Product.HondaPlusGo)
          ? state.selectedModel.hpConfig?.HPG?.price
          : state.selectedModel.hpConfig?.HP?.price,
      )
    }

    //select other services on select HP or HPG
    if (products.includes(Product.HondaPlus) && product === Product.HondaPlus) {
      if (!products.includes(Product.HondaMapit)) products.push(Product.HondaMapit)
    } else if (products.includes(Product.HondaPlusGo) && product === Product.HondaPlusGo) {
      if (!products.includes(Product.HondaMapit)) products.push(Product.HondaMapit)
      if (!products.includes(Product.Insurance) && state.vehicleModelInsurance) products.push(Product.Insurance)
    }

    //unselect services without HP or HPGo
    if (!products.includes(Product.HondaPlus) && !products.includes(Product.HondaPlusGo)) {
      if (products.includes(Product.Insurance)) products.splice(products.indexOf(Product.Insurance), 1)
      if (products.includes(Product.Finance)) products.splice(products.indexOf(Product.Finance), 1)
    }

    state.servicesProducts = products
    state.offer.servicesProducts = products
    state.servicesDiscount = 0
    state.servicesDiscountPercent = 0
    state.offer.servicesDiscount = 0

    if (
      !state.servicesInsuranceType &&
      (products.includes(Product.HondaPlus) || products.includes(Product.HondaPlusGo)) &&
      products.includes(Product.Insurance)
    ) {
      state.servicesInsuranceType = InsuranceType.Terceros
      const priceByProvince = getPriceByProvince(state, InsuranceType.Terceros, country)
      if (priceByProvince) state.offer.setServicesInsurance(InsuranceType.Terceros, priceByProvince)
    }
    state.servicesPrice = state.offer.getServicesPrice()
    state.servicesTotalDiscount = state.offer.getServicesTotalDiscount()
    state.servicesTotalPrice = state.offer.getServicesTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_SERVICES_INSURANCE_TYPE: (state, { type, country }) => {
    state.servicesInsuranceType = type
    const priceByProvince = getPriceByProvince(state, type, country)
    if (priceByProvince) state.offer.setServicesInsurance(type, priceByProvince)
    state.servicesPrice = state.offer.getServicesPrice()
    state.servicesTotalPrice = state.offer.getServicesTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_SERVICES_DISCOUNT: (state, type) => {
    state.servicesDiscountError = null
    state.servicesDiscountPercentError = null
    try {
      state.servicesDiscount = type
      state.offer.servicesDiscount = type
      state.servicesDiscountPercent = state.offer.servicesDiscountPercent
      state.servicesTotalDiscount = state.offer.getServicesTotalDiscount()
      state.servicesTotalPrice = state.offer.getServicesTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    } catch (e) {
      console.warn('Discount error', e.message)
      state.servicesDiscountError = e.message
    }
  },
  SET_SERVICES_DISCOUNT_PERCENT: (state, percentage) => {
    state.servicesDiscountError = null
    state.servicesDiscountPercentError = null

    if (percentage < 0 || percentage > 100) {
      state.servicesDiscountPercentError = 'Discount percentage cannot be higher than 100%'
    }
    try {
      state.servicesDiscountPercent = percentage
      state.offer.servicesDiscountPercent = percentage
      state.servicesDiscount = state.offer.servicesDiscount
      state.servicesTotalDiscount = state.offer.getServicesTotalDiscount()
      state.servicesTotalPrice = state.offer.getServicesTotalPrice()
      state.totalPrice = state.offer.getTotalPrice()
    } catch (e) {
      console.warn('Discount percent error', e.message)
      state.servicesDiscountPercentError = e.message
    }
  },
  SET_DRIVING_LICENCE_DATE: (state, { drivingLicenceDate, country }) => {
    state.drivingLicenceDate = drivingLicenceDate
    state.offer.clientDrivingLicenceDate = drivingLicenceDate
    state.clientAge = state.offer.clientAge
    const priceByProvince = getPriceByProvince(state, state.servicesInsuranceType, country)
    if (priceByProvince) state.offer.setServicesInsurance(state.servicesInsuranceType, priceByProvince)
    state.servicesPrice = state.offer.getServicesPrice()
    state.servicesTotalPrice = state.offer.getServicesTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_DELIVERED_BRAND: (state, brand) => {
    state.deliveredBrand = brand
    state.offer.deliveredBrand = brand
  }, //test
  SET_DELIVERED_MODEL: (state, model) => {
    state.deliveredModel = model
    state.offer.deliveredModel = model
  }, //test
  SET_DELIVERED_AMOUNT: (state, amount) => {
    if (amount && (typeof amount === 'string' ? parseFloat(amount) : amount) >= 0) {
      state.deliveredAmount = typeof amount === 'string' ? parseFloat(amount) : amount
      state.offer.deliveredAmount = typeof amount === 'string' ? parseFloat(amount) : amount
    } else {
      state.deliveredAmount = null
      state.offer.deliveredAmount = null
    }
    state.totalPrice = state.offer.getTotalPrice()
  }, //test
  ADD_COMPLEMENT: (state) => {
    state.offer.addComplement()
    state.complements = state.offer.complementsComplements
  },
  SET_COMPLEMENTS: (state, complements) => {
    state.offer.setComplements({ complements })
    state.complements = state.offer.complementsComplements
    state.complementsTotalPrice = state.offer.getComplementsTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_COMPLEMENT_NAME: (state, { name, index }) => {
    state.offer.setComplementName(name, index)
    state.complements = state.offer.complementsComplements
  },
  SET_COMPLEMENT_PRICE: (state, { price, index }) => {
    state.offer.setComplementPrice(price || 0, index)
    state.complements = state.offer.complementsComplements
    state.complementsTotalPrice = state.offer.getComplementsTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_COMPLEMENT_DISCOUNT: (state, { discount, index }) => {
    state.offer.setComplementDiscount(discount || 0, index)
    state.complements = state.offer.complementsComplements
    state.complementsTotalPrice = state.offer.getComplementsTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_COMPLEMENT_DISCOUNT_PERCENT: (state, { discount, index }) => {
    state.offer.setComplementDiscountPercent(discount || 0, index)
    state.complements = state.offer.complementsComplements
    state.complementsTotalPrice = state.offer.getComplementsTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  ADD_PACK: (state) => {
    state.offer.addPack()
    state.packs = state.offer.packsPacks
  },
  SET_PACK: (state, { pack, index }) => {
    state.offer.setPack(pack, index)
    state.packs = state.offer.packsPacks
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_PACKS: (state, packs) => {
    state.offer.setPacks({ packs })
    state.packs = state.offer.packsPacks
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_PACK_DISCOUNT: (state, { discount, index }) => {
    state.offer.setPackDiscount(discount, index)
    state.packs = state.offer.packsPacks
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_PACK_DISCOUNT_PERCENT: (state, { discount, index }) => {
    state.offer.setPackDiscountPercent(discount, index)
    state.packs = state.offer.packsPacks
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_TYPE: (state, type) => {
    state.type = type
    state.offer.setType(type)
  },
  SET_SUBAGENT_NAME: (state, name) => {
    state.subagentName = name
    state.offer.setSubagentName(name)
  },
  RESET_OFFER(state) {
    const initialState = getOfferInitialState(new HondaOfferBuilder().setProbabilityOfSale(ProbabilityOfSale.Low))
    Object.keys(initialState).forEach((key) => {
      if (!persitedKeys.includes(key)) {
        state[key] = initialState[key]
      }
    })
  },
  DECOLLAPSE_PANEL: (state, panel) => {
    state.decollapsedPanels.push(panel)
  },
  COLLAPSE_PANEL: (state, panel) => {
    state.decollapsedPanels = state.decollapsedPanels.filter((item) => item !== panel)
  },
  SET_DECOLLAPSED_PANELS: (state, panels) => {
    state.decollapsedPanels = panels
  },
  SET_ADDITIONAL_DISCOUNT_PRICE_RATE: (state, discount) => {
    state.additionalDiscountPriceRate = discount
    state.offer.additionalDiscountPriceRate = !discount ? 0 : typeof discount === 'string' ? parseFloat(discount) : discount
    state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  SET_OFFER_FINANCE: (state, finance) => {
    state.offer.setFinance(finance)
    state.finance = state.offer.getFinance()
  },
  SET_VEHICLE_MODEL_PRICE_RATE: (state, priceRate) => {
    state.vehicleModelPriceRate = priceRate
  },
  SET_IS_FINANCE_UPDATED: (state, isUpdated) => {
    state.isFinanceUpdated = isUpdated
  },
  SET_SORT_BY: (state, sortBy) => {
    state.sortBy = sortBy
  },
  CLEAN_PACKS_AND_SERVICES: (state) => {
    const newOffer = new HondaOfferBuilder().setProbabilityOfSale(ProbabilityOfSale.Low)
    state.servicesProducts = newOffer.servicesProducts
    state.servicesDiscount = newOffer.servicesDiscount
    state.servicesDiscountPercent = newOffer.servicesDiscountPercent
    state.servicesTotalPrice = newOffer.getServicesTotalPrice()
    state.servicesPrice = newOffer.getServicesPrice()
    state.servicesInsuranceType = newOffer.servicesInsuranceType
    state.clientDob = newOffer.clientDob
    state.clientDrivingLicenceDate = newOffer.clientDrivingLicenceDate
    state.offer.campaign = newOffer.campaign
    state.offer.servicesProducts = newOffer.servicesProducts
    state.offer.servicesInsuranceType = newOffer.servicesInsuranceType
    state.offer.clientDob = newOffer.clientDob
    state.offer.clientDrivingLicenceDate = newOffer.clientDrivingLicenceDate
    state.offer.servicesPrice = newOffer.getServicesTotalPrice()
    state.offer.servicesTotalPrice = 0
    state.offer.servicesDiscount = newOffer.servicesDiscount
    state.offer.servicesDiscountPercent = newOffer.servicesDiscountPercent

    state.vehicleModelPacks = []
    state.offer.clearPacks()
    state.offer.addPack()
    state.packs = state.offer.packsPacks
    state.finance = null
    state.vehicleTotalPrice = state.offer.getVehicleTotalPrice()
    state.totalPrice = state.offer.getTotalPrice()
  },
  CLEAR_OFFER_CONTACT: (state) => {
    state.offerContact = null
    state.clientAddressCountry = null
    state.offer.clientAddressCountry = null
    state.clientProvince = null
    state.offer.clientAddressProvince = null
  },
  CLEAR_OFFER_PDF: (state) => {
    state.pdfUrl = null
    state.offerCode = null
    state.offerStatus = null
  },
}

const calculateFirstLevelRegionPrice = (country, summary, province, co2Rates) => {
  if (!co2Rates || !province || Object.keys(co2Rates).length === 0) return summary?.price?.amount

  const secondLevelRegion = findSecondLevelRegionByName(province, country)

  return Math.round(
    (summary?.price?.amount / (1 + 0.21 + co2Rates.BASE_PRICE[summary.co2IdRate])) *
      (1 + 0.21 + co2Rates[secondLevelRegion.parent || 'BASE_PRICE'][summary.co2IdRate]),
  )
}

export const getPriceByProvince = (state, type, country) => {
  if (!type) return 0

  const ratesByType = get(state.vehicleModelInsurance?.rates, mapInsuranceTypeToInsuranceModality(type))
  const provinceCode = findSecondLevelRegionByName(state.clientProvince)?.id
  const clientDrivingLicenceYear = DateTime.fromISO(state.drivingLicenceDate)?.get('year')
  const years = clientDrivingLicenceYear ? DateTime.now().get('year') - clientDrivingLicenceYear : 0

  if (!ratesByType) return 0
  const rateSelected = find(ratesByType[provinceCode] ?? ratesByType[country], (rate) => {
    return inRange(years, rate.ageRange.from, rate.ageRange.to)
  })

  return rateSelected?.price
}

export const mapInsuranceTypeToInsuranceModality = (type) => {
  switch (type) {
    case InsuranceType.Terceros:
      return InsuranceModality.ThirdParty
    case InsuranceType.RoboIncendio:
      return InsuranceModality.ThirdPartyFireAndTheft
    case InsuranceType.TodoRiesgo:
      return InsuranceModality.Comprehensive
  }
}

export const mapInsuranceModalityToInsuranceType = (modality) => {
  switch (modality) {
    case InsuranceModality.ThirdParty:
      return InsuranceType.Terceros
    case InsuranceModality.ThirdPartyFireAndTheft:
      return InsuranceType.RoboIncendio
    case InsuranceModality.Comprehensive:
      return InsuranceType.TodoRiesgo
  }
}

// name: FinanceName;
// rate: number;
// depositRate: number;
// feeRate: number;
// finalInstallmentRate ?: number;
const mapFinanceMode = (offerFinance, summaryFinance) => {
  const mode = offerFinance?.mode
  if (!mode) return undefined

  const financeOption = summaryFinance[mode.name]

  if (!financeOption) return mode
  return {
    ...financeOption,
    name: mode.name,
  }
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}
