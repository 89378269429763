<template>
  <v-app class="honda-registration-wrapper pb-12" id="honda-registration">
    <v-overlay :value="mainLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </v-overlay>

    <v-card v-if="isArchiving" class="honda-registration-card-wrapper honda-loader">
      <v-progress-circular
        class="honda-loader-spinner"
        indeterminate
        color="primary"
      />
    </v-card>

    <v-card v-show="!isArchiving" class="honda-registration-card-wrapper">
      <v-card-title>
        <toolbar-card
          @openCloseCancelDialog="openCloseCancelDialog"
          :title="$t(isTransferBike ? 'honda.registration.vehicle.title_transfer': 'honda.registration.vehicle.title')"
        />
      </v-card-title>
      <v-divider class="stepper-separator mx-6" />
      <stepper :key="refreshKey" @archiving="isArchiving = true" />
    </v-card>

    <dialog-exit-process :dialog="cancelDialog" @exit="exit" @openClose="openCloseCancelDialog" />
  </v-app>
</template>

<script>
import Stepper from './Stepper'
import ToolbarCard from './ToolbarCard'
import DialogExitProcess from '@/components/dialog/DialogExitProcess'
import { RegistrationTypes } from '@/utils/constants'
import { accountRegistrationModel } from '@/vuex/models'

export default {
  name: 'HondaRegistration',
  components: {
    Stepper,
    ToolbarCard,
    DialogExitProcess
  },
  data() {
    return {
      mainLoading: false,
      cancelDialog: false,
      isArchiving: false,
      refreshKey: 0
    }
  },
  watch: {
    $route(to, from) {
      this.$store.dispatch('resetRegistration')
      .then(() => {
        this.initRegistration()
      })
    }
  },
  mounted() {
    this.$store.dispatch('resetRegistration')
      .then(() => {
        this.initRegistration()
      })
  },
  methods: {
    initRegistration() {
      if(this.$route.query.forcedType === RegistrationTypes.OFFER_REGISTRATION){
        this.mainLoading = true
        this.$store.dispatch('initNewOffer')
        this.$store.dispatch('loadOfferById', this.$route.query.offerId)
          .finally(() => {
            this.mainLoading = false
            this.$store.dispatch('setForcedType', RegistrationTypes.OFFER_REGISTRATION)
            this.$store.dispatch('changeOfferNumber', this.offer.code.split('-')[1])

            const account = accountRegistrationModel();
            account.email.value = this.offer.clientEmail || ''
            account.firstName.value = this.offer.clientName || ''
            account.lastName.value = this.offer.clientSurname || ''
            account.phone.value = this.offer.clientPhone || ''
            account.dob.value = this.offer.clientDob || ''
            account.address.addressRegion.value = this.offer.clientAddressProvince || ''
            this.$store.dispatch('addAccount', account)
          })
      } else if (this.isTransferBike){
        if(this.getSelectedTransfer){
          this.$store.dispatch('setForcedType', RegistrationTypes.HONDA_MAPIT_TRANSFERRED)
          this.$store.dispatch('initTransferRegistration', this.getSelectedTransfer)
        } else {
          this.$store.dispatch('setForcedType', RegistrationTypes.HONDA_PLUS)
        }
      }else{
        this.$store.dispatch('setSelectedTransfer', null)
        this.$store.dispatch('setForcedType', this.$route.query.forcedType)
      }
      this.refreshKey ++
    },
    openCloseCancelDialog() {
      this.cancelDialog = !this.cancelDialog;
    },
    exit() {
      this.openCloseCancelDialog()
      this.$router.push("/")
      this.$store.dispatch('resetRegistration')
    }
  },
  computed: {
    getSelectedTransfer() {
      return this.$store.getters.getSelectedTransfer
    },
    offer() {
      return this.$store.getters.getOffer
    },
    isTransferBike() {
      return this.$route.query.forcedType === RegistrationTypes.HONDA_MAPIT_TRANSFERRED
    },
  },
}
</script>

<style>
.honda-registration-card-wrapper {
  margin: 0 auto;
  width: 90%;
  margin-top: 40px;
}
.stepper-separator {
  border-color: var(--v-gray-base) !important;
}

.honda-registration-wrapper {
  background-color: var(--v-background-base) !important;
}

.honda-loader {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 1.0);
  z-index: 2;
}
.honda-loader-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<style lang="scss" src="./registration-styles.scss" />
