<template>
  <div>
    <v-row class="flex justify-start" no-gutters>
      <v-col class="col-md-4 col-sm-12 px-1 py-1">
        <m-select
          :items="filterByStatus"
          :multiple="true"
          :placeholder="$t('dealer-bike.list.lbl-filter-status')"
          :showSelectedCounter="true"
          :value="selectedStatus"
          @input="(value) => (selectedStatus = value)"
          data-cy="dealerBikesStatusFilter"
        >
          <template v-slot:prepend-inner>
            <i class="icon-mapit icon-filter-status"></i>
          </template>
        </m-select>
      </v-col>

      <v-col class="col-md-4 col-sm-12 px-1 py-1">
        <m-select
          :items="filterByType"
          :multiple="true"
          :placeholder="$t('dealer-bike.list.lbl-filter-type')"
          :showSelectedCounter="true"
          :value="selectedType"
          @input="(value) => (selectedType = value)"
          data-cy="dealerBikesTypeFilter"
        >
          <template v-slot:prepend-inner>
            <i class="icon-mapit icon-filter-status"></i>
          </template>
        </m-select>
      </v-col>

      <v-col class="col-md-4 col-sm-12 px-1 py-1">
        <m-text-field
          :placeholder="$t('dealer-bike.list.lbl-search')"
          data-cy="dealerBikesSearchFilter"
          v-model.trim="search"
        />
      </v-col>
      <v-spacer class="d-none d-xl-block" />
    </v-row>

    <v-data-table
      id="dealer-bike-table-list"
      :loading="loading"
      :loading-text="$t('dealer-bike.list.table.loading')"
      :headers="headers"
      :items="filteredDealerBikes"
      :item-class="'click'"
      sort-by="vehicle.registrationDate"
      class="elevation-0 pt-4"
      @click:row="onRowClick"
      :no-data-text="
        $t(
          filteredDealerBikes && filteredDealerBikes.length > 0
            ? 'dealer-bike.list.table.no-data-search'
            : 'dealer-bike.list.table.no-data',
          { search },
        )
      "
      :no-results-text="$t('dealer-bike.list.table.no-data-search')"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'fas fa-angle-double-left',
        lastIcon: 'fas fa-angle-double-right',
        prevIcon: 'fas fa-angle-left',
        nextIcon: 'fas fa-angle-right',
        'items-per-page-text': $t('dealer-bike.list.table.footer_num_x_page'),
        'items-per-page-options': [10, 25, 50, -1],
      }"
    >
      <template #footer.page-text="props">
        {{ props.pageStart }}-{{ props.pageStop }} {{ $t('honda.registration.list.of') }}
        {{ props.itemsLength }}
      </template>

      <template v-slot:item.vehicle.model="{ item }">
        <div class="vehicle py-4">
          <span class="vehicle-model"> {{ item.vehicle.model }} </span><br />
          <span class="vehicle-color">{{ item.vehicle.color }}</span>
        </div>
      </template>

      <template v-slot:item.vehicle.registrationNumber="{ item }">
        <m-copy
          :text-to-copy="`${item.vehicle.registrationNumber}, ${item.vehicle.vin}`"
          @onCopy="onCopyClick"
        >
          <div class="vehicle align-left">
            <span class="vehicle-model">{{ item.vehicle.registrationNumber }}</span
            ><br />
            <span class="vehicle-color">{{ item.vehicle.vin }}</span>
          </div>
        </m-copy>
      </template>

      <template v-slot:item.usageType="{ item }">
        <v-tooltip bottom v-if="item.usageType">
          <template v-slot:activator="{ on, attrs }">
            <v-chip class="ma-2" outlined label text-color="black" v-bind="attrs" v-on="on">
              {{
                item.usageType === UsageType.Demo
                  ? $t('dealer-bike.loans.type.demo')
                  : $t('dealer-bike.loans.type.courtesy')
              }}
            </v-chip>
          </template>
          <span>{{ t(`type.${item.usageType}`) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.vehicle.registrationDate="{ item }">
        <span class="text-item">{{
          item.vehicle.registrationDate ? toDateFormat(item.vehicle.registrationDate) : '-'
        }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <v-tooltip bottom color="black" :disabled="item.status !== DealerBikeStatusPortal.Expired">
          <template v-slot:activator="{ on, attrs }">
            <div class="text-item" v-bind="attrs" v-on="on">
              <v-icon size="8" :color="item.statusColor">fa-solid fa-circle</v-icon>
              <span>&nbsp;{{ t(`status.${item.status}`) }}</span>
            </div>
          </template>
          <span>{{ t(`table.tooltip-expired`) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-menu transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" :data-cy="`actionsMenu${dealerBikeList.indexOf(item)}`">
              <v-icon>fa-light fa-ellipsis</v-icon>
            </div>
          </template>

          <v-list dense class="list-menu-actions">
            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Available)"
              @click="onActionClick(item, DealerBikeActions.Available)"
            >
              <v-list-item-title>
                {{ t('actions.available') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Unavailable)"
              @click="onActionClick(item, DealerBikeActions.Unavailable)"
            >
              <v-list-item-title>
                {{ t('actions.unavailable') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Transfer)"
              @click="onActionClick(item, DealerBikeActions.Transfer)"
            >
              <v-list-item-title>
                {{ t('actions.transfer') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Courtesy)"
              @click="onActionClick(item, DealerBikeActions.Courtesy)"
            >
              <v-list-item-title>
                {{ t('actions.courtesy') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Register)"
              :disabled="!permissions.dealerBikes.canCreate"
              @click="onActionClick(item, DealerBikeActions.Register)"
            >
              <v-list-item-title>
                {{ t('actions.register') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Unregister)"
              @click="onActionClick(item, DealerBikeActions.Unregister)"
            >
              <v-list-item-title>
                {{ t('actions.unregister') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.Detail)"
              @click="onActionClick(item, DealerBikeActions.Detail)"
            >
              <v-list-item-title>
                {{ t('actions.detail') }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item
              v-if="renderAction(item, DealerBikeActions.RemoveBike)"
              @click="onActionClick(item, DealerBikeActions.RemoveBike)"
            >
              <v-list-item-title>
                {{ t('actions.remove_bike') }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>

    <mapit-dialog :dialog="dialogOpen" custom-width="610px" @close="hideDialog">
      <template v-slot:header-icon>
        <v-icon color="orange">fa-solid fa-triangle-exclamation</v-icon>
      </template>

      <template v-slot:header-text>
        <p class="dialog-title text-center" v-html="dialog.header"></p>
      </template>

      <template v-slot:body>
        <div class="text-center">
          <div v-html="dialog.content"></div>

          <p v-if="dialog.extra" class="dialog-extra-link">
            <span>{{ dialog.extra }}&nbsp;</span>
            <a href="#" @click.prevent="onLinkClick">{{ dialog.link }}</a>
          </p>
        </div>
      </template>

      <template v-slot:footer-center>
        <v-btn class="btn-gray" @click="hideDialog" data-cy="cancelAction">{{
          $t('buttons.cancel')
        }}</v-btn>
        <v-btn
          :disabled="loading"
          :key="`${selectedAction}${buttonKey}`"
          :loading="loading"
          @click.once="dialog.onSubmit"
          class="btn-primary"
          data-cy="confirmAction"
          >{{ dialog.cta }}
        </v-btn>
      </template>
    </mapit-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapGetters, mapState } from 'vuex'

import { DealerBikeActions, DealerBikeStatusPortal, UsageType } from '@/types/dealer-bikes'
import { getDealerBikeActions } from '@/utils/dealer-bikes'
import { toDateFormat } from '@/utils/date-helper'
import { searchByVehicleInfo } from '@/utils/search'
import { RegistrationTypes } from '@/utils/constants'
import { mapDealerBikeActionToStoreAction } from '@/mappers/dealer-bikes'

import MapitDialog from '@/components/dialog/MapitDialog'
import MCopy from '@/components/atom/MCopy'

export default {
  name: 'DealerBikesTable',
  components: { MCopy, MapitDialog },
  data() {
    return {
      buttonKey: 1,
      dialogOpen: false,
      dialogContent: this.generateDialog({ action: DealerBikeActions.Available }),
      selectedId: undefined,
      selectedAction: DealerBikeActions.Available,
      selectedUsage: UsageType.Demo,
      selectedLicense: undefined,
      DealerBikeStatusPortal,
      DealerBikeActions,
      UsageType,
      toDateFormat,
      headers: [
        {
          text: this.t('table.model-color'),
          align: 'left',
          value: 'vehicle.model',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.t('table.plate-vin'),
          align: 'left',
          value: 'vehicle.registrationNumber',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.t('table.type'),
          align: 'center',
          value: 'usageType',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.t('table.enrollment-date'),
          align: 'left',
          value: 'vehicle.registrationDate',
          class: 'text-header',
          sortable: true,
          sort: this.sortByDate,
        },
        {
          text: this.t('table.state'),
          align: 'left',
          value: 'status',
          class: 'text-header',
          sortable: false,
        },
        {
          text: this.t('table.actions'),
          align: 'right',
          class: 'text-header',
          sortable: false,
          value: 'actions',
        },
      ],
      search: '',
      selectedStatus: [
        DealerBikeStatusPortal.Available,
        DealerBikeStatusPortal.Expired,
        DealerBikeStatusPortal.HandedOver,
        DealerBikeStatusPortal.Candidate,
        DealerBikeStatusPortal.Unavailable,
      ],
      selectedType: [],
    }
  },
  computed: {
    ...mapState(['permissions']),
    ...mapGetters({
      loading: 'isLoadingDealerBikes',
      dealerBikeList: 'getDealerBikeList',
      dealerBikeSelected: 'getDealerBikeSelected',
    }),
    filteredDealerBikes() {
      return this.dealerBikeList
        .filter((d) => !this.selectedStatus.length || this.selectedStatus.includes(d.status))
        .filter((d) => !this.selectedType.length || this.selectedType.includes(d.usageType))
        .filter((d) => !this.search || searchByVehicleInfo(this.search, d.vehicle))
    },
    filterByStatus() {
      return this.generateFilters(DealerBikeStatusPortal, 'status')
    },
    filterByType() {
      return this.generateFilters(UsageType, 'type')
    },
    dialog: {
      get() {
        return this.dialogContent
      },
      set(value) {
        this.dialogContent = value
      },
    },
  },
  methods: {
    t(id, content) {
      return this.$t(`dealer-bike.list.${id}`, content)
    },
    generateFilters(category, scope) {
      return Object.keys(category).map((key) => ({
        text: this.t(`${scope}.${category[key]}`),
        value: category[key],
      }))
    },
    generateDialog({ action, content, extra, onSubmit }) {
      const modal = (key) => this.t(`dialog.${action}.${key}`, content)
      const extraLink = extra ? { extra: modal('extra'), link: modal('link') } : null

      return {
        header: modal('title'),
        content: modal('body'),
        cta: modal('cta'),
        ...extraLink,
        onSubmit,
      }
    },
    sortByDate(a, b) {
      return !a || DateTime.fromISO(b) < DateTime.fromISO(a) ? -1 : 1
    },
    renderAction(dealerBike, action) {
      return getDealerBikeActions(dealerBike).includes(action)
    },
    onRowClick(dealerBike) {
      if (dealerBike.status !== DealerBikeStatusPortal.Candidate) {
        this.selectedId = dealerBike.id
        this.redirectTo('DealerBikeDetail')
      }
    },
    onCopyClick() {
      this.$store.dispatch('showAlertSuccess', 'common.copy-success')
    },
    onLinkClick() {
      this.dialog = this.generateDialog({
        action: DealerBikeActions.Deactivate,
        content: { registrationNumber: this.selectedLicense },
        extra: false,
        onSubmit: () => this.changeStatus(DealerBikeActions.Deactivate),
      })
    },
    onActionClick(dealerBike, action) {
      const { id, usageType, vehicle } = dealerBike

      this.selectedId = id
      this.selectedAction = action
      this.selectedUsage = usageType
      this.selectedLicense = vehicle?.registrationNumber

      if (dealerBike) {
        this.$store.dispatch('setDealerBikeSelected', dealerBike)
      }

      if (action === DealerBikeActions.Register) {
        this.redirectTo('DealerBikesRegistration')
        return
      }

      if (action === DealerBikeActions.Transfer) {
        this.redirectTo('DealerBikesLoan')
        return
      }

      if (action === DealerBikeActions.Detail) {
        this.redirectTo('DealerBikeDetail')
        return
      }

      const content = {
        usageType: this.t(`type.${usageType}`),
        vin: vehicle.vin,
      }

      this.dialog = this.generateDialog({
        action,
        content,
        extra: action === DealerBikeActions.Unregister,
        onSubmit: this.changeStatus,
      })

      this.showDialog()
    },
    async changeStatus(customAction) {
      const action = mapDealerBikeActionToStoreAction(this.selectedAction)

      try {
        await this.$store.dispatch(action, {
          id: this.selectedId,
          usage: this.t(`type.${this.selectedUsage}`),
          showAlert: !DealerBikeActions.Unregister,
        })
      } catch (error) {
        console.error(error)
        this.hideDialog()
        return
      }

      if (
        DealerBikeActions.Deactivate !== customAction &&
        DealerBikeActions.Unregister === this.selectedAction
      ) {
        this.$store.dispatch('setSelectedTransfer', this.dealerBikeSelected)
        this.redirectTo('HondaRegistration', {
          forcedType: RegistrationTypes.HONDA_MAPIT_TRANSFERRED,
        })
        return
      }

      this.hideDialog()
    },
    showDialog() {
      this.dialogOpen = true
    },
    hideDialog() {
      this.dialogOpen = false
      this.selectedId = undefined
      this.buttonKey++
    },
    redirectTo(page, query) {
      this.$router.push({ name: page, params: { dealerBikeId: this.selectedId }, query })
    },
  },
}
</script>

<style scoped>
.dialog-title {
  word-break: normal;
}

.dialog-extra-link {
  color: #aaa;
}

.dialog-extra-link > a {
  color: #aaa !important;
  text-decoration: underline !important;
}
</style>
