
import provincias from '@/assets/json/provincias.json';
import cities from '@/assets/json/municipios.json';
import countries from '@/assets/json/country.json';
import moment from 'moment'

const helper = {
  state: {
    provincias,
    cities,
    countries
  },
  getCities(state){
    let res = [];
    cities.forEach(function (value) {
      if(value.id.substring(0,2) === state){
        res.push(value);
      }
    });
    return res;
  },
  getProvincia(state){
    let res = '';
    provincias.forEach(function (value) {
      if(value.id === state){
        res = value.nm;
      }
    });
    return res;
  },
  getProvinciaIdByName(stateName){
    let res = '';
    provincias.forEach(function (value) {
      if(value.nm === stateName){
        res = value.id;
      }
    });
    return res;
  },
  getAllCities(){
    return cities;
  },
  getAllProvincias(){
    return provincias;
  },
  getCitiesByStateId(state){
    return state ? cities.filter(item => parseInt(item.id.substring(0,2)) === state) : cities
  },
  getAllCitiesStringArray(state){
    return state ? this.getCitiesByStateId(state).map(item => item.nm) : cities.map(item => item.nm)
  },
  getAllProvinciasStringArray(){
    return provincias.map(item => item.nm);
  },
  validateEmail(email) {
    if (email && email.length > 0) {
      // eslint-disable-next-line
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    } else {
      return true;
    }
  },
  transform(value, type) {
    let newValue;
    switch (type) {
      case "DateTime":
        newValue = value ? moment(value).format("DD/MM/YYYY HH:mm:ss") : '';
        break;
      case "Date":
        newValue = value ? moment(value).format("DD/MM/YYYY") : '';
        break;
      default:
        newValue = value;
    }
    return newValue;
  },
  check_dni(dni) {
    let numero;
    let letr;
    let letra;
    let expresion_regular_dni;
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
    let expresion_regular_dni2 = /^\d{8}-[a-zA-Z]$/;

    if (expresion_regular_dni.test(dni) === true || expresion_regular_dni2.test(dni) === true) {
      if (dni.length === 10) {
        numero = dni.substr(0, dni.length - 2);
      } else {
        numero = dni.substr(0, dni.length - 1);
      }
      letr = dni.substr(dni.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);
      if (letra !== letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  },
  check_nie(nie) {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = nie.toUpperCase().charAt( 0 );

    switch (nie_prefix) {
      case 'X': nie_prefix = 0; break;
      case 'Y': nie_prefix = 1; break;
      case 'Z': nie_prefix = 2; break;
    }
    return this.check_dni(nie_prefix + nie.substr(1));
  },
  check_cif(cif) {


    if (!cif || cif.length !== 9) {
      return false;
    }

    cif = cif.toUpperCase();

    var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var i;
    var digit;

    if (!letter.match(/[A-Z]/)) {
      return false;
    }

    for (i = 0; i < digits.length; ++i) {
      digit = parseInt(digits[i]);

      if (isNaN(digit)) {
        return false;
      }

      if (i % 2 === 0) {
        digit *= 2;
        if (digit > 9) {
          digit = parseInt(digit / 10) + (digit % 10);
        }

        sum += digit;
      } else {
        sum += digit;
      }
    }

    sum %= 10;
    if (sum !== 0) {
      digit = 10 - sum;
    } else {
      digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
      return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
      return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
  },
  getCountryByIBAN(iban) {
    let country= countries.find(function(f){return f.code == iban.substring(0, 2)});
    if (country) {
      return country.name;
    } else {
      return "";
    }
  },
  replace(string, object, regex) {
    return String(string).replace(regex || (/\\?\{([^{}]+)\}/g), function(match, name) {
      return (object[name] != null) ? object[name] : match;
    });
  },

};

export default helper;
