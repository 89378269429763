import coreApi from '@/api/core.api'
import registrationsApi from '@/api/registrations.api'
import { makeVehicleModelName } from '@/helpers/utils'
import { RegistrationTypes } from '@/utils/constants'
import { RegistrationResolutionStatus } from '@mapit/core-dynamodb/dist/types'

const state = {
  registrationsCount: 0,
  forcedType: RegistrationTypes.HONDA_PLUS,
  selectedTransfer: null,
  subagentSuggestions: [],
  orderBySuggestions: [],
  lastFocus: '',
  lastEvent: null,
  dealersList: [],
  modelsList: [],
  ccaaRates: null,
  alertResult: {
    type: 'success',
    stringId: '',
    customString: '',
    open: false,
  },
  imeiNeedsClearing: false,
}

const getters = {
  getPendingRegistrations: (state) => state.registrationsCount,
  getForcedType: (state) => state.forcedType,
  getSelectedTransfer: (state) => state.selectedTransfer,
  getSubagentSuggestions: (state) => state.subagentSuggestions,
  getOrderBySuggestions: (state) => state.orderBySuggestions,
  getLastFocus: (state) => state.lastFocus,
  getDealersList: (state) => state.dealersList,
  getModelsList: (state) => state.modelsList,
  getCCSSRates: (state) => state.ccaaRates,
  getAlertResult: (state) => state.alertResult,
  getDealerAddressRegion: (state) => state.group?.profile?.shop?.address?.addressRegion,
  imeiNeedsClearing: (state) => state.imeiNeedsClearing,
}

const actions = {
  setPendingRegistrations: ({ commit }, dealerId) => {
    return new Promise((resolve, reject) => {
      registrationsApi
        .getRegistrations({
          dealer: {
            id: dealerId,
          },
          pagination: {
            size: 1,
          },
          params: {
            archived: false,
            accountRegistrationCompleted: false,
            contractsSigned: false,
            deviceHealthChecked: false,
            resolutionStatus: RegistrationResolutionStatus.ActionsNeeded,
          },
        })
        .then((res) => {
          if (res.total?.value != null) {
            commit('SET_PENDING_REGISTRATIONS_COUNT', res.total.value)
            resolve(res.total.value)
          }
        })
        .catch((e) => {
          commit('SET_PENDING_REGISTRATIONS_COUNT', 0)
          reject(e)
        })
    })
  },
  setForcedType: ({ commit }, forcedType) => {
    commit(
      'SET_FORCED_TYPE',
      Object.keys(RegistrationTypes).indexOf(forcedType) >= 0
        ? forcedType
        : RegistrationTypes.HONDA_PLUS,
    )
  },
  setSelectedTransfer({ commit }, selectedTransfer) {
    commit('SET_SELECTED_TRANSFER', selectedTransfer)
  },
  addSubagentSuggestion({ commit, state }, newSuggestion) {
    if (
      typeof newSuggestion !== 'string' ||
      state.subagentSuggestions.indexOf(newSuggestion) !== -1
    )
      return
    commit('ADD_SUBAGENT_SUGGESTION', newSuggestion)
  },
  addOrderBySuggestion({ commit, state }, newSuggestion) {
    if (typeof newSuggestion !== 'string' || state.orderBySuggestions.indexOf(newSuggestion) !== -1)
      return
    commit('ADD_ORDER_BY_SUGGESTION', newSuggestion)
  },
  setLastFocus({ commit }, focusUid) {
    commit('SET_LAST_FOCUS', focusUid)
  },
  loadDalersList({ commit }, internal = false) {
    return new Promise((resolve, reject) => {
      coreApi
        .getDealersList(internal)
        .then((res) => {
          commit('SET_DEALERS_LIST', res.data)
          resolve(res.data)
        })
        .catch((e) => {
          commit('SET_DEALERS_LIST', [])
          reject(e)
        })
    })
  },
  setAlertResult: ({ commit }, alertResult) => {
    commit('SET_ALERT_RESULT', alertResult)
  },
  showAlertInfo: ({ commit }, stringId, content) => {
    commit('SET_ALERT_RESULT', { open: true, stringId, content, type: 'info' })
  },
  showAlertSuccess: ({ commit }, stringId, content) => {
    commit('SET_ALERT_RESULT', { open: true, stringId, content, type: 'success' })
  },
  showAlertError: ({ commit }, stringId, content) => {
    commit('SET_ALERT_RESULT', { open: true, stringId, content, type: 'error' })
  },
  imeiNeedsClearing: ({ commit }, needsClearing) => {
    commit('SET_IMEI_NEEDS_CLEARING', needsClearing)
  },
}

const mutations = {
  SET_ALERT_RESULT: (state, alertResult) => {
    state.alertResult = alertResult
  },
  SET_PENDING_REGISTRATIONS_COUNT: (state, count) => {
    state.registrationsCount = count
  },
  SET_FORCED_TYPE: (state, forcedType) => {
    state.forcedType = forcedType
  },
  SET_SELECTED_TRANSFER: (state, selectedTransfer) => {
    state.selectedTransfer = selectedTransfer
  },
  ADD_SUBAGENT_SUGGESTION: (state, newSuggestion) => {
    state.subagentSuggestions.push(newSuggestion)
  },
  ADD_ORDER_BY_SUGGESTION: (state, newSuggestion) => {
    state.orderBySuggestions.push(newSuggestion)
  },
  RESET_STORE(state) {
    state.registrationsCount = 0
    state.forcedType = RegistrationTypes.HONDA_PLUS
    state.selectedTransfer = null
  },
  SET_LAST_FOCUS: (state, focusUid) => {
    state.lastFocus = focusUid
  },
  SEND_GA_EVENT: (state, action) => {
    state.lastEvent = action
  },
  SET_DEALERS_LIST: (state, dealersList) => {
    state.dealersList = dealersList
  },
  CLEAN_VEHICLE_MODELS(state) {
    state.modelsList = []
  },
  SET_VEHICLE_MODELS(state, catalog) {
    const mappedModels = catalog.map((model) => {
      return {
        modelName: model.modelName,
        name: makeVehicleModelName(model),
        hondaPlusGo: model.hondaPlusGo > 0,
        color: model.color,
      }
    })
    state.modelsList = mappedModels.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
  },
  SET_IMEI_NEEDS_CLEARING: (state, needsClearing) => {
    state.imeiNeedsClearing = needsClearing
  },
}

export default {
  namespaced: false,
  state,
  getters,
  actions,
  mutations,
}

export const showAlert = (dispatch, type, text, timeout) => {
  dispatch('setAlertResult', {
    type: type,
    stringId: text,
    open: true,
    timeout,
  })
}

export const closeAlert = (dispatch) => {
  dispatch('setAlertResult', {
    open: false,
  })
}
