<template>
  <div class="users-table form-card high-size custom-table">
    <form id="search">
      <div class="form-group columns m-0 tr-body b-r-0 b-l-0">

        <div class="td-body headertd bold-900 col-10 p-10 text-left b-r-0 f-s-18" v-if="!editMode">
          <!--<i class="fa fa-arrow-left tooltip tooltip-top p-l-20 p-r-20 p-b-10 p-t-10 click" aria-hidden="true" data-tooltip="Volver atrás" @click="closeDetail"></i> -->
          {{
            selectedUser.ownerDetail.Gender && selectedUser.ownerDetail.Gender === 'M' ? $t("users.man") : (selectedUser.ownerDetail.Gender && selectedUser.ownerDetail.Gender === 'F' ? $t("users.woman") : '')
          }} {{ selectedUser.ownerDetail.Name + ' ' + selectedUser.ownerDetail.Surname }}
        </div>

        <!--<div class="td-body headertd col-2 col-md-2 col-sm-12 d-block b-r-0 click"  @click="changeEditUserDetail" v-if="!editMode">
          <i class="fa fa-pencil-square-o tooltip tooltip-bottom f-s-22 p-t-10" aria-hidden="true" data-tooltip="Editar usuario"></i>
        </div>-->
        <div class="d-block b-b-0 align-center" v-if="editMode">
          <h4 class="m-auto align-center">{{ $t("users.edit.title") }}</h4>
        </div>

        <!--<div class="text-center p-40" v-if="loadingDetail">
          Cargando datos del usuario... <i class="form-icon loading p-l-20"></i>
        </div> -->

        <!-- USER BODY DETAIL -->
        <div class="td-body-detail boxdetail bg-white p-0 p-t-10 show animated fadeIn">


          <v-row class="text-left m-r-0 m-l-0 p-l-30 p-r-20" v-if="selectedUser && !editMode">


            <v-col class="col-6 col-md-6 col-sm-12">

              <div class="f-s-22 bold-900 p-b-10 font-proxima-light">
                {{ $t("users.detail.title") }}
              </div>
              <div class="f-s-20">
                <b><span v-if="!selectedUser.ownerLogin"></span>{{ $t("users.detail.document") }}</b>
                {{ selectedUser.ownerDetail.Document ? selectedUser.ownerDetail.Document : '-' }}
              </div>
              <div class="f-s-20">
                <b>{{ $t("users.detail.birthDate") }}</b>
                {{ selectedUser.ownerDetail.Birthday ? getDateValue(selectedUser.ownerDetail.Birthday, "Date") : '-' }}
              </div>

              <div class="f-s-20" v-if="selectedUser.ownerLogin">
                <b>{{ $t("users.detail.bankDetails.title") }}</b> <span>{{
                  selectedUser.ownerDetail.StripeId ? $t("users.detail.bankDetails.saved") : $t("users.detail.bankDetails.notSaved")
                }}</span>
              </div>

            </v-col>

            <v-col class="col-6 col-md-6 col-sm-12">

              <div class="f-s-22 bold-900 font-proxima-light">
                {{ $t("users.contact.title") }}
              </div>
              <div class="p-b-10 font-gotham">
                <span class="color-red f-s-16 bold-100">{{ $t("users.contact.consent") }}</span>
              </div>

              <div class="f-s-20" v-if="selectedUser.ownerLogin">
                <b>{{ $t("users.contact.email") }}</b> {{ selectedUser.ownerLogin }}
              </div>
              <div class="f-s-20">
                <b> {{ $t("users.contact.address") }}</b>
                {{ selectedUser.ownerDetail.AddressFirstLine ? selectedUser.ownerDetail.AddressFirstLine : '-' }},
                {{ selectedUser.ownerDetail.AddressSecondLine ? selectedUser.ownerDetail.AddressSecondLine : '-' }},
                {{ selectedUser.ownerDetail.State ? selectedUser.ownerDetail.State : '-' }},
                {{ selectedUser.ownerDetail.City ? selectedUser.ownerDetail.City : '-' }},
                {{ selectedUser.ownerDetail.PostalCode ? selectedUser.ownerDetail.PostalCode : '-' }}
              </div>
              <div class="f-s-20">
                <b> {{ $t("users.contact.phone") }}</b>
                <m-phone-label :value="(selectedUser.ownerDetail.Phone ? selectedUser.ownerDetail.Phone : '-')" />
              </div>
              <div class="f-s-20" v-if="!selectedUser.ownerLogin">
                <b>{{ $t("users.contact.email") }}</b> {{ selectedUser.ownerDetail.InfoEmail ? selectedUser.ownerDetail.InfoEmail : '-' }}
              </div>
            </v-col>

          </v-row>

          <!--<p class="" v-if="selectedUser && selectedUser.containers && Object.keys(selectedUser.containers).length === 0"> Loading... <i class="form-icon loading m-l-15"></i></p>-->

          <containers-list :selectedUser="selectedUser" :shop_profile="shop_profile" :loadingDetail="loadingDetail"
                           v-if="!editMode"></containers-list>


        </div>
        <!-- USER BODY DETAIL -->


      </div>
    </form>
  </div>
</template>

<script>
import helpers from '@/helpers';
import ContainersList from '@/components/users/ContainersList';
import swal from 'sweetalert2';


export default {
  name: 'UserDetail',
  components: {
    ContainersList
  },
  props: ["id", 'selectedUser', 'loadingDetail'],
  data() {
    return {
      bottom: false,
      page: 1,
      noMoreResults: false,
      loading: false,
      userDetail: false,
      editMode: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
    shop_profile() {
      const shop_profile = {
        ...this.$store.getters.getGroup.legacy,
        detail: JSON.stringify(this.$store.getters.getGroup.legacy.detail)
      }
      return shop_profile
    }
  },
  watch: {},
  methods: {
    closeDetail() {
      this.$emit('closeDetail');
    },
    changeUserDetail() {
      this.$emit('changeUserDetail');
    },
    changeEditUserDetail() {
      this.editMode = !this.editMode;
    },
    getDateValue(value, type) {
      return helpers.transform(value, type);
    },
    generateContracts(user, container) {
      //console.log(user, container);

      let detail = this.createDataContract(user, container);

      let cc = JSON.parse(container.containerDetail);

      if (cc.HondaConnect && !cc.HondaPlus) {
        detail['creditor_ref'] = container.ownerId + 'A' + detail['Vin'].toUpperCase();
        //helpers.getContracts('honda-mapit', detail);
        detail['PACK'] = "HONDAMAPIT";
        return JSON.stringify(detail);//.replace("?","").replace("/","");

      } else if (!cc.HondaConnect && cc.HondaPlus) {
        //helpers.getContracts('honda-plus', detail);
        detail['PACK'] = "HONDAPLUS";
        return JSON.stringify(detail);//.replace("?","").replace("/","");

      } else if (cc.HondaConnect && cc.HondaPlus) {
        detail['creditor_ref'] = container.ownerId + 'A' + detail['Vin'].toUpperCase();
        //helpers.getContracts('honda-plus-mapit', detail);
        detail['PACK'] = "HONDAPLUSMAPIT";
        return JSON.stringify(detail);//.replace("?","").replace("/","");
      } else {

        swal.fire({
          title: '<span class="f-s-18">Error!</span>',
          html: `<div>
											<p>El dispositivo no tiene ningun contrato!</p>
						      </div>`,
          type: 'error',
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonText: 'VALE',
          confirmButtonAriaLabel: 'VALE',
          confirmButtonColor: '#c8102e'
        });
      }


    },
    createDataContract(user, container) {

      let detail = {};
      const ownerDetail = user.ownerDetail;
      const containerDetail = JSON.parse(container.containerDetail);

      detail['Name'] = ownerDetail.Name ? ownerDetail.Name : '';
      detail['Surname'] = ownerDetail.Surname ? ownerDetail.Surname : '';
      detail['Gender'] = ownerDetail.Gender ? ownerDetail.Gender : '-';
      detail['DocumentType'] = ownerDetail.DocumentType ? ownerDetail.DocumentType : 0;
      detail['Document'] = ownerDetail.Document ? ownerDetail.Document : '-';
      detail['Phone'] = ownerDetail.Phone ? ownerDetail.Phone : '-';
      detail['AddressFirstLine'] = ownerDetail.AddressFirstLine ? ownerDetail.AddressFirstLine : '';//.replace("/","."):'';
      detail['AddressSecondLine'] = ownerDetail.AddressSecondLine ? ownerDetail.AddressSecondLine : '';//.replace("/","."):'';
      detail['State'] = ownerDetail.State ? ownerDetail.State : '';
      detail['City'] = ownerDetail.City ? ownerDetail.City : '';
      detail['Birthday'] = ownerDetail.Birthday ? ownerDetail.Birthday : '';//.replace("/","-"):'';
      detail['IBAN'] = ownerDetail.IBAN ? ownerDetail.IBAN : '-';
      detail['SwiftBIC'] = ownerDetail.SwiftBIC ? ownerDetail.SwiftBIC : '';
      detail['PostalCode'] = ownerDetail.PostalCode ? ownerDetail.PostalCode : '-';


      detail['Name_Surname'] = detail['Name'] + ' ' + detail['Surname'];
      detail['Address'] = detail['AddressFirstLine'] + ' ' + detail['AddressSecondLine'];
      detail['Mail'] = user.ownerLogin ? user.ownerLogin.trim() : '';

      detail['Vin'] = containerDetail.Vin ? containerDetail.Vin : '';
      detail['Model'] = containerDetail.Model ? containerDetail.Model : '';

      let shop_detail = JSON.parse(this.shop_profile.detail);
      detail['shop_name'] = shop_detail.ShortName;
      detail['shop_phone'] = shop_detail.PhoneService;
      detail['shop_address'] = shop_detail.Address/*.replace("/",".")*/ + ', ' + shop_detail.Town;//.replace("/","-");
      detail['shop_city'] = shop_detail.Town.replace("/", "-");
      detail['current_date'] = ('0' + (new Date()).getDate()).slice(-2) + '-' + ('0' + ((new Date()).getMonth() + 1)).slice(-2) + '-' + (new Date()).getFullYear();
      detail['mapit_code'] = container.device.deviceNumber ? container.device.deviceNumber : '';

      // SEPA
      detail['Country'] = ownerDetail.IBAN ? helpers.getCountryByIBAN(ownerDetail.IBAN) : '';


      return detail;
    },
    removeContainer(c, entry) {
      swal.fire({
        title: 'ERROR AL BORRAR MOTO',
        html: 'En estos momentos no podemos eliminar este dispositivo. Intentelo mas tarde.',
        type: 'error',
        confirmButtonColor: '#c8102e'
      })
    },
  },
};
</script>
<style>
</style>
