import validator from "validator"
import i18n from "@/i18n"
import moment from 'moment'
import {formatNumberToCurrency} from "@mapit/common-utils";
//OFFER MANAGER
export const isRequired = value => value !== undefined && value !== null && value !== '' || i18n.t('error.required')
export const isMinAmount = (value, min) => {
    if (!value || !min) return false

    const epsilon = 0.001;

    const valueParsed = typeof value === 'number' ? value : parseFloat(value.replace('.', '').replace('-', '').replace(',', '.'))
    const minimum = parseFloat(min)

    const isEq = (a, b) => Math.abs(a - b) < epsilon;

    return valueParsed
        && !isNaN(valueParsed)
        && isEq(valueParsed, minimum)
        || valueParsed > minimum
        || i18n.t('error.offer-min-amount', {amount: formatNumberToCurrency(min)})
}

export const isRequiredTrueCheck = value => !!value || i18n.t('error.required')

export const isPercentage = value => !value || (parseFloat(value) >= 0 && parseFloat(value) <= 100) || i18n.t('error.invalid-percentage')
export const isDiscountPercentage = value => !value || (parseFloat(value.replace('.', '').replace('-', '').replace(',', '.')) >= 0 && parseFloat(value.replace('.', '').replace('-', '').replace(',', '.')) <= 100) || i18n.t('error.invalid-percentage')

export const isMinText = (value, min = 3) => !value || value.length >= min || i18n.t('error.min-text', { min })

export const isMaxText = (value, max = 255) => !value || value.length <= max || i18n.t('error.max-text', { max })

//OFFER MANAGER

export const isEmpty = (value, textError = "honda.validation.required") => !!value && !validator.isEmpty(typeof value === 'string' ? value.trim() : value) || i18n.t(textError)

export const isAlphanumeric = (value, textError = "honda.validation.alphaNum") => validator.isAlphanumeric(value) || i18n.t(textError)

export const isNumeric = (value, textError = "honda.validation.num") => !isNaN(value) || i18n.t(textError)

export const isLength = (value, length) => !validator.isLength(value, length) || i18n.t("honda.validation.maxLength")

export const lengthEqualTo = (value, length, field = "honda.validation.fields.default") => value.length == length || i18n.t("honda.validation.equalLengthCustom", {
    field: i18n.t(field),
    length
})

export const isNoRequiredAlphanumeric = value => (validator.isEmpty(value) || validator.isAlphanumeric(value)) || i18n.t("honda.validation.alphaNum")

export const isEmail = (value, textError = "honda.validation.email") => (value && validator.isEmail(value)) || i18n.t(textError)
export const isEmailOptional = (value, textError = "honda.validation.email") => !value || value && validator.isEmail(value) || i18n.t(textError)

export const isPhone = (value, textError = "honda.validation.phone") => !value || value && validator.isMobilePhone(value) || i18n.t(textError)

export const isTimestamp = (value) => !isNaN(parseFloat(value)) && isFinite(value)

export const isPhoneNumberEvent = evt => {
    if (evt.key == '+') return
    return isNumberEvent(evt)
}

export const isNumberEvent = evt => {
    if (!validator.isInt(evt.key))
        evt.preventDefault()
    return true
}

export const isNumberFloatEvent = evt => {
    if (evt.key == '.') return
    if (!validator.isFloat(evt.key))
        evt.preventDefault()
    return true
}

export const isValidBirth = (value, textError = "honda.validation.date", inputFormat = 'DD/MM/YYYY') => {
    const date = moment(value, inputFormat)
    return date.isValid() && date.isBefore(moment()) && date.isAfter('1900-01-01') || i18n.t(textError)
}

export const isValidIban = (value, textError = "honda.validation.iban") => validator.isIban(value) || i18n.t(textError)

export const isValidDocument = (value, textError = "honda.validation.required") => {
    return checkDni(value) || checkNie(value) || checkCif(value) || i18n.t(textError)
}

const checkDni = dni => {
    let numero;
    let letr;
    let letra;
    let expresion_regular_dni;
    expresion_regular_dni = /^\d{8}[a-zA-Z]$/;
    let expresion_regular_dni2 = /^\d{8}-[a-zA-Z]$/;

    if (expresion_regular_dni.test(dni) === true || expresion_regular_dni2.test(dni) === true) {
        if (dni.length === 10) {
            numero = dni.substr(0, dni.length - 2);
        } else {
            numero = dni.substr(0, dni.length - 1);
        }
        letr = dni.substr(dni.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra.substring(numero, numero + 1);
        if (letra !== letr.toUpperCase()) {
            return false;
        } else {
            return true;
        }
    } else {
        return false;
    }
}

const checkNie = nie => {
    // Change the initial letter for the corresponding number and validate as DNI
    let nie_prefix = nie.toUpperCase().charAt(0);

    switch (nie_prefix) {
        case 'X':
            nie_prefix = 0;
            break;
        case 'Y':
            nie_prefix = 1;
            break;
        case 'Z':
            nie_prefix = 2;
            break;
    }
    return checkDni(nie_prefix + nie.substr(1));
}

const checkCif = cif => {

    if (!cif || cif.length !== 9) {
        return false;
    }

    cif = cif.toUpperCase();

    var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'];
    var digits = cif.substr(1, cif.length - 2);
    var letter = cif.substr(0, 1);
    var control = cif.substr(cif.length - 1);
    var sum = 0;
    var i;
    var digit;

    if (!letter.match(/[A-Z]/)) {
        return false;
    }

    for (i = 0; i < digits.length; ++i) {
        digit = parseInt(digits[i]);

        if (isNaN(digit)) {
            return false;
        }

        if (i % 2 === 0) {
            digit *= 2;
            if (digit > 9) {
                digit = parseInt(digit / 10) + (digit % 10);
            }

            sum += digit;
        } else {
            sum += digit;
        }
    }

    sum %= 10;
    if (sum !== 0) {
        digit = 10 - sum;
    } else {
        digit = sum;
    }

    if (letter.match(/[ABEH]/)) {
        return String(digit) === control;
    }
    if (letter.match(/[NPQRSW]/)) {
        return letters[digit] === control;
    }

    return String(digit) === control || letters[digit] === control;
}

export const isMobilePhone = number => {
    try {
        if (!validator.isMobilePhone(number)) {
            return false
        }
        if (number.length < 9) {
            return false
        }
        const countPlus = number.split('+').length - 1
        if (countPlus > 1) {
            return false
        }
        const intPhone = parseInt(number.replace('+', ''))
        return !!intPhone
    } catch (e) {
        return false
    }
}
