























import Vue, {PropType} from 'vue'
import AddressFormGroup from "@/components/repairshop/AddressFormGroup.vue"
import OpeningHoursSchedule from "@/components/repairshop/OpeningHoursSchedule.vue"
import {getCountry, PostalAddress} from "@/utils/administrative-region-helper";
import {Country} from "@mapit/common-utils/dist/types";

export default Vue.extend({
  name: "LocationFormGroup",
  components: {OpeningHoursSchedule, AddressFormGroup},
  props: {
    value: Object,
    translationPrefix: String,
    dealerCountry: String as PropType<Country>
  },
  data() {
    return {
      loc: this.value
    }
  },
  computed: {
    country() {
      return getCountry(this.value.address as PostalAddress, this.dealerCountry)
    }
  }
})
