<template>

	<div class="users-table form-card custom-table high-size"  >


		<form id="search " style="padding: 0px !important;">
			<div class="col-12 columns m-0 p-0">

				<div class="col-12 text-center p-40" v-if="loadingDetail">
					loading user data... <i class="form-icon loading p-l-20"></i>
				</div>

				<!-- CONTAINER DETAIL -->
				<container-detail :container="container" :selectedUser="selectedUser" :shop_profile="shop_profile" :type="2" v-if="container "></container-detail>

				<!-- EDIT CONTAINER -->
				<!-- REVISIONS HISTORIAL -->

			</div>
		</form>
	</div>


</template>
<script>
  import api from '@/api';
  import helpers from '@/helpers';
  import ContainerDetail from '@/components/users/ContainerDetail';


  export default {
    name: 'Container',
    components: {
      ContainerDetail
    },
    props: ["containerId"],
    data() {
      return {
        loadingDetail: false,
        container: null,
	      selectedUser: null,
      }
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      shop_profile(){
        const shop_profile = {
          ...this.$store.getters.getGroup.legacy,
          detail: JSON.stringify(this.$store.getters.getGroup.legacy.detail)
        }
        return shop_profile
      }
    },
    watch: {

    },
    methods: {

      getSepa(StripeId){

        api.getMandateSepa(StripeId, (err, resp) => {
          if (!err) {
            this.selectedUser.urlMandateSepa = resp.url;
          } else {
            console.log("No tiene mantato SEPA disponible");
          }
        });
      },
    },
    created(){
      api.getContainers({containerId: this.containerId}, (err, resp) => {
        if(!err){
					this.container = resp[0];
					if(this.container.deviceCount>0){

            if(this.container.ownerId) {
              api.getSupervisors({userId: this.container.ownerId}, (err1, resp1) => {
                this.selectedUser = resp1[0];
                this.getSepa(this.selectedUser.ownerDetail.StripeId)
                if (!err1) {
                  api.getDevices({
                    groupId: this.user.groupId,
                    containerId: this.containerId
                  }, (err2, resp2) => {
                    if (!err2) {
                      this.container.device = resp2[0];
                    } else {
                      this.selectedUser.showDetail = true;
                      this.selectedUser.containers = [];
                      this.selectedUser.containers.push(this.container);
                    }
                  });

                  api.services({
                    containerId: this.containerId
                  }, (err3, resp3) => {
                    if (!err3) {
                      this.container.services = resp3;
                    }
                  });

                } else {
                  console.log("ERROR", err)
                  this.selectedUser.showDetail = true;
                  this.selectedUser.containers = [];
                  this.selectedUser.containers.push(this.container);
                }
              });
            }else{

              api.getDevices({
                groupId: this.user.groupId,
                containerId: this.containerId
              }, (err2, resp2) => {
                if (!err2) {
                  this.container.device = resp2[0];
                }

              });

              api.services({
                containerId: this.containerId
              }, (err3, resp3) => {
                if (!err3) {
                  this.container.services = resp3;
                }
              });
						}
					}else {
					  if(this.container.ownerId) {
              api.getSupervisors({userId: this.container.ownerId}, (err1, resp1) => {
                if (!err1) {
                  this.selectedUser = resp1[0];
                  this.selectedUser.showDetail = true;
                  this.selectedUser.containers = [];
                  this.selectedUser.containers.push(this.container);
                  this.getSepa(this.selectedUser.ownerDetail.StripeId)

                  api.services({
                    containerId: this.containerId
                  }, (err3, resp3) => {
                    if (!err3) {
                      this.container.services = resp3;
                    }
                  });
                } else {
                  console.log("ERROR", err)
                }
              });
            }else{

              api.services({
                containerId: this.containerId
              }, (err3, resp3) => {
                if (!err3) {
                  this.container.services = resp3;
                }
              });
					  }
          }
        }else{
          console.log(err);
        }
      });


    },
    mounted(){

    }
  };
</script>
<style>
</style>
