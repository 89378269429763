<template>
  <modal :active="active" @close="$emit('close')">
    <template slot="body">
      <message>
        <template slot="icon">
          <i class="fas fa-user-check text-success" style="font-size: 100px"/>
        </template>
        <template slot="text">
          <h3 class="text-success p-t-20">{{ $t('honda.repair.updated-data') }}</h3>
        </template>
      </message>
    </template>
  </modal>
</template>
<script>
  import Message from '@/components/dialog/Message';
  import Modal from '@/components/Modal';

  export default {
    components: {
      Message, Modal
    },
    props: ["active"]

  }
</script>
