<template>
  <div class="Solicitudes col-12">
    <div class="form-card mobilestyle ultra-high-size">
      <div class="form form-horizontal centered" style="text-align: start">


        <div class="revisions form-card ultra-high-size">
          <h3 class="align-left p-t-0 p-b-20 honda-plus-title" style='border-bottom: 1px solid black'>{{ $t("honda.repair.repairshop.dealer-data") }}</h3>

          <v-progress-circular indeterminate
                               :size="70"
                               :width="7"
                               class="full-width" v-if="!dealer"/>

          <div class="animated fadeIn" v-if="dealer">

            <v-expansion-panels v-model="panels" multiple flat>
              <expansion-panel
                uid="ep-avatar"
                title="General"
                :hide-divider="true"
              >
                  <m-form-row :label="$t('honda.repair.repairshop.dealer-name')">
                      <mapit-input
                        uid="dealer-name"
                        v-model="dealer.name"
                        :placeholder="$t('honda.repair.repairshop.dealer-name')"
                      ></mapit-input>
                  </m-form-row>
                  <m-form-row :label="$t('honda.repair.repairshop.dealer-email')">
                      <mapit-input
                        uid="dealer-email"
                        v-model="dealer.email"
                        :placeholder="$t('honda.repair.repairshop.dealer-email')"
                        disabled
                      ></mapit-input>
                  </m-form-row>
                  <m-form-row :label="$t('honda.repair.repairshop.contact-name')">
                      <mapit-input
                        uid="contact-name"
                        v-model="dealer.profile.contactPoint.name"
                        :placeholder="$t('honda.repair.repairshop.contact-name')"
                        :rules="requiredRules"
                      ></mapit-input>
                  </m-form-row>
                  <m-form-row :label="$t('honda.repair.repairshop.contact-email')">
                      <mapit-input
                        uid="contact-email"
                        v-model="dealer.profile.contactPoint.email"
                        :placeholder="$t('honda.repair.repairshop.contact-email')"
                        type="email"
                      ></mapit-input>
                  </m-form-row>
                  <m-form-row :label="$t('honda.repair.repairshop.shop-contact-name')">
                      <mapit-input
                        uid="shop-contact-name"
                        v-model="shop.contactPoint.name"
                        :placeholder="$t('honda.repair.repairshop.shop-contact-name')"
                      ></mapit-input>
                  </m-form-row>
                  <m-form-row :label="$t('honda.repair.repairshop.shop-contact-email')">
                      <mapit-input
                        uid="shop-contact-email"
                        v-model="shop.contactPoint.email"
                        :placeholder="$t('honda.repair.repairshop.shop-contact-email')"
                        type="email"
                      ></mapit-input>
                  </m-form-row>
                  <m-form-row :label="$t('honda.repair.repairshop.honda-mapit-email')">
                    <mapit-input
                      uid="honda-mapit-email"
                      v-model="dealer.profile.hondaMapitAccount"
                      :placeholder="$t('honda.repair.repairshop.honda-mapit-email')"
                      type="email"
                      disabled
                    ></mapit-input>
                  </m-form-row>
              </expansion-panel>

              <expansion-panel
                data-cy="ep-shop-panel"
                :title="$t('honda.repair.repairshop.shop-data')"
              >
                <location-form-group v-model="shop" translation-prefix="honda.repair.repairshop.shop" :dealer-country="dealer.country"/>
              </expansion-panel>

              <expansion-panel
                data-cy="ep-garage-panel"
                :title="$t('honda.repair.repairshop.garage-data')"
              >
                <location-form-group v-model="garage" translation-prefix="honda.repair.repairshop.garage" :dealer-country="dealer.country"/>
              </expansion-panel>

              <expansion-panel
                data-cy="ep-appointment-panel"
                :title="$t('honda.repair.repairshop.appointment-data')"
                v-if="permissions.appointments.canSee"
              >
                <m-form-row :label="$t('honda.repair.repairshop.appointment-days-before-request')">
                  <select id="appointment-days-before-request" class="form-select mt-2 ml-2" v-model='dealer.profile.appointmentConditions.daysBeforeRequest'>
                    <option v-for="option in marginDays" v-bind:value="option.value" v-bind:key="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </m-form-row>
                <m-form-row :label="$t('honda.repair.repairshop.appointment-days-before-request-courtesy-bike')">
                  <select id="appointment-days-before-request-with-courtesy-bike" class="form-select mt-2 ml-2" v-model='dealer.profile.appointmentConditions.daysBeforeRequestWithCourtesyBike'>
                    <option v-for="option in marginDays" v-bind:value="option.value" v-bind:key="option.value">
                      {{ option.text }}
                    </option>
                  </select>
                </m-form-row>
                <m-form-row class="mt-2">
                  <template v-slot:custom-label>
                    <div class="align-self-start">{{ $t('honda.repair.repairshop.appointment-holidays') }}</div>
                  </template>
                  <template v-slot:default>
                    <vc-date-picker
                      class="ml-2"
                      ref="holidaypicker"
                      v-model="holidays"
                      locale="es"
                      mode="multiple"
                      :min-date='new Date()'
                      :disabled-dates='garageOpensOnSaturdays ? { weekdays: [1] } : { weekdays: [1,7] }'
                      :rows="2"
                      color="red"
                      is-inline
                      is-expanded
                    />
                  </template>
                </m-form-row>

              </expansion-panel>

            </v-expansion-panels>

            <v-divider/>

            <v-col class='col-12 text-center p-t-40'>
              <v-btn class='btn col-auto btn-success' :loading="saving" :disabled="!isValid" @click="sendProfile">
                <v-icon class="mr-2">fas fa-angle-right</v-icon>{{ $t('honda.repair.repairshop.save') }}
              </v-btn>
            </v-col>
          </div>

        </div>

      </div>
    </div>

    <repair-shop-profile-confirm v-if="activeModal" :active="activeModal" @close="activeModal=false"></repair-shop-profile-confirm>
  </div>
</template>
<script>

  import coreApi from '@/api/core.api'
  import RepairShopProfileConfirm from './RepairShopProfileConfirm.vue'
  import moment from 'moment'
  import router from '@/router';
  import ExpansionPanel from "@/components/general/ExpansionPanel"
  import MapitInput from "@/components/general/MapitInput"
  import {isRequired} from "@/helpers/validations"
  import LocationFormGroup from "@/components/repairshop/LocationFormGroup"
  import {mapState} from "vuex"

  const MAX_DAYS = 45;

  export default {
    components: {
      LocationFormGroup,
      MapitInput,
      ExpansionPanel, RepairShopProfileConfirm },
    methods: {
      sendProfile() {
        this.$validator.validate().then((result) => {
          if (result) {
            this.saving = true
            coreApi.updateDealer(this.$store.getters.getDealerMid, {
              name: this.dealer.name,
              profile: {
                ...this.dealer.profile,
                garage: this.garage,
                shop: this.shop,
              }
            })
              .then(() => {
                this.activeModal = true
              })
              .catch(error => {
                if(error.response.status === 401){
                  this.$store.dispatch('logout');
                  router.push({name: 'Login'});
                }
              })
            .finally(() => {
              this.saving = false
            })
          }
        })
      }
    },
    computed: {
      ...mapState(['permissions']),
      minValue() {
        return 4
      },
      user() {
        return this.$store.getters.getUser;
      },
      isValid() {
        return this.errors.items.length <= 0;
      },
      garageOpensOnSaturdays() {
        if (!this.garage) return false
        return this.garage.openingHours.length === 3
      },
    },
    created() {
      coreApi.getDealer(this.$store.state.dealerMid)
      .then(res => {
        this.dealer = res.data
        this.garage = {
          ...this.dealer.profile.garage,
          address: this.dealer.profile.garage.address || {}
        }
        this.shop = {
          ...this.dealer.profile.shop,
          address: this.dealer.profile.shop.address || {},
        }
        this.holidays = this.dealer.profile.appointmentConditions.holidays.map(d => moment(d).toDate())

        this.$validator.reset().then(() => {
          this.$validator.validate()
        })
        const refs = this.$refs
        this.$nextTick(() => {
          refs.holidaypicker?.$refs.calendar.move(new Date());
        })
      }).catch(error => {
        if(error.response.status === 401){
          this.$store.dispatch('logout');
          router.push({name: 'Login'});
        }
      });
    },
    data() {
      let marginDays = [];
      for (let i = 1; i <= MAX_DAYS; i++) {
        let value = i;
        let option = {text: value, value: value};
        marginDays.push(option);
      }
      marginDays.push({text:'365 (indefinido)', value:365})
      return {
        dealer: null,
        garage: null,
        shop: null,
        holidays: [],
        marginDays,
        activeModal : false,
        saving: false,
        panels:[0],
        requiredRules: [isRequired],
      }
    },
    watch: {
      holidays: function(val) {
        this.dealer.profile.appointmentConditions.holidays = val.map(d => moment(d).format("YYYY-MM-DD"))
      }
    }
  }
</script>
<style scoped>

    .form-select{
      font-family: 'Roboto', sans-serif;
      color: #888;
    }
</style>

