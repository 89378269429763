import {onMounted, Ref, ref, watch} from "@vue/composition-api";
import {OpenSearchListRequest, OpenSearchPagination, OpenSearchResponse} from '@/types'
import {cloneDeep, debounce} from "lodash";

interface Props<T> {
  dealerId: string,
  dealerCode: string,
  search?: string,
  pagination: Partial<OpenSearchPagination>,
  queryParams?: Record<string, any>,
  fetchFn: (request: OpenSearchListRequest) => Promise<OpenSearchResponse<T>>
}

export const useTableList = <T>(props: Props<T>) => {
  const list = ref<Array<T>>([]) as Ref<Array<T>>
  const text = ref('')
  const queryParams = ref(props.queryParams || {})
  const isLoading = ref(false)
  const total = ref(0)
  const pagination = ref<Partial<OpenSearchPagination>>(props.pagination)

  const debounceFetch = debounce(fetch, 500)

  onMounted(async () => {
    await fetch()
  })

  watch(text, debounceFetch)
  watch(queryParams, debounceFetch, {deep: true})

  async function fetch() {
    try {
      isLoading.value = true

      const request: OpenSearchListRequest = {
        dealer: {
          id: props.dealerId,
          code: props.dealerCode,
        },
        text: cleanSearchInput(text.value),
        pagination: pagination.value,
        params: props.queryParams
      }

      const response = await props.fetchFn(cloneDeep(request))
      const {hits, data, total: {value: totalHits}} = response

      list.value = hits || data || []
      total.value = totalHits
    } catch (e) {
      console.error("Error fetching table list", e)
    } finally {
      isLoading.value = false
    }
  }

  function cleanSearchInput(input?: string) {
    if (!input) return ''
    return input.trim().replace(/^\+/, '')
  }

  return {
    isLoading,
    data: list,
    text,
    total,
    queryParams,
    pagination,
    refetch: fetch,
    debounceRefetch: debounceFetch
  }
}
