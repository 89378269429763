import Vue from 'vue'
import Router from 'vue-router'
import * as AmplifyModules from 'aws-amplify'
// @ts-ignore
import { AmplifyPlugin } from 'aws-amplify-vue'

import Login from '@/components/Login.vue'
import Dashboard from '@/components/Dashboard.vue'
import HomeDashboard from '@/components/HomeDashboard.vue'
import RegistrationOnlyMapit from '@/components/registrations/RegistrationOnlyMapit.vue'
import TransferredVehicles from '@/components/registrations/MapitWizard/TransferredVehicles.vue'
import HondaRegistration from '@/components/registrations/HondaRegistration/HondaRegistration.vue'
import Users from '@/components/users/Users.vue'
import Registrations from '@/components/registrations/HondaRegistration/Registrations.vue'
import Container from '@/components/users/Container.vue'
import User from '@/components/users/User.vue'
import RepairShopProfile from '@/components/repairshop/RepairShopProfile.vue'
import ContractHondaMapit from '@/components/other/ContractHondaMapit.vue'
import RoutesCheck from '@/components/other/RoutesCheck.vue'
import Solicitudes from '@/components/solicitudes/Requests.vue'
import HelpCenter from '@/components/ayuda/HelpCenter.vue'
import Revisions from '@/components/revisions/Revisions.vue'
import Repairs from '@/components/repairs/Repairs.vue'
import DataImport from '@/components/import/DataImport.vue'
import Offers from '@/components/offer-manager/Offers.vue'
import CreateOfferMotorbike from '@/components/offer-manager/CreateOfferMotorbike.vue'
import ResumeOfferMotorbike from '@/components/offer-manager/ResumeOfferMotorbike.vue'
import CreateOffer from '@/components/offer-manager/CreateOffer.vue'
import InitOffer from '@/components/offer-manager/InitOffer.vue'
import StocksManager from '@/components/stocks-manager/StocksManager.vue'
import UsersManager from '@/components/users/manager/UsersManager.vue'
import DealerBikesList from '@/components/dealer-bike/List.vue'
import DealerBikesRegistration from '@/components/dealer-bike/Registration.vue'
import LoansList from '@/components/dealer-bike/LoansList.vue'
import DealerBikesLoan from '@/components/dealer-bike/Loan.vue'
import DealerBikeDetail from '@/components/dealer-bike/Detail.vue'
import ReturnDealerBike from '@/components/dealer-bike/ReturnDealerBike.vue'
import DealerBikes from '@/components/dealer-bike/index.vue'

import Leads from '@/components/leads/Leads.vue'
import LeadDetail from '@/components/leads/LeadDetail.vue'

// @ts-ignore
import store from '../vuex/index'
// @ts-ignore
import coreApi from '@/api/core.api'
import { Permissions } from '@/utils/permission-helper'

import i18n from '@/i18n'
import RegistrationMapitConnect from '@/components/registrations/RegistrationMapitConnect.vue'
import { checkAppVersion } from '@/utils/versions'

Vue.use(AmplifyPlugin, AmplifyModules)
Vue.use(Router)

const scrollBehavior = (to: { hash: any }) => {
  if (to.hash) {
    return {
      selector: to.hash,
    }
  }
}

export const refreshSession = async (currentAuthenticatedUserOpt = undefined) => {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const cognitoUser = await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser(
        currentAuthenticatedUserOpt,
      )

      await Vue.prototype.$Amplify.Auth.currentSession()
      return resolve(cognitoUser)
    } catch (err: any) {
      console.error('Unable to refresh session', err)
      if (
        (err && err.code === 'NotAuthorizedException') ||
        err.code === 'PasswordResetRequiredException'
      ) {
        store.dispatch('logout')
      }
      return reject(err)
    }
  })
}

const getUser = async () => {
  try {
    const cognitoUser: any = await refreshSession()
    store.commit('SET_COGNITO_USER', cognitoUser)

    if (cognitoUser && cognitoUser.signInUserSession) {
      const accessTokenPayload = cognitoUser.signInUserSession.accessToken.payload
      if (accessTokenPayload['cognito:groups']) {
        if (
          store.getters.getUserGroups &&
          accessTokenPayload['cognito:groups'] &&
          JSON.stringify(store.getters.getUserGroups) !==
            JSON.stringify(accessTokenPayload['cognito:groups'])
        ) {
          store.commit('SET_USER_GROUPS', accessTokenPayload['cognito:groups'])
        }
      } else {
        store.commit('SET_USER_GROUPS', [])
      }
      if (store.getters.getUser) {
        return store.getters.getUser
      }
      const userInfo = await Vue.prototype.$Amplify.Auth.currentUserInfo()
      const {
        data: { legacyToken },
      } = await coreApi.getLegacyToken()
      await store.commit('SET_TOKEN', `Bearer ${legacyToken}`)
      const { data: dealer } = await coreApi.getDealer(cognitoUser.attributes['custom:dealerId'])
      const user = {
        groupId: dealer.legacy?.id?.toString(), //TODO: Eliminar dependencia LEGACY
        dealerId: userInfo.attributes['custom:dealerId'],
        login: userInfo.attributes['email'],
      }
      store.commit('SET_USER', user)
      return user
    }
  } catch (error) {
    console.error('Error getting user', error)
    store.commit('SET_USER', null)
    return null
  }
}

const permissions = (): Permissions => {
  return store.getters.getPermissions || undefined
}

const router = new Router({
  scrollBehavior,
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: {
        allowUnauthenticated: true,
        title: 'title.login',
        gtm: i18n.t('title.login', 'es'),
      },
    },
    {
      path: '/RoutesCheck',
      name: 'RoutesCheck',
      component: RoutesCheck,
    },
    {
      path: '/contract/:params',
      name: 'ContractHondaMapit',
      props: true,
      component: ContractHondaMapit,
    },
    {
      path: '/',
      component: Dashboard,
      children: [
        {
          path: '',
          name: 'Dashboard',
          component: HomeDashboard,
          meta: {
            title: 'title.dashboard',
            gtm: i18n.t('title.dashboard', 'es'),
          },
        },
        {
          path: 'registrations/create',
          name: 'HondaRegistration',
          component: HondaRegistration,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.registrations',
            gtm: i18n.t('title.registrations', 'es'),
          },
        },
        {
          path: 'registrations/mapit-only',
          name: 'RegistrationOnlyMapit',
          component: RegistrationOnlyMapit,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.registrations-mapit',
            gtm: i18n.t('title.registrations-mapit', 'es'),
          },
        },
        {
          path: 'registrations/transferred-motorbikes',
          name: 'TransferredVehicles',
          component: TransferredVehicles,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.transferred-vehicles',
            gtm: i18n.t('title.transferred-vehicles', 'es'),
          },
        },
        {
          path: 'connect',
          name: 'RegistrationMapitConnect',
          component: RegistrationMapitConnect,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.registrations-mapit',
            gtm: i18n.t('title.registrations-mapit', 'es'),
          },
        },
        {
          path: 'registrations',
          name: 'Registrations',
          component: Registrations,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.pending-registrations',
            gtm: i18n.t('title.pending-registrations', 'es'),
          },
        },
        {
          path: 'profile',
          name: 'Profile',
          component: RepairShopProfile,
          meta: {
            allowed: () => permissions()?.dealerProfile.canSee,
            title: 'title.edit-account',
            gtm: i18n.t('title.edit-account', 'es'),
          },
        },
        {
          path: 'requests',
          name: 'Solicitudes',
          component: Solicitudes,
          meta: {
            allowed: () => permissions()?.requests.canSee,
            title: 'title.create-incidence',
            gtm: i18n.t('title.create-incidence', 'es'),
          },
        },
        {
          path: 'appointments',
          name: 'Revisions',
          component: Revisions,
          meta: {
            allowed: () => permissions()?.appointments.canSee,
            title: 'title.revisions',
            gtm: i18n.t('title.revisions', 'es'),
          },
        },
        {
          path: 'appointments/:id',
          name: 'RevisionsById',
          component: Revisions,
          meta: {
            allowed: () => permissions()?.appointments.canSee,
            title: 'title.revisions',
            gtm: i18n.t('title.revisions', 'es'),
          },
        },
        {
          path: 'repairs',
          name: 'Repairs',
          component: Repairs,
          meta: {
            allowed: () => permissions()?.appointments.canSee,
            title: 'title.repairs',
            gtm: i18n.t('title.repairs', 'es'),
          },
        },
        {
          path: 'repairs/:id',
          name: 'RepairsById',
          component: Repairs,
          meta: {
            allowed: () => permissions()?.appointments.canSee,
            title: 'title.repairs',
            gtm: i18n.t('title.repairs', 'es'),
          },
        },
        {
          path: 'help',
          name: 'Ayuda',
          component: HelpCenter,
          meta: {
            allowed: () => permissions()?.help.canSee,
            title: 'title.help-center',
            gtm: i18n.t('title.help-center', 'es'),
          },
        },
        {
          path: 'users',
          name: 'Users',
          component: Users,
          meta: {
            allowed: () => permissions()?.customers.canSee,
            title: 'title.users',
            gtm: i18n.t('title.users', 'es'),
          },
        },
        {
          path: 'user/:userId',
          name: 'User',
          props: true,
          component: User,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.users',
            gtm: i18n.t('title.users', 'es'),
          },
        },
        {
          path: 'container/:containerId',
          name: 'Container',
          props: true,
          component: Container,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
          },
        },
        {
          path: 'users/:id',
          name: 'UsersId',
          props: true,
          component: Users,
          meta: {
            allowed: () => permissions()?.registrations.canSee,
            title: 'title.users',
            gtm: i18n.t('title.users', 'es'),
          },
        },
        {
          path: 'import',
          name: 'DataImport',
          component: DataImport,
          meta: {
            allowed: () => permissions()?.imports.canSee,
            title: 'title.import',
            gtm: i18n.t('title.import', 'es'),
          },
        },
        {
          path: 'leads',
          name: 'Leads',
          component: Leads,
          meta: {
            allowed: () => permissions()?.leads.canSee,
            title: 'title.leads',
            gtm: i18n.t('title.leads', 'es'),
          },
        },
        {
          path: 'leads/:id',
          name: 'LeadDetail',
          component: LeadDetail,
          meta: {
            allowed: () => permissions()?.leads.canSee,
            title: 'title.leads-detail',
            gtm: i18n.t('title.leads-detail', 'es'),
          },
        },
        {
          path: 'offer-manager/offers',
          name: 'Offers',
          component: Offers,
          meta: {
            allowed: () => permissions()?.offers.canSee,
            title: 'title.list-offers',
            gtm: i18n.t('title.list-offers', 'es'),
          },
        },
        {
          path: 'stocks-manager',
          name: 'StocksManager',
          component: StocksManager,
          meta: {
            allowed: () => permissions()?.stocks.canSee,
            title: 'title.stocks',
            gtm: i18n.t('title.stocks', 'es'),
          },
        },
        {
          path: 'users-manager',
          name: 'UsersManager',
          component: UsersManager,
          meta: {
            allowed: () => permissions()?.userCredentials.canSee,
            title: 'title.users-manager',
            gtm: i18n.t('title.users-manager', 'es'),
          },
        },
        {
          path: 'offer-manager',
          name: 'CreateOffer',
          component: CreateOffer,
          children: [
            {
              path: 'create',
              name: 'InitOffer',
              component: InitOffer,
              meta: {
                allowed: () => permissions()?.offers.canSee,
                title: 'title.create-offer',
                gtm: i18n.t('title.create-offer', 'es'),
              },
            },
            {
              path: 'create',
              name: 'CreateOfferMotorbike',
              component: CreateOfferMotorbike,
              props: true,
              meta: {
                allowed: () => permissions()?.offers.canSee,
                title: 'title.create-offer',
                gtm: i18n.t('title.create-offer', 'es'),
              },
            },
            {
              path: 'edit/:id',
              name: 'EditOfferMotorbike',
              component: CreateOfferMotorbike,
              meta: {
                allowed: () => permissions()?.offers.canSee,
                title: 'title.edit-offer',
                gtm: i18n.t('title.edit-offer', 'es'),
              },
            },
            {
              path: 'resume/:id',
              name: 'ResumeOfferMotorbike',
              component: ResumeOfferMotorbike,
              props: true,
              meta: {
                allowed: () => permissions()?.offers.canSee,
                title: 'title.resume-offer',
                gtm: i18n.t('title.resume-offer', 'es'),
              },
            },
          ],
        },
        {
          path: 'dealer-bikes',
          component: DealerBikes,
          meta: { allowed: () => permissions()?.dealerBikes.canSee },
          children: [
            {
              path: '',
              name: 'DealerBikesHome',
              component: DealerBikesList,
              meta: {
                allowed: () => permissions()?.dealerBikes.canSee,
                string: 'list',
                title: 'title.dealer-bike-list',
                gtm: i18n.t('title.dealer-bike-list', 'es'),
              },
            },
            {
              path: 'list',
              name: 'DealerBikesList',
              component: DealerBikesList,
              meta: {
                allowed: () => permissions()?.dealerBikes.canSee,
                string: 'list',
                title: 'title.dealer-bike-list',
                gtm: i18n.t('title.dealer-bike-list', 'es'),
              },
            },
            {
              path: 'registration',
              name: 'DealerBikesRegistration',
              component: DealerBikesRegistration,
              meta: {
                allowed: () => permissions()?.dealerBikes.canCreate,
                string: 'registration',
                canClose: true,
                title: 'title.dealer-bike-registration',
                gtm: i18n.t('title.dealer-bike-registration', 'es'),
              },
            },
            {
              path: 'loans',
              name: 'LoansList',
              component: LoansList,
              meta: {
                allowed: () => permissions()?.dealerBikes.canSee,
                string: 'loans',
                title: 'title.dealer-bike-loans',
                gtm: i18n.t('title.dealer-bike-loans', 'es'),
              },
            },
            {
              path: 'loans/:loanId/return',
              name: 'ReturnDealerBike',
              component: ReturnDealerBike,
              meta: {
                allowed: () => permissions()?.dealerBikes.canSee,
                string: 'return',
                canClose: true,
                redirectCloseName: 'LoansList',
                title: 'title.dealer-bike-loans',
                gtm: i18n.t('title.dealer-bike-loans', 'es'),
              },
            },
            {
              path: ':dealerBikeId/loan',
              name: 'DealerBikesLoan',
              component: DealerBikesLoan,
              meta: {
                allowed: () => permissions()?.dealerBikes.canSee,
                string: 'loan',
                canClose: true,
                title: 'title.dealer-bike-loans',
                gtm: i18n.t('title.dealer-bike-loans', 'es'),
              },
            },
            {
              path: ':dealerBikeId/detail',
              name: 'DealerBikeDetail',
              component: DealerBikeDetail,
              meta: {
                allowed: () => permissions()?.dealerBikes.canSee,
                string: 'detail',
                canClose: true,
                redirectCloseName: 'DealerBikesList',
                omitDialog: true,
                title: 'title.dealer-bike-detail',
                gtm: i18n.t('title.dealer-bike-detail', 'es'),
              },
            },
          ],
        },
      ],
    },
  ],
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some((record) => !record.meta.allowUnauthenticated)) {
    const user = await getUser()

    if (!user) {
      return next({ name: 'Login' })
    }

    await store.dispatch('loadDealer')

    // admin is allowed to visit all the pages
    if (store.getters.isAdmin) {
      return next()
    }

    // import vin users
    if (store.getters.isImportUser) {
      if (to.fullPath === '/import' || to.fullPath.includes('/help')) {
        return next() // allow only import and help page
      } else {
        return next({ name: 'DataImport' }) // redirect to import if not in import or help page
      }
    }

    // when you enter the Stock Manager page you are redirected to the Dashboard if you are a ServicePartner
    if (store.getters.isServicePartner && to.fullPath === '/stocks-manager') {
      return next({ name: 'Dashboard' })
    }

    if (to.meta.allowed && to.meta.allowed()) {
      return next()
    }

    // if not afected with any of back rules, redirect to dashboard
    if (to.name === 'Dashboard') {
      return next()
    } else {
      return next({ name: 'Dashboard' })
    }
  }

  return next()
})

router.afterEach(async () => {
  const newVersion = await checkAppVersion()

  if (newVersion) {
    store.dispatch('setAppVersion', newVersion)
  }
})

export default router
