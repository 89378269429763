<template>
  <div class="supervisors">
    <!-- USER DETAIL -->
    <user-detail
      :selectedUser="selectedUser"
      :loadingDetail="loadingDetail"
      @closeDetail="closeDetail"
      v-if="selectedUser"
    />
  </div>
</template>

<script>
import api from '@/api';
import helpers from '@/helpers';
import UserDetail from '@/components/users/UserDetail';

export default {
  name: 'User',
  components: {
    UserDetail
  },
  props: ["userId"],
  data() {
    return {
      loadingDetail: false,
      selectedUser: null,
      prevRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from
      next();
    })
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  watch: {},
  methods: {
    closeDetail() {
      this.selectedUser.showDetail = false;
      this.$router.back();
    },
    loadDetail() {

      if (!this.loadingDetail) {
        this.loadingDetail = true;
        api.getContainers({
          groupId: this.user.groupId,
          userId: this.userId
        }, (err, resp) => {
          if (!err) {
            let promises = [];
            resp.forEach((item) => {
              if (item.deviceCount > 0) {
                promises.push(new Promise((resolve, reject) => {
                  api.getDevices({
                    groupId: this.user.groupId,
                    containerId: item.containerId
                  }, (err, resp) => {
                    if (!err) {
                      item.device = resp[0];
                      api.services({
                        containerId: item.containerId
                      }, (err, resp) => {
                        if (!err) {
                          item.services = resp[0];
                          resolve(true);
                        } else {
                          resolve(false);
                        }
                      });
                    } else {
                      resolve(false);
                    }
                  });
                }));
              }
            });

            Promise.all(promises).then((results) => {
              this.selectedUser.containers = resp;
              this.loadingDetail = false;
              this.selectedUser.showDetail = true;
            });

          } else {
            this.selectedUser.containers = resp;
            this.loadingDetail = false;
            this.selectedUser.showDetail = true;
          }
        })
      }
    }
  },
  created() {
    api.getSupervisors({userId: this.userId}, (err, resp) => {
      if (!err) {
        this.selectedUser = resp[0]
        this.loadDetail()
      } else {
        console.log("ERROR", err)
      }
    });
  },
};
</script>
<style>
</style>
