













































































































































































































































































import printJS from 'print-js'
import { DateTime } from 'luxon'
import { computed, defineComponent, nextTick, onMounted, ref } from '@vue/composition-api'

import HondaOfferBuilder from '@mapit/honda-offers-builder'
import { OfferDoc, Offer, OfferType, Status } from '@/types'

import apiOffers from '@/api/offers.api'
import LabelTooltip from '@/components/text/LabelTooltip.vue'
import MCopy from '@/components/atom/MCopy.vue'

import { downloadFile } from '@/helpers/downloadFile'
import { useGtm } from '@/plugins/gtm'
import { useOffersList } from "@/composables/offers-list"

import { customFormatDateOutput, RegistrationTypes } from '@/utils/constants'
import { isRegistrationAllowed } from '@/utils/registration-helper'
import { toDateFormat, isValidDate } from "@/utils/date-helper";
import { mapGetters } from '@/utils/map-store'

export default defineComponent({
  name: 'OfferTable',
  components: {
    MCopy,
    LabelTooltip
  },
  setup(props, {root, emit}) {
    const gtm = useGtm()
    /* REFS & COMPUTED */
    const {
      getGroup: group,
      getLocale: locale,
      getSortBy: sortBy,
    } = mapGetters(root.$store.getters)

    const {
      data: offerList,
      debounceRefetch,
      isLoading: offerListLoading,
      pagination,
      queryParams,
      refetch,
      text,
      total: totalOffers,
    } = useOffersList<OfferDoc>({
      dealerId: group.value.dealerId,
      dealerCode: group.value.code,
      pagination: { from: 0, size: 10 },
      queryParams: {
        statuses: [Status.Pending, Status.Accepted, Status.COICreated, Status.Quote, Status.Ordered, Status.Invoiced],
      },
    })

    const headers = ref([
      {
        text: root.$t('offer-manager.offers-list.lbl-creation'),
        align: 'center',
        value: 'createDate',
        class: 'text-header px-0',
        width: '10%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-name-email'),
        align: 'left',
        value: 'client',
        class: 'text-header',
        width: '20%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-model'),
        align: 'left',
        value: 'vehicle.model',
        class: 'text-header',
        width: '10%',
        sortable: true,
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-num-offer'),
        align: 'center',
        value: 'offerCode',
        class: 'text-header',
        width: '10%',
        sortable: true
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-attended-by'),
        align: 'left',
        value: 'attendedBy',
        class: 'text-header',
        width: '20%',
        sortable: true
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-status'),
        align: 'left',
        value: 'status',
        class: 'text-header',
        width: '15%',
        sortable: true
      },
      {
        text: root.$t('offer-manager.offers-list.lbl-follow-up'),
        align: 'center',
        value: 'followUp',
        class: 'text-header',
        width: '5%',
        sortable: true
      },
      {
        align: 'center',
        value: 'actions',
        class: 'text-header',
        width: '5%',
        sortable: false,
      }
    ])

    const MAX_HOLD_DURATION = 50
    const keyStopper = ref(false)
    const activeKeys = ref<Record<string, any>>({})
    const loading = ref(false)
    const filterList = ref<Array<{ text: string, value: Status }>>(Object.values(Status).map(item => {
      return {text: root.$t(`offer-manager.create.step-offer-status.lbl-offer-status-${item}`).toString(), value: item}
    }))

    const total = computed(() => {
      return totalOffers.value || 0
    })

    onMounted(() => {
      nextTick(() => {
        window.document.querySelector('.dashboard-body')?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      })
    })

    function getModel(model = ''): string {
      return model.split(' ')[0]
    }

    function onCopyClick() {
      root.$store.dispatch('showAlertSuccess', 'common.copy-success')
    }

    function canChangeToPending(status: Status) {
      return status === Status.Pending
    }

    function canChangeToAccepted(status: Status) {
      return status === Status.Pending
    }

    function canChangeToRefused(status: Status) {
      return status === Status.Pending || status === Status.Accepted
    }

    function canCreateRegistration(status: Status) {
      return isRegistrationAllowed(status)
    }

    function viewOfferSummary(offer: Offer) {
      if (!offer?.id) {
        console.error('This offer does not exists', offer)
        return
      }

      gtm?.trackEvent({
        category: 'Offers',
        action: 'ViewOfferSummary',
        label: 'FromOffersList'
      })

      root.$router.push({name: 'ResumeOfferMotorbike', params: { id: offer.id }})
    }

    async function editOffer(offerId: string) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'EditOffer',
        label: 'FromOffersList'
      })

      root.$store.commit('RESET_OFFER')
      await root.$store.dispatch('setGroupToOffer', group.value)
      await root.$store.dispatch('loadOfferById', offerId)

      root.$router.push({name: 'EditOfferMotorbike', params: {id: offerId}})
    }

    function createRegistration(offerId: string) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'CreateRegistrationFromOffer',
        label: 'FromOffersList'
      })
      root.$router.push({name: 'HondaRegistration', query: {forcedType: RegistrationTypes.OFFER_REGISTRATION, offerId}})
    }

    function getCreatedDate(created: string) {
      return DateTime.fromISO(created).toLocaleString(customFormatDateOutput)
    }

    function getFullName(item: OfferDoc) {
      return `${item.client?.name || ''} ${item.client?.surname || ''}`
    }

    function printPdf(offerId: string) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'PrintOffer',
        label: 'FromOffersList'
      })
      root.$store.dispatch('loadOfferSummaryById', {id: offerId, dealerId: group.value.dealerId})
        .then(({pdf, code}) => {
          printJS({
            printable: pdf,
            type: 'pdf',
            documentTitle: `${group.value.code}-${code?.split('-')[1] ?? offerId}.pdf`
          })
        })
    }

    function downloadPdf(offer: Offer) {
      gtm?.trackEvent({
        category: 'Offers',
        action: 'DownloadOffer',
        label: 'FromOffersList'
      })
      root.$store.dispatch('loadOfferSummaryById', {id: offer.id, dealerId: group.value.dealerId})
        .then(({pdf, code}) => {
          downloadFile(pdf, `${group.value.code}-${code?.split('-')[1] ?? offer.id}.pdf`)
        })
    }

    async function changeStatus(offer: Offer, status: Status) {
      if (!loading.value) {
        gtm?.trackEvent({
          category: 'Offers',
          action: `EditStatusOffer`,
          label: `${offer.id}-${status}`
        })

        try {
          loading.value = true
          const fullOffer = await apiOffers.getOfferById(offer.id || '', group.value.dealerId)
          const newOffer = new HondaOfferBuilder().fromOffer(fullOffer.data)
          newOffer.status = status

          await apiOffers.editOffer(offer.id || '', newOffer.toOffer(), group.value.dealerId)

          emit('refreshList')
          loading.value = false
          nextTick(() => {
            // Visual change if not refreshed
            offer.status = status
            offer.updatedStatusFromEmail = false
          })
        } catch (err) {
          console.log(err)/* Show error popup */
          loading.value = false
        }
      }
    }

    function onPageChange(page: any) {
      pagination.value.from = (page - 1) * (pagination.value.size || 10)
      refetch()
    }

    function onItemsPerPageChange(itemsPerPage: any) {
      pagination.value.size = itemsPerPage
      refetch()
    }

    function onSortByChange(options: any) {
      let [column] = options.sortBy

      if (column === 'client') {
        column = 'user'
      }

      if (column === 'vehicle.model') {
        column = 'model'
      }

      if (column === 'followUp') {
        options.sortDesc = [true]
      }

      // update request params
      const sort = options.sortDesc[0] === false  ? 'asc' : 'desc'
      pagination.value.column = column
      pagination.value.sort = sort

      // call the API
      debounceRefetch()

      // update store
      root.$store.dispatch('setSortBy', { column, sort })
    }

    function onTextKeydown(e: KeyboardEvent) {
      if (!activeKeys.value[e.code] && e.code !== 'Backspace') {
        activeKeys.value[e.code] = Date.now()
      }

      if (keyStopper.value) {
        e.preventDefault()
        return
      }

      if ((Date.now() - activeKeys.value[e.code]) > MAX_HOLD_DURATION) {
        e.preventDefault()
        keyStopper.value = true
      }

      if (e.key === 'Enter') {
        e.preventDefault()
        refetch()
      }
    }

    function onTextKeyup({code}: KeyboardEvent) {
      keyStopper.value = false
      delete activeKeys.value[code]
    }

    function getRowClass(item: OfferDoc) {
      return `click row-${item.id}`
    }

    function isOfferAcceptedOrRefused(offer: OfferDoc) {
      return offer.status === Status.Accepted || offer.status === Status.Refused
    }

    function hasOfferBeenAcceptedOrRefusedInThePast(offer: OfferDoc) {
      return offer.updatedAtStatusAccepted || offer.updatedAtStatusRefused
    }

    function getFollowUpDate(date: string) {
      return isValidDate(date) ? toDateFormat(date, locale.value) : undefined
    }

    function isExpiredDate(date: string) {
      return isValidDate(date) && DateTime.fromISO(date) < DateTime.local().startOf('day') ? 'expired' : ''
    }

    return {
      Status,
      OfferType,
      offerListLoading,
      loading,
      headers,
      text,
      queryParams,
      filterList,
      offerList,
      total,
      pagination,
      activeKeys,
      keyStopper,
      getModel,
      onTextKeyup,
      onTextKeydown,
      onCopyClick,
      canChangeToPending,
      canChangeToAccepted,
      canChangeToRefused,
      canCreateRegistration,
      viewOfferSummary,
      editOffer,
      createRegistration,
      getCreatedDate,
      getFullName,
      printPdf,
      downloadPdf,
      changeStatus,
      onPageChange,
      onItemsPerPageChange,
      onSortByChange,
      getRowClass,
      isOfferAcceptedOrRefused,
      hasOfferBeenAcceptedOrRefusedInThePast,
      getFollowUpDate,
      isExpiredDate,
      sortBy,
    }
  }
})
