import { debounce } from 'lodash'
import { onMounted, Ref, ref, watch } from '@vue/composition-api'

import apiOffers from '@/api/offers.api'
import { getClosestCommentToToday } from '@/utils/comments'
import { OpenSearchListRequest, OpenSearchPagination } from '@/types'

interface Props {
  dealerId: string
  dealerCode: string
  search?: string
  pagination: Partial<OpenSearchPagination>
  queryParams?: Record<string, any>
}

export const useOffersList = <T>(props: Props) => {
  const list = ref<Array<T>>([]) as Ref<Array<T>>
  const text = ref('')
  const queryParams = ref(props.queryParams || {})
  const isLoading = ref(false)
  const total = ref(0)
  const pagination = ref<Partial<OpenSearchPagination>>(props.pagination)

  const debounceFetch = debounce(fetchOffers, 500)

  onMounted(async () => {
    await fetchOffers()
  })

  watch(text, debounceFetch)
  watch(queryParams, debounceFetch, { deep: true })

  async function fetchOffers() {
    try {
      isLoading.value = true

      const request: OpenSearchListRequest = {
        dealer: { id: props.dealerId, code: props.dealerCode },
        pagination: pagination.value,
        params: props.queryParams,
        text: cleanSearchInput(text.value),
      }

      const response = await apiOffers.getOffersListByDealerId(request)
      const { total: { value: totalHits } } = response
      const data = mapResponseDataToOffers(response.hits || response.data)

      list.value = data || []
      total.value = totalHits
    } catch (e) {
      console.error('Error fetching the offer list', e)
    } finally {
      isLoading.value = false
    }
  }

  function mapResponseDataToOffers(data: any) {
    return data.map((item: any) => ({
      ...item,
      followUp: getClosestCommentToToday(item.commentLog),
    }))
  }

  function cleanSearchInput(input?: string) {
    if (!input) return ''
    return input.trim().replace(/^\+/, '')
  }

  return {
    isLoading,
    data: list,
    text,
    total,
    queryParams,
    pagination,
    refetch: fetchOffers,
    debounceRefetch: debounceFetch,
  }
}
