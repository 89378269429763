import { isRequired, isDiscountPercentage, isRequiredTrueCheck, isEmailOptional, isPhone } from "./validations"

export const attendedByRules = [ isRequired ]
export const createDateRules = []
export const offerTypeRules = [ isRequired ]
export const drivingLicenceDateRules = []
export const nameRules = [ isRequired ]
export const surnameRules = []
export const emailRules = [ isEmailOptional ]
export const phoneRules = [ isRequired, isPhone ]
export const provinceRules = [ isRequired ]
export const privacityRules = [ isRequiredTrueCheck ]
export const modelRules = [ isRequired ]
export const modelDescriptionRules = [ isRequired ]
export const priceRateCCAARules = [ isRequired ]
export const discountPercentagePriceRateCCAARules = [ isDiscountPercentage ]
export const discountPriceRateCCAARules = []
export const campaignRules = []
export const statusRules = [ isRequired ]
export const probabilityOfSaleRules = [ isRequired ]
export const additionalDiscountRules = []
export const registrationDiscountRules = []
export const registrationDiscountRulesPercentage = [isDiscountPercentage]
export const servicesDiscountRules = []
export const servicesDiscountRulesPercentage = [isDiscountPercentage]
export const complementDiscountRules = []
export const complementDiscountRulesPercentage = [isDiscountPercentage]
