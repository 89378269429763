<template>
	<div class="columns my-2 p-0">
    <v-overlay :value="mainLoading">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
      <p class="py-2 loading-text">{{$t("users.vehicleList.detail.loading")}}</p>
    </v-overlay>

		<!-- CONTAINER BOX: DETAIL REDUCED (FOR LIST) -->
		<router-link :to="{name:'Container', params: {containerId: container.containerId}}" v-if="type === 1 && container" class="no-decoration">
			<div class="tr-body-detail container-detail m-0 click color-gray" >
			<!-- CONTAINER HEADER -->
			<div class="columns  m-r-0 m-l-0 header-container box-header bg-honda color-white font-proxima-light" >

				<div class="col-sm-12 text-left p-5 p-l-10 f-s-22 bold-300">
					{{ JSON.parse(container.containerDetail).Name ? JSON.parse(container.containerDetail).Name : '' }} <b>{{ JSON.parse(container.containerDetail).Model + " (" + JSON.parse(container.containerDetail).Plate + ")"}}</b>
				</div>


			</div>
			<!-- CONTAINER HEADER -->

			<!-- CONTAINER BODY -->
			<v-row class="m-r-0 m-l-0 ">

				<!-- CONTAINER BODY - DETAIL -->
				<v-col class="col-3 col-md-3 col-sm-12 m-r-0 m-l-0 text-left">

					<div class="center-parent-left-responsive f-s-14 p-b-10">
						<div class="p-t-5 p-l-10 p-r-10" style="line-height: 1">
							<span class="f-s-12 ">{{ $t("users.vehicleList.detail.vin") }}</span> <br/><b>{{ JSON.parse(container.containerDetail).Vin }}</b>
						</div>

						<div class="p-t-5 p-l-10 p-r-10" style="line-height: 1">
							<span class="f-s-12 ">{{ $t("users.vehicleList.detail.salesDate") }}</span> <br/><b>{{ getDateValue(JSON.parse(container.containerDetail).SaleDate,"Date") }}</b>
						</div>
						<div class="col-sm-12 p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="selectedUser && selectedUser.showDetail && container.deviceCount === 0">
							<span class="f-s-12 ">{{ $t("users.vehicleList.detail.mapit") }}</span> <br/>
								<p>
                  {{ $t("users.vehicleList.detail.noMapit") }}
								</p>
            </div>
						<div class="col-sm-12 p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="selectedUser && selectedUser.showDetail && container.deviceCount > 0">
							<span class="f-s-12">{{ $t("users.vehicleList.detail.mapit") }}</span> <br/>
							<b>
								{{ container.device.deviceNumber }} <i class="fa fa-circle tooltip tooltip-bottom" :class="{'status-red': deviceState.comm.state === 0 || (deviceState.battery.state && deviceState.battery.value <= 15), 'status-orange': deviceState.comm.state === 1 && (!deviceState.battery.state || deviceState.battery.state && deviceState.battery.value > 15),'status-green': deviceState.comm.state === 2 && (!deviceState.battery.state || deviceState.battery.state && deviceState.battery.value > 15)}"
                                                       aria-hidden="true"  :data-tooltip="msg[deviceState.stateCode].shortStr "></i>

							</b>
							<span v-if="deviceState.stateCode" :class="msg[deviceState.stateCode].customClass + ' f-s-12'">{{ msg[deviceState.stateCode].shortStr }}</span>

						</div>
					</div>


				</v-col>
				<!-- CONTAINER BODY - DETAIL -->

				<!-- CONTAINER BODY - MAPIT BOX -->
				<v-col class="col-9 col-md-9 col-sm-12">
          <div class="d-flex flex-sm-wrap">
            <service-checkbox
              :title="!!JSON.parse(container.containerDetail).HondaPlusGo ? 'Honda Plus Go' : 'Honda Plus'"
              class="mr-5 tooltip"
              :disabled="!JSON.parse(container.containerDetail).HondaPlus && !JSON.parse(container.containerDetail).HondaPlusGo"
              :checkValue="!!JSON.parse(container.containerDetail).HondaPlus || !!JSON.parse(container.containerDetail).HondaPlusGo"
              :variant="!!JSON.parse(container.containerDetail).HondaPlusGo ? 'honda-plus-go':'honda-plus'"
              :typeStyled="'reduced'"
              :data-tooltip="!!JSON.parse(container.containerDetail).HondaPlusGo ? 'Honda Plus Go' : 'Honda Plus'"
              :isSelectable="true"
            />
            <service-checkbox
              title="Honda Mapit"
              class="mr-5 tooltip"
              :disabled="!JSON.parse(container.containerDetail).HondaConnect"
              :checkValue="JSON.parse(container.containerDetail).HondaConnect"
              variant="honda-mapit"
              :typeStyled="'reduced'"
              data-tooltip="Honda Mapit"
              :isSelectable="true"
            />
            <service-checkbox
              title="Honda Seguro"
              class="mr-5 tooltip"
              :disabled="!JSON.parse(container.containerDetail).Insurance"
              :checkValue="JSON.parse(container.containerDetail).Insurance"
              variant="honda-seguro-normal"
              :typeStyled="'reduced'"
              :data-tooltip="$t('users.vehicleList.detail.insurance')"
              :isSelectable="true"
            />
            <service-checkbox
              title="Honda Seguro Ampliado"
              class="mr-5 tooltip"
              :disabled="!JSON.parse(container.containerDetail).AnyRiskInsurance"
              :checkValue="JSON.parse(container.containerDetail).AnyRiskInsurance"
              variant="honda-seguro-plus"
              :typeStyled="'reduced'"
              :data-tooltip="$t('users.vehicleList.detail.expandedInsurance')"
              :isSelectable="true"
            />
            <service-checkbox
              title="Honda Financial Services"
              class="tooltip"
              :disabled="!JSON.parse(container.containerDetail).Financing"
              :checkValue="JSON.parse(container.containerDetail).Financing"
              variant="honda-finance"
              :typeStyled="'reduced'"
              :data-tooltip="$t('users.vehicleList.detail.financing')"
              :isSelectable="true"
            />
					</div>

				</v-col>
				<!-- CONTAINER BODY - MAPIT BOX -->

			</v-row>
			<!-- CONTAINER BODY -->

		</div>
		</router-link>
		<!-- CONTAINER BOX -->

		<!-- CONTAINER BOX: CONTAINER FULL DETAIL -->
		<div class="tr-body-detail container-detail border-radius-0 m-0" v-else-if="type === 2" >
			<!-- CONTAINER HEADER -->
			<div class="columns  m-r-0 m-l-0 header-container box-header bg-honda color-white font-proxima-light" >

				<div class="py-2 px-2 col-sm-12 d-flex justify-space-between align-center">
					<!--<i class="fa fa-arrow-left tooltip tooltip-top p-l-20 p-r-20 p-b-10 p-t-10 click" aria-hidden="true" data-tooltip="Ir a la ficha de usuario" @click="backToUser" v-if="selectedUser"></i>!-->
					<div class="px-4 font-proxima-bold f-s-22 bold-300">{{ JSON.parse(container.containerDetail).Name ? JSON.parse(container.containerDetail).Name : '' }} <b>{{ JSON.parse(container.containerDetail).Model + " (" + JSON.parse(container.containerDetail).Plate + ")"}}</b></div>
					<m-btn variant="outlined"
                 color="white"
                 hoverTextColor="primary"
                 v-if="selectedUser"
                 style="width: fit-content"
                 @click="removeContainer(container, selectedUser)"
          >
            <span class="px-4 text-uppercase font-proxima-bold f-s-18"><v-icon class="fa-light fa-times pr-2" />{{ $t('users.vehicleList.detail.deregistration') }}</span>
          </m-btn>
				</div>


			</div>
			<!-- CONTAINER HEADER -->

			<!-- CONTAINER BODY -->
			<div class="m-r-0 m-l-0 ">

				<!-- CONTAINER BODY - DETAIL -->
				<div class="col-sm-12 m-r-0 m-l-0 text-left">

					<v-row class="f-s-14 p-b-20 p-l-10 m-0">
						<v-col class="col-3 col-md-3 col-sm-12">
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1">
								<span class="f-s-12 ">{{ $t('users.vehicleList.detail.vin') }}</span> <br/><b class="color-gray">{{ JSON.parse(container.containerDetail).Vin }}</b>
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1">
								<span class="f-s-12 ">{{ $t('users.vehicleList.detail.salesDate') }}</span> <br/><b class="color-gray">{{ getDateValue(JSON.parse(container.containerDetail).SaleDate,"Date") }}</b>
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="container.licenceExpiration">
								<span class="f-s-12 ">{{ $t('users.vehicleList.detail.validity') }}</span> <br/><b class="color-gray">{{ getDateValue(container.licenceExpiration,"Date") }}</b>

							</div>
              <!-- CONTRACTS -->
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="container.registrationId && hasContracts">
								<div class="align-left">
										<button type="button" class="btn btn-accent btn-honda-style f-s-18 m-t-10 m-b-15 text-no-wrap" @click="getContracts(container.registrationId, true)" >
											<i class="fa fa-print p-r-20" aria-hidden="true"></i> {{ $t('users.vehicleList.detail.printContracts') }}
										</button>
								</div>
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="!(container.registrationId && hasContracts) && urlContract">
								<div class="align-left">
									<router-link :to="{name: 'ContractHondaMapit', params: {params: urlContract}}" target="_blank">
										<button type="button" class="btn btn-accent btn-honda-style f-s-18 m-t-10 m-b-15 text-no-wrap" ><i class="fa fa-print f-s-16 p-r-5" aria-hidden="true"></i> {{ $t('users.vehicleList.detail.hondaContract') }}</button>
									</router-link>

								</div>
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="!(container.registrationId && hasContracts) && SEPA">
								<div class="align-left">

										<button type="button" class="btn btn-accent btn-honda-style f-s-18 m-t-10 m-b-15 text-no-wrap" @click="openPage(selectedUser.urlMandateSepa)" >
											<i class="fa fa-print p-r-20" aria-hidden="true"></i> {{ $t('users.vehicleList.detail.sepaContract') }}
										</button>

								</div>
							</div>
              <!-- END CONTRACTS -->
						</v-col>
						<v-col class="col-6 col-md-6 col-sm-12 m-0 b-l-1 b-r-1">


							<div class="p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="container.deviceCount === 0">
								<span class="f-s-12 ">{{ $t('users.vehicleList.detail.mapit') }}</span> <br/>
								<p class="color-gray pt-4 pb-0">
									{{ $t('users.vehicleList.detail.noMapit') }}
                </p>
                <button class="btn btn-accent btn-honda-style f-s-18 m-auto " type="button" @click="$router.push({name: 'RegistrationOnlyMapit', query: { vin: JSON.parse(container.containerDetail).Vin }})"><i class="fa fa-plus f-s-16 p-r-5" aria-hidden="true"></i> {{ $t('users.vehicleList.detail.installMapit') }}</button>

							</div>
							<div class="p-t-5 p-l-10 p-r-10" style="line-height: 1" v-if="container.deviceCount > 0">
								<span class="f-s-12">{{ $t('users.vehicleList.detail.mapit') }}</span> <br/>
								<b class="color-gray">
									<i class="fa fa-circle tooltip tooltip-bottom f-s-16 p-t-5 click" :class="{'status-red': deviceState.comm.state === 0 || (deviceState.battery.state && deviceState.battery.value <= 15), 'status-orange': deviceState.comm.state === 1 && (!deviceState.battery.state || deviceState.battery.state && deviceState.battery.value > 15),'status-green': deviceState.comm.state === 2 && (!deviceState.battery.state || deviceState.battery.state && deviceState.battery.value > 15)}"
                     aria-hidden="true"  :data-tooltip="msg[deviceState.stateCode].shortStr "></i> {{ container.device.deviceNumber }}

								</b><br/>
								<div class="p-0 m-t-5 text-left" v-if="deviceState.stateCode !== 'ACTIVO' && deviceState.stateCode !== 'LOW_BATTERY_ACTIVO'">
									<!--<i class="fa fa-info info-button-small hvr-grow-rotate" aria-hidden="true" @click=""></i>-->
									<span v-if="deviceState.stateCode" class="f-s-12" :class="msg[deviceState.stateCode].customClass">{{ msg[deviceState.stateCode].longStr?msg[deviceState.stateCode].longStr:msg[deviceState.stateCode].shortStr }}</span>

									<br/>
									<p class="text-left m-0 p-t-5 p-b-15">
										<span class="f-s-12 click link" @click="showModalInfo()"> - {{ $t('users.vehicleList.detail.moreInfo') }} -</span>
									</p>
								</div>
								<div class="p-0 m-t-5 text-left f-s-12 p-b-10" v-else>

									<span class="p-b-20">{{ $t('users.vehicleList.detail.stateTitle') }}<span v-if="deviceState.battery.state && deviceState.battery.value <= 15">{{ $t('users.vehicleList.detail.stateLowBattery') }}</span>.
									</span>
									<p class="p-t-10 m-0 ">{{ $t('users.vehicleList.detail.batteryLevel') }}
										<b class="bold-900 color-red" :class="{'color-red': !deviceState.battery.state || deviceState.battery.state && deviceState.battery.value <= 15, 'color-green': deviceState.battery.state && deviceState.battery.value > 15}">
											{{ (deviceState.battery.state? deviceState.battery.value + '%' : $t('users.vehicleList.detail.noAvailable')) }}
										</b>
									</p>

								</div>
								<div class="align-left" v-if="selectedUser">
									<button class="btn btn-primary btn-honda-style f-s-18 m-auto " type="button" @click="quitarMapit"><i class="fa fa-times f-s-16 p-r-5" aria-hidden="true"></i> {{ $t('users.vehicleList.detail.changeMapit') }}</button>
								</div>
							</div>

						</v-col>

						<v-col class="col-3 col-md-3 col-sm-12 p-0" v-if="selectedUser">
							<div class="col-md-12 p-t-15 p-l-10 p-r-10" style="line-height: 1">
								<span class="color-red f-s-12">{{ $t('users.contact.consent') }}</span>
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1">
								<span class="f-s-12 ">{{ $t('users.vehicleList.detail.client') }}</span>
								<br/><b><router-link :to="{name: 'User', params: { userId: selectedUser.ownerId}}" class="color-gray">{{ selectedUser.ownerDetail.Name + ' ' + selectedUser.ownerDetail.Surname}}</router-link></b>
								<!--<br/><span class="f-s-12"><i class="fa fa-envelope m-r-5 color-gray-dark f-s-10"></i> {{ selectedUser.ownerLogin? selectedUser.ownerLogin: (selectedUser.ownerDetail.InfoEmail?selectedUser.ownerDetail.InfoEmail: '-')}}</span>
								<br/><span class="f-s-12"><i class="fa fa-phone m-r-5 color-gray-dark f-s-11"></i> {{ selectedUser.ownerDetail.Phone}}</span>.-->
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1">
								<span class="f-s-12 ">{{ $t("users.contact.contactEmail") }}</span> <br/><b class="color-gray">{{ selectedUser.ownerLogin? selectedUser.ownerLogin: (selectedUser.ownerDetail.InfoEmail?selectedUser.ownerDetail.InfoEmail: '-')}}</b>
							</div>
							<div class="col-md-12 p-t-5 p-l-10 p-r-10" style="line-height: 1">
								<span class="f-s-12 ">{{ $t('users.contact.phone') }}</span> <br/><b class="color-gray"><m-phone-label :value="selectedUser.ownerDetail.Phone" /></b>
							</div>

						</v-col>
						<div class="col-md-12 columns m-0 p-10 b-r-1">
							<div class="col-md-12 columns m-0 p-b-20 shadow-all">
								<div class="p-l-10 m-b-5 b-b-1 f-s-18 font-proxima-reg">
									{{ $t('users.vehicleList.detail.contractedServices')}}
								</div>
								<div class="p-t-15 columns m-0">
                  <div class="d-flex align-inline justify-center">
                    <service-checkbox
                      :title="!!JSON.parse(container.containerDetail).HondaPlusGo ? 'Honda Plus Go' : 'Honda Plus'"
                      class="ml-1 mr-1 tooltip"
                      :disabled="!JSON.parse(container.containerDetail).HondaPlus && !JSON.parse(container.containerDetail).HondaPlusGo"
                      :checkValue="!!JSON.parse(container.containerDetail).HondaPlus || !!JSON.parse(container.containerDetail).HondaPlusGo"
                      :variant="!!JSON.parse(container.containerDetail).HondaPlusGo ? 'honda-plus-go':'honda-plus'"
                      :typeStyled="'reduced'"
                      :data-tooltip="!!JSON.parse(container.containerDetail).HondaPlusGo ? 'Honda Plus Go' : 'Honda Plus'"
                      :isSelectable="true"
                    />
                    <service-checkbox
                      title="Honda Mapit"
                      class="ml-1 mr-1 tooltip"
                      :disabled="!JSON.parse(container.containerDetail).HondaConnect"
                      :checkValue="JSON.parse(container.containerDetail).HondaConnect"
                      variant="honda-mapit"
                      :typeStyled="'reduced'"
                      data-tooltip="Honda Mapit"
                      :isSelectable="true"
                    />
                    <service-checkbox
                      title="Honda Seguro"
                      class="ml-1 mr-1 tooltip"
                      :disabled="!JSON.parse(container.containerDetail).Insurance"
                      :checkValue="JSON.parse(container.containerDetail).Insurance"
                      variant="honda-seguro-normal"
                      :typeStyled="'reduced'"
                      :data-tooltip="$t('users.vehicleList.detail.insurance')"
                      :isSelectable="true"
                    />
                    <service-checkbox
                      title="Honda Seguro Ampliado"
                      class="ml-1 mr-1 tooltip"
                      :disabled="!JSON.parse(container.containerDetail).AnyRiskInsurance"
                      :checkValue="JSON.parse(container.containerDetail).AnyRiskInsurance"
                      variant="honda-seguro-plus"
                      :typeStyled="'reduced'"
                      :data-tooltip="$t('users.vehicleList.detail.expandedInsurance')"
                      :isSelectable="true"
                    />
                    <service-checkbox
                      title="Honda Financial Services"
                      class="ml-1 mr-1 tooltip"
                      :disabled="!JSON.parse(container.containerDetail).Financing"
                      :checkValue="JSON.parse(container.containerDetail).Financing"
                      variant="honda-finance"
                      :typeStyled="'reduced'"
                      :data-tooltip="$t('users.vehicleList.detail.financing')"
                      :isSelectable="true"
                    />
                    </div>
								</div>
							</div>
						</div>
					</v-row>

					<div class="col-md-12 p-l-10 p-r-10 b-t-1 " v-if="container.services && container.services.length > 1">
						<!--<div class="p-l-10 f-s-22 font-proxima-reg">
							Revisiones:
						</div>-->
						<div class="p-t-15 p-l-10 p-r-10 p-b-5 f-s-20 font-proxima-reg">
								{{ $t('users.vehicleList.detail.nextRevision') }} <b>{{ container.services[0].inspection.nextService }}</b>Km {{ $t('users.vehicleList.detail.remaining') }} <b>{{ container.services[0].inspection.prevision }}</b> {{ $t('users.vehicleList.detail.weeks') }}
						</div>
						<div class="columns m-0 f-s-18 p-t-5 p-l-10 p-r-10 bold-900">
							<div class="col-3 b-all-s-1 b-r-0 p-5 text-center font-proxima-reg">
								{{ $t('users.vehicleList.detail.stopAt') }}
							</div>
							<div class="col-3 b-all-s-1 b-r-0 p-5 text-center font-proxima-reg">
								{{ $t('users.vehicleList.detail.date') }}
							</div>
							<div class="col-3 b-all-s-1 b-r-0 p-5 text-center font-proxima-reg">
								{{ $t('users.vehicleList.detail.state') }}
							</div>
							<div class="col-3 b-all-s-1 p-5">
							</div>
						</div>


						<div class="columns m-0 f-s-14 p-l-10 p-r-10" v-for="(revision, index) in container.services" :key="index">
              <div  v-if="index >= 1 && revision.inspection.status >= 2">
							<div class="col-3 b-all-s-1 b-r-0 b-t-0 p-5 text-center">
								{{ revision.inspection.nextService }}
							</div>
							<div class="col-3 b-all-s-1 b-r-0 b-t-0 p-5 text-center">
                <span v-if="revision.inspection.updateDate">
                  {{ getDateValue(revision.inspection.updateDate, 'Date') }}
                </span>
							</div>
							<div class="col-3 b-all-s-1 b-r-0 b-t-0 p-5 text-center">{{ getStatusRevision(revision.inspection.status) }}</div>
							<div class="col-3 b-all-s-1 b-t-0 p-5 text-center">
								<i class="fa fa-eye p-l-10 p-r-10 tooltip tooltip-bottom click" data-tooltip="Ver" @click="showInfoRevision(revision.inspection)"></i>
								<!--<i class="fa fa-pencil p-l-10 p-r-10 tooltip tooltip-bottom click" data-tooltip="Corregir" v-if="index === 1" @click="showEditInfoRevision(revision)"></i>-->

							</div>
              </div>
						</div>

					</div>

					<div class="p-t-5 text-center f-s-22 font-proxima-reg" v-else-if="!container.services || container.services && container.services.length <=1">
						<p class="p-t-30 b-t-1" v-if="container.services && container.services.length ===1">
							{{ $t('users.vehicleList.detail.nextRevision') }} <b>{{ container.services[0].inspection.nextService }}</b>Km {{ $t('users.vehicleList.detail.remaining') }} <b>{{ container.services[0].inspection.prevision }}</b> {{ $t('users.vehicleList.detail.weeks') }}
							<br/>
							<span class="f-s-18">{{ $t('users.vehicleList.detail.noRevision') }}</span>
						</p>

					</div>

				</div>

				<!-- CONTAINER BODY - DETAIL -->

				<!-- CONTAINER BODY - MAPIT BOX -->
				<div class="col-7 col-sm-12 p-b-10 m-t-10 ">


				</div>
				<!-- CONTAINER BODY - MAPIT BOX -->

			</div>
			<!-- CONTAINER BODY -->

		</div>
		<!-- CONTAINER BOX -->


    <mapit-dialog :dialog="dialog" @close="dialog = !dialog">
      <template v-slot:header-icon>
        <v-icon class="text-orange">
          fa-light fa-exclamation-triangle
        </v-icon>
      </template>
      <template v-slot:body>
        <div class="text-center">
          {{ $t('users.vehicleList.detail.error-remove-demobike') }}
        </div>
      </template>
      <template v-slot:footer-center>
        <v-btn class="btn-primary" @click="dialog = !dialog">
          {{ $t('buttons.next') }}
        </v-btn>
      </template>
    </mapit-dialog>

	</div>
</template>
<script>
  import api from '@/api';
  import helpers from '@/helpers';
  import moment from 'moment';
  import swal from 'sweetalert2';
  import coreApi from '@/api/core.api'
  import { downloadSignedUrlFiles } from '@/helpers/downloadFile'
  import ServiceCheckbox from '@/components/registrations/HondaRegistration/services/ServiceCheckbox'
  import MapitDialog from '@/components/dialog/MapitDialog'


  export default {
    name: 'ContainerDetail',
    components: {
      MapitDialog,
      ServiceCheckbox
    },
    props: ["container", "selectedUser", "type", "shop_profile"],
    data() {
      return {
        dialog: false,
        mainLoading: false,
        SEPA: false,
        editMode: '',
        urlContract: '',
        deviceState:{
          device: '',
          stateCode: '',
          battery: {
            state: false,
            value: 0
          },
          odometer: {
            state: false,
            value: 0
          },
          comm:{
            lastCommAll: null,
            lastCommunication: null,
            lastPoint: null,
            lastVibration: null,
            state: 2, // 0=red, 1= orange, 2= green
          }
        },
        msg: {
          '':'',
          'EN_PREPARACION': {
            shortStr: this.$t('users.vehicleList.detail.states.preparation'),
            customClass: "color-red"
          },
          'LOW_BATTERY_MES_48_M301': {
            shortStr: this.$t('users.vehicleList.detail.states.lowBatteryInactive'),
            longStr: this.$t('users.vehicleList.detail.states.lowBatteryInactiveLong'),
            customClass: "color-red"
          },
          'MES_48_M301': {
            shortStr: this.$t('users.vehicleList.detail.states.inactive'),
            longStr: this.$t('users.vehicleList.detail.states.inactiveLong'),
            customClass: "color-orange"
          },
          'LOW_BATTERY_MES_48': {
            shortStr: this.$t('users.vehicleList.detail.states.lowBatteryNoComms'),
            customClass: "color-red"
          },
          'MES_48': {
            shortStr: this.$t('users.vehicleList.detail.states.noComms'),
            customClass: "color-red"
          },
          'LOW_BATTERY_ENTRE_24_48': {
            shortStr: this.$t('users.vehicleList.detail.states.lowBatterySomeComms'),
            customClass: "color-red"
          },
          'ENTRE_24_48': {
            shortStr: this.$t('users.vehicleList.detail.states.someComms'),
            customClass: "color-orange"
          },
          'LOW_BATTERY_ACTIVO': {
            shortStr: this.$t('users.vehicleList.detail.states.lowBattery'),
            customClass: "color-red"
          },
          'ACTIVO': {
            shortStr: this.$t('users.vehicleList.detail.states.active'),
            customClass: "color-green"
          },
        },
        contracts: []
      }
    },
    computed: {
      user() {
        return this.$store.getters.getUser;
      },
      firebase() {
        return this.$store.getters.getFirebase;
      },
      hasContracts() {
        // eslint-disable-next-line no-prototype-builtins
        return this.contracts.filter(contract => contract.hasOwnProperty('s3Key')).length > 0
      }
    },
    watch: {
      'selectedUser.urlMandateSepa': function (val) {
        if(val !== null && val !== '')
          this.SEPA = true;
        else
          this.SEPA = false;
      },
      'container.registrationId': function(val) {
        if(val.registrationId) this.getContracts(val.registrationId, false)
      }
    },
    methods: {
      getContracts(id, download) {
        coreApi
          .getRegistrationContracts(id)
          .then(({data}) => {
            this.contracts = data || []
            if(download) {
              let docs = []
              data.forEach(contract => {
                if(contract.s3Key){
                  docs.push({url: contract.documentUrl, name: `${this.container.containerMid}_${contract.name}.pdf`})
                }
              })
              downloadSignedUrlFiles(docs)
            }
          })
          .catch((e) => {
            console.log('Error download contract files: ', e)
          })
      },
      openPage(url){
        window.open(url, '_blank');
      },
      backToUser(){
        this.$router.push({name: 'User', params: {userId: this.selectedUser.ownerId}})
      },
      reloadPage(){
        window.location.reload(true);
      },
      getStatusRevision(status){
        switch(status){
          case 2:
            return this.$t('users.vehicleList.detail.revision.passed');  //La revisión ya ha sido pasada
          case 3:
            return this.$t('users.vehicleList.detail.revision.ignored');  //La revisión ha sido ignorada
          default:
            return this.$t('users.vehicleList.detail.revision.pending'); //Toca pasar la revisión
        }
      },
      showInfoRevision(revision){

        this.$gtm.trackEvent({
          event: 'TestEvent',
          category: 'test-category',
          action: 'test-action',
          label: 'test-label',
          value: 1
        });
        swal.fire({
          type:'success',
          html:`<div class="align-center f-s-14">
												<h5 class="f-s-18">${this.$t('users.vehicleList.detail.revision.modal.title')}<b class="bold-900">${this.getStatusRevision(revision.status)}</b></h5><br/>
												<p>${this.$t('users.vehicleList.detail.revision.modal.date')}<b class="bold-900">${this.getDateValue(revision.updateDate, 'Date')}</b>,
													${this.$t("users.vehicleList.detail.revision.modal.withKm", { revision: revision.nextService }) }
												</p>
												<p>${this.$t("users.vehicleList.detail.revision.modal.comment")}${revision.comment?revision.comment: '-'}</p>
							      </div>`,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
          confirmButtonText: this.$t("users.vehicleList.detail.preparation.okButton"),
          confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
          confirmButtonColor: '#c8102e'
        });
      },
      showModalInfo(){
        switch (this.deviceState.stateCode){
          case 'EN_PREPARACION':
            swal.fire({
              type:'warning',
              html:`<div class="align-center f-s-14 spectre">
												<h5>${this.$t("users.vehicleList.detail.preparation.title")}</h5><br/>
												<p class="text-left p-l-20">${this.$t("users.vehicleList.detail.preparation.subtitle")}</p>
												<p class="text-left p-l-20">${this.$t("users.vehicleList.detail.preparation.steps")}</p>
												<p class="text-left p-l-20"${this.$t("users.vehicleList.detail.preparation.wait")}</p>
												<div class="columns m-0">
													<div class="col-6 p-10"><img class="img-responsive" src="/images/bateria.jpg"/></div>
													<div class="col-6 p-10"><img class="img-responsive" src="/images/mover.jpg"/></div>
												</div>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 color-red" >
														${ (this.deviceState.battery.state ? this.deviceState.battery.value + '%' : this.$t("users.vehicleList.detail.preparation.notAvailable")) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'LOW_BATTERY_MES_48_M301':
            swal.fire({
              type:'warning',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.lowBattery.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.lowBattery.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 " >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' : this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
												<p>${this.$t("users.vehicleList.detail.lowBattery.state")}</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'MES_48_M301':
            swal.fire({
              type:'warning',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.inactive.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.inactive.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 " >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
												<p>${this.$t("users.vehicleList.detail.lowBattery.state")}</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'LOW_BATTERY_MES_48':
            swal.fire({
              type:'error',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.lowBatteryInactive.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.lowBatteryInactive.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 " >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'MES_48':
            swal.fire({
              type:'error',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.inactive.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.noComms.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 " >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'ENTRE_24_48':
            swal.fire({
              type:'warning',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.inactive.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.someComms.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 " >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'LOW_BATTERY_ENTRE_24_48':
            swal.fire({
              type:'warning',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.lowBatteryInactive.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.lowBatterySomeComms.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 " >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          case 'LOW_BATTERY_ACTIVO':
            swal.fire({
              type:'error',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.activeLowBattery.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.activeLowBattery.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900 color-red" >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
          default: //ACTIVO
            swal.fire({
              type:'success',
              html:`<div class="align-center f-s-14">
												<h5>${this.$t("users.vehicleList.detail.active.title")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.active.subtitle")}</b></p>
												<p>${this.$t("users.vehicleList.detail.preparation.batteryLevel")}
													<b class="bold-900" >
														${ ( this.deviceState.battery.state? this.deviceState.battery.value + '%' :this.$t("users.vehicleList.detail.preparation.notAvailable") ) }
													</b>
												</p>
							      </div>`,
              showCloseButton: true,
              showCancelButton: false,
              focusConfirm: true,
              confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
              confirmButtonColor: '#c8102e'
            });
            break;
        }
      },
      getContractParams(){
        if(this.selectedUser) {
          let print = this.printContracts();
          //console.log(print);
          if (print === false) {
            return ''
          } else {
            return print//encodeURIComponent(print);
          }
        }else{
          return '';
        }
      },
      getDateValue(value, type){
        return helpers.transform(value,type);
      },
      printContracts(){
        //console.log(user, container);

        let detail = this.createDataContract();
        //console.log(detail);
        let cc = JSON.parse(this.container.containerDetail);

        //console.log(cc, detail)
        if (cc.HondaPlus){
          detail['PACK'] = "HONDAPLUS";
          detail['creditor_ref'] = this.selectedUser.ownerId + 'A' + detail['Vin'].toUpperCase();
          return JSON.stringify(detail);

        }else{
          return false;
        }


      },
      createDataContract(){

        let detail = {};
        const ownerDetail = this.selectedUser.ownerDetail;
        const containerDetail = JSON.parse(this.container.containerDetail);

        detail['Name'] = ownerDetail.Name?ownerDetail.Name:'';
        detail['Surname'] = ownerDetail.Surname?ownerDetail.Surname:'';
        detail['Gender'] = ownerDetail.Gender?ownerDetail.Gender:'-';
        detail['DocumentType'] = ownerDetail.DocumentType?ownerDetail.DocumentType:'NIF';
        detail['Document'] = ownerDetail.Document?ownerDetail.Document:'-';
        detail['Phone'] = ownerDetail.Phone?ownerDetail.Phone:'-';
        detail['AddressFirstLine'] = ownerDetail.AddressFirstLine?ownerDetail.AddressFirstLine:'';
        detail['AddressSecondLine'] = ownerDetail.AddressSecondLine?ownerDetail.AddressSecondLine:'';
        detail['State'] = ownerDetail.State?ownerDetail.State:'';
        detail['City'] = ownerDetail.City?ownerDetail.City:'';
        detail['Birthday'] = ownerDetail.Birthday?ownerDetail.Birthday:'';
        detail['IBAN'] = ownerDetail.IBAN?ownerDetail.IBAN:'-';
        detail['SwiftBIC'] = ownerDetail.SwiftBIC?ownerDetail.SwiftBIC:'';
        detail['PostalCode'] = ownerDetail.PostalCode?ownerDetail.PostalCode:'-';


        detail['Name_Surname'] = detail['Name'] + ' ' + detail['Surname'];
        detail['Address'] = detail['AddressFirstLine'] + ' ' + detail['AddressSecondLine'];
        detail['Mail'] = this.selectedUser.ownerLogin? this.selectedUser.ownerLogin.trim():(ownerDetail.InfoEmail?ownerDetail.InfoEmail:'');

        detail['Vin'] = containerDetail.Vin?containerDetail.Vin:'';
        detail['Model'] = containerDetail.Model?containerDetail.Model:'';
        detail['Accessories'] = containerDetail.Accessories?containerDetail.Accessories:'';

        let shop_detail = JSON.parse(this.shop_profile.detail);
        detail['shop_name'] = shop_detail.ShortName;
        detail['shop_phone'] = shop_detail.PhoneService;
        detail['shop_address'] = shop_detail.Address + ', ' + shop_detail.Town;
        detail['shop_city'] = shop_detail.Town;
        detail['current_date'] = ('0' + (new Date()).getDate()).slice(-2) + '-' + ('0' + ((new Date()).getMonth() + 1)).slice(-2) + '-' + (new Date()).getFullYear();
        if(this.container.deviceCount > 0) detail['mapit_code'] = this.container.device.deviceNumber;
        else if(containerDetail.pending_mapit_installation) detail['mapit_code'] = this.$t("users.vehicleList.detail.preparation.pendingInstall");
        else detail['mapit_code'] = '';

        // SEPA
        //detail['Country'] = ownerDetail.IBAN?helpers.getCountryByIBAN(ownerDetail.IBAN):'';


        return detail;
      },
      quitarMapit(){

        //eslint-disable-next-line @typescript-eslint/no-this-alias
        let ctx = this;

        swal.fire({
          html:`<div class="align-center f-s-14 spectre">
												<h5 class="f-s-18">${this.$t("users.vehicleList.detail.changeMapitModal.title")}</h5><br/>
												<p class="align-left m-0 ">
													${this.$t("users.vehicleList.detail.changeMapitModal.explanation")}
												</p>
												<p class="align-left m-0 p-t-15 p-b-5">
													${this.$t("users.vehicleList.detail.changeMapitModal.reason")}
												</p>
												<form class="align-left">
													<div class="p-10 shadow-all p-t-15 p-b-15 click hover-bg-gray" id="cambioGarantiaDiv" onclick="document.getElementById('input2').checked = true;document.getElementById('p1').style.display = 'none';document.getElementById('p2').style.display = 'block';document.getElementById('p3').style.display = 'none';">
														<input type="radio" id="input2" name="reason" value="Cambio con garantia." class=""/>
														<span class="bold-600">${this.$t("users.vehicleList.detail.changeMapitModal.changeGuarantee")}</span><br/>
														<p id="p2" class="f-s-12 p-t-5 p-b-5 p-l-10 p-r-10 m-b-0 m-t-10 " style="display: none;">
															${this.$t("users.vehicleList.detail.changeMapitModal.changeGuaranteeExplanation")}
														</p>
													</div>
													<div class="p-5">
													</div>
													<div class="p-10 shadow-all p-t-15 p-b-15 click hover-bg-gray " id="cambioSinGarantiaDiv" onclick="document.getElementById('input3').checked = true;document.getElementById('p1').style.display = 'none';document.getElementById('p2').style.display = 'none';document.getElementById('p3').style.display = 'block';">
														<input type="radio" id="input3" name="reason" value="Cambio sin garantia (accidente...)." class=""/>
														<span class="bold-600">${this.$t("users.vehicleList.detail.changeMapitModal.changeNoGuarantee")}</span><br/>
														<p id="p3" class="f-s-12 p-t-5 p-b-5 p-l-10 p-r-10 m-b-0 m-t-10 " style="display: none;">
															${this.$t("users.vehicleList.detail.changeMapitModal.changeNoGuaranteeExplanation")}
														</p>
													</div>
													<p class="align-left f-s-12 m-t-10">
														${ this.$t("users.vehicleList.detail.changeMapitModal.link", {link: "/#/requests"}) }
													</p>
                          <br/>
													<span>${this.$t("users.vehicleList.detail.changeMapitModal.comments")}</span>
													<textarea id="comment" class="form-input full-width border-radius-0" style="line-height: 1.5;min-height: 100px;border-radius: 0 !important;"></textarea>
												</form>
							      </div>`,
          focusConfirm: false,
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonColor: '#157F3F',
          cancelButtonColor: '#c8102e',
          confirmButtonText: `<i class="fa fa-times color-white f-s-16 p-r-5" aria-hidden="true"></i> ${this.$t("users.vehicleList.detail.changeMapitModal.changeDevice")}`,
          cancelButtonText: this.$t("users.vehicleList.detail.cancel"),
          confirmButtonClass: 'btn btn-success extend-paddings',
          cancelButtonClass: 'btn btn-danger extend-paddings',
          preConfirm: () => {
              let comment = document.getElementById('comment').value;
              let radios = document.getElementsByName('reason');
              let reasonValue = "";
              let reasonCode = '2';


              for (var i = 0, length = radios.length; i < length; i++) {
                if (radios[i].checked) {
                    reasonValue = radios[i].value;
                }
              }
              if (ctx.allowRemove === 'ALLOWED') {
                if (reasonValue === 'Cambio con garantia.') {
                    reasonCode = '0';

                } else if (reasonValue === 'Cambio sin garantia (accidente...).') {
                    reasonCode = '2'
                }
              } else {
                console.log('Not allowed to remove the device without permission')
                return false
              }
              if(reasonValue) {
                  reasonValue += " " + comment;
                  return {
                      reason: reasonValue,
                      reasonCode: reasonCode
                  }
              }else{
                  return false;
              }


          }
        }).then((result) => {
          if (result.value) {
            api.detachDeviceToContainer(ctx.container.device.deviceId, ctx.container.containerId, result.value.reasonCode, (err, resp) => {
              if(!err){
                api.changeStateDevice(ctx.container.device.deviceId, {
                  state: "REMOVED",
                  ownerId: ctx.container.device.ownerId,
                  containerId: ctx.container.containerId,
                  groupId: ctx.container.device.groupId
                }, (err, resp) => {
                  if(!err){
                    console.log("CORRECT change state device");
                  }else{
                    console.error("FAIL change state device");
                  }
                });


                ctx.$gtm.trackEvent({
                  event: 'UninstallMapit',
                  category: 'Moto',
                  action: 'UninstallDevice',
                  label: result.value.reasonCode === '0'? 'With guarantee': (result.value.reasonCode === '1' ? 'Voluntary removal': 'Without guarantee')/*,
                  value: parseInt(result.value.reasonCode) */
                });

                swal.fire({
                  type:'success',
                  html:`<div class="align-center f-s-14">
												<h5 class="f-s-18">${this.$t("users.vehicleList.detail.detachMapit.title")}</h5>
												<p class="${result.value.reasonCode === '0'?'':'d-hidden'}">${this.$t("users.vehicleList.detail.detachMapit.defect")}</p>
							          <p class="${result.value.reasonCode === '1'?'':'d-hidden'}">${this.$t("users.vehicleList.detail.detachMapit.cancel")}</p>
												<p class="${result.value.reasonCode === '2'?'':'d-hidden'}">${this.$t("users.vehicleList.detail.detachMapit.change")}</p>
										</div>`,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: true,
                  confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
                  confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
                  confirmButtonColor: '#c8102e'
                }).then((result) => {
                  ctx.reloadPage();
                });

              }else{
                swal.fire({
                  type:'error',
                  html:`<div class="align-center f-s-14">
												<h5 class="f-s-18">${this.$t("users.vehicleList.detail.errorMapit.title")}</h5>
												<p>${this.$t("users.vehicleList.detail.errorMapit.subtitle")}</p>
							      </div>`,
                  showCloseButton: true,
                  showCancelButton: false,
                  focusConfirm: true,
                  confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
                  confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
                  confirmButtonColor: '#c8102e'
                }).then((result) => {
                  ctx.reloadPage();
                });
              }
            });



          }

        });

        ctx.allowRemove = 'ALLOWED';
        },
      removeContainer(c, entry){
        if(JSON.parse(c.containerDetail).DemoBike){
          this.dialog = true
          return;
        }
        //eslint-disable-next-line @typescript-eslint/no-this-alias
        let ctx = this;

          swal.fire({
            html:`<span class="align-center f-s-14 spectre">
												<h5 class="f-s-18">${this.$t("users.vehicleList.detail.removeVehicle.title")}</h5><br/>
												<p class="align-left m-0 p-t-15 p-b-5">
													${this.$t("users.vehicleList.detail.removeVehicle.reason")}
												</p>
												<form class="align-left">
													<div class="p-10 shadow-all p-t-15 p-b-15 click hover-bg-gray" onclick="document.getElementById('input1').checked = true;document.getElementById('p1').style.display = 'block';document.getElementById('p2').style.display = 'none';">
														<input type="radio" id="input1" name="reason" value="Venta de la moto." class="" checked/>
														<span class="bold-600">${this.$t("users.vehicleList.detail.removeVehicle.sale")}</span><br/>
														<p id="p1" class="f-s-12 p-t-5 p-b-5 p-l-10 p-r-10 m-b-0 m-t-10">
															${this.$t("users.vehicleList.detail.removeVehicle.saleExplanation")}
														</p>
													</div>
													<div class="p-5">
													</div>
													<div class="p-10 shadow-all p-t-15 p-b-15 click hover-bg-gray" onclick="document.getElementById('input2').checked = true;document.getElementById('p1').style.display = 'none';document.getElementById('p2').style.display = 'block';">
														<input type="radio" id="input2" name="reason" value="Robo de la moto." class=""/>
														<span class="bold-600">${this.$t("users.vehicleList.detail.removeVehicle.theft")}</span><br/>
														<p id="p2" class="f-s-12 p-t-5 p-b-5 p-l-10 p-r-10 m-b-0 m-t-10" style="display: none;">
															${this.$t("users.vehicleList.detail.removeVehicle.theftExplanation")}
														</p>
													</div>
													<p class="align-left f-s-12 m-t-10">
														${ this.$t("users.vehicleList.detail.removeVehicle.link", {link: "/#/requests"}) }
													</p>
													<br/>
													<span>${this.$t("users.vehicleList.detail.removeVehicle.comments")}</span>
													<textarea id="comment" class="form-input full-width" style="line-height: 1.5;min-height: 100px;border-radius: 0 !important;"></textarea>
												</form>
							      </div>`,
            focusConfirm: false,
            showCloseButton: true,
            showCancelButton: true,
            confirmButtonColor: '#157F3F',
            cancelButtonColor: '#c8102e',
            confirmButtonText: `<i class="fa fa-times color-white f-s-16 p-r-5" aria-hidden="true"></i> ${this.$t("users.vehicleList.detail.removeVehicle.cancel")}`,
            cancelButtonText: this.$t("users.vehicleList.detail.cancel"),
            confirmButtonClass: 'btn btn-success',
            cancelButtonClass: 'btn btn-danger',
            preConfirm: () => {
              let comment = document.getElementById('comment').value;
              let radios = document.getElementsByName('reason');
              let reasonValue = "";
              let reasonCode = 'OTHER';

              for (var i = 0, length = radios.length; i < length; i++) {
                if (radios[i].checked) {
                  reasonValue = radios[i].value;
                }
              }

              if(reasonValue === 'Robo de la moto.'){
                reasonCode = 'THEFT';

              }else if(reasonValue === 'Venta de la moto.'){
                reasonCode = 'SOLD';

              }

              if(reasonValue) {
                reasonValue += " " + comment;
                return {
                  reason: reasonValue,
                  reasonCode: reasonCode
                }
              }else{
                return false;
              }

            }
          }).then((result) => {

            if (result.value) {
              ctx.mainLoading = true
              coreApi
                .deleteVehicle(ctx.container.containerMid, {
                  reason: result.value.reasonCode
                })
                .then(res => {
                  ctx.$gtm.trackEvent({
                    event: 'CancellationMotorcycle',
                    category: 'Moto',
                    action: 'Cancellation',
                    label: result.value.reasonCode
                  });

                  ctx.mainLoading = false
                  swal.fire({
                    type:'success',
                    html:`<div class="align-center f-s-14">
												<h5 class="f-s-18">${this.$t("users.vehicleList.detail.removeVehicle.cancelCorrect")}</h5>
							      </div>`,
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
                    confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
                    confirmButtonColor: '#c8102e'
                  }).then(() => {
                    ctx.backToUser()
                  });
                })
                .catch(err => {
                  ctx.mainLoading = false
                  console.error("FAIL DETACH CONTAINER TO USER");
                  swal.fire({
                    type:'error',
                    html:`<div class="align-center f-s-14">
												<h5 class="f-s-18">${this.$t("users.vehicleList.detail.removeVehicle.cancelError")}</h5><br/>
												<p>${this.$t("users.vehicleList.detail.errorMapit.subtitle")}</p>
							      </div>`,
                    showCloseButton: true,
                    showCancelButton: false,
                    focusConfirm: true,
                    confirmButtonText:this.$t("users.vehicleList.detail.preparation.okButton"),
                    confirmButtonAriaLabel: this.$t("users.vehicleList.detail.preparation.okButton"),
                    confirmButtonColor: '#c8102e'
                  }).then((result) => {
                    ctx.backToUser()
                  });
                })
            }

          });

      },
    },
    created(){
      //eslint-disable-next-line @typescript-eslint/no-this-alias
      let ctx = this;

      if(this.container.registrationId) this.getContracts(this.container.registrationId, false)


			if(this.container.deviceCount > 0){
        api.getKeys({ids: [parseInt(this.container.containerId)]}, (err, resp)=>{

        if(!err){
          // eslint-disable-next-line vue/no-mutating-props
          this.container['fbKey'] = resp[0][1];

          this.firebase.database().ref("events/" + this.container.fbKey).on('value', function (snapshot) {
            if (snapshot.val()) {
              let fbDevice = snapshot.val();
              //console.log(ctx.container.containerId, fbDevice)
              let now = new Date();
              let lastComm24 = new Date();
              let lastComm48 = new Date();

              lastComm24.setDate(now.getDate() - 1);
              lastComm24 = moment(lastComm24);
              lastComm48.setDate(now.getDate() - 2);
              lastComm48 = moment(lastComm48);

              // eslint-disable-next-line no-prototype-builtins
              if (fbDevice.hasOwnProperty('battery')) {
                ctx.deviceState.battery.state = true;
                let battery = fbDevice.battery[Object.keys(fbDevice.battery)[0]];
                ctx.deviceState.battery.value = battery.value[Object.keys(battery.value)[0]].toFixed(2);
                //console.log("BATTERY: " + battery + "%")
              }
              // eslint-disable-next-line no-prototype-builtins
              if (fbDevice.hasOwnProperty('odometer')) {
                //CONTRACT KM
                ctx.deviceState.odometer.state = true;
                let odometer = fbDevice.odometer[Object.keys(fbDevice.odometer)[0]];
                ctx.deviceState.odometer.value = odometer.km[Object.keys(odometer.km)[0]].toFixed(3);
              }

              // eslint-disable-next-line no-prototype-builtins
              if(!fbDevice.hasOwnProperty('lastCommunication') && !fbDevice.hasOwnProperty('point') && !fbDevice.hasOwnProperty('vibration')){
                ctx.deviceState.comm.state = 0;
                ctx.deviceState.stateCode = 'EN_PREPARACION';

              }else {

                // eslint-disable-next-line no-prototype-builtins
                if (fbDevice.hasOwnProperty('lastCommunication')) {
                  ctx.deviceState.comm.lastCommunication = moment(new Date(fbDevice.lastCommunication.time * 1000));
                  if (ctx.deviceState.comm.lastCommAll) {
                    if (ctx.deviceState.comm.lastCommAll.isBefore(ctx.deviceState.comm.lastCommunication)) {
                      //console.log(ctx.deviceState.comm.lastCommAll.format("DD/MM/YYYY HH:mm:ss") + " > " + ctx.deviceState.comm.lastCommunication.format("DD/MM/YYYY HH:mm:ss"));
                      ctx.deviceState.comm.lastCommAll = ctx.deviceState.comm.lastCommunication;
                    }
                  } else {
                    ctx.deviceState.comm.lastCommAll = ctx.deviceState.comm.lastCommunication;
                  }
                }

                // eslint-disable-next-line no-prototype-builtins
                if (fbDevice.hasOwnProperty('point')) {
                  ctx.deviceState.comm.lastPoint = moment(new Date(fbDevice.point.timestamp * 1000));
                  if (ctx.deviceState.comm.lastCommAll) {
                    if (ctx.deviceState.comm.lastCommAll.isBefore(ctx.deviceState.comm.lastPoint)) {
                      //console.log(ctx.deviceState.comm.lastCommAll.format("DD/MM/YYYY HH:mm:ss") + " > " + ctx.deviceState.comm.lastPoint.format("DD/MM/YYYY HH:mm:ss"));
                      ctx.deviceState.comm.lastCommAll = ctx.deviceState.comm.lastPoint;
                    }
                  } else {
                    ctx.deviceState.comm.lastCommAll = ctx.deviceState.comm.lastPoint;
                  }
                }

                // eslint-disable-next-line no-prototype-builtins
                if (fbDevice.hasOwnProperty('vibration')) {
                  ctx.deviceState.comm.lastVibration = moment(new Date(fbDevice.vibration.time * 1000));
                  if (ctx.deviceState.comm.lastCommAll) {
                    if (ctx.deviceState.comm.lastCommAll.isBefore(ctx.deviceState.comm.lastVibration)) {
                      //console.log(ctx.deviceState.comm.lastCommAll.format("DD/MM/YYYY HH:mm:ss") + " > " + ctx.deviceState.comm.lastVibration.format("DD/MM/YYYY HH:mm:ss"));
                      ctx.deviceState.comm.lastCommAll = ctx.deviceState.comm.lastVibration;
                    }
                  } else {
                    ctx.deviceState.comm.lastCommAll = ctx.deviceState.comm.lastVibration;
                  }
                }


                // eslint-disable-next-line no-prototype-builtins
                if (fbDevice.hasOwnProperty('device')) {
                  ctx.deviceState.device = fbDevice.device + "";
                }

                //console.log("LAST COMM FB: " + ctx.deviceState.comm.lastCommAll.format("DD/MM/YYYY HH:mm:ss"), "NOW -24h: " + lastComm24.format("DD/MM/YYYY HH:mm:ss") + ", NOW -48h: " + lastComm48.format("DD/MM/YYYY HH:mm:ss"));

                if (ctx.deviceState.comm.lastCommAll.isBefore(lastComm48)) { //Mes de 48h
                  if (ctx.deviceState.device.substring(0, 4) === '3520') {
                    ctx.deviceState.comm.state = 1;
                    ctx.deviceState.stateCode = 'MES_48_M301';
                  } else {
                    ctx.deviceState.comm.state = 0;
                    ctx.deviceState.stateCode = 'MES_48';
                  }

                } else if (ctx.deviceState.comm.lastCommAll.isBefore(lastComm24)) { //Entre 24h i 48h
                  ctx.deviceState.comm.state = 1;
                  ctx.deviceState.stateCode = 'ENTRE_24_48';
                } else {//Menys de 24h
                  ctx.deviceState.comm.state = 2;
                  ctx.deviceState.stateCode = 'ACTIVO';
                }

                if(ctx.deviceState.battery.state && ctx.deviceState.battery.value <= 15){
                  ctx.deviceState.stateCode = "LOW_BATTERY_" + ctx.deviceState.stateCode;
                }

              }
            }else{
              ctx.deviceState.comm.state = 0;
              ctx.deviceState.stateCode = 'EN_PREPARACION';
            }

          });
        }else{
          console.log("ERROR ON GET KEYS",err)
        }

      })
      }
    },
    mounted(){
      //eslint-disable-next-line @typescript-eslint/no-this-alias
      let ctx = this;
      setTimeout(() => {

        ctx.urlContract = ctx.getContractParams();

      }, 1000)
      setTimeout(() => {

        if(ctx.selectedUser && ctx.selectedUser.urlMandateSepa) {
          ctx.SEPA = true;
        }else {
          ctx.SEPA = false;
        }
      }, 2000)
    }
  };
</script>
<style scoped>

.loading-text{
  font-size: 18px;
  font-family: 'ProximaNovaRegular', sans-serif;
}
.extend-paddings{
  padding: 10px 30px 25px 30px !important;
}
</style>
