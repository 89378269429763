























































































import FooterActions from '../FooterActions.vue'
//@ts-ignore
import { accountRegistrationModel, parseRegisteredAccount } from '@/vuex/models/index.js'
//@ts-ignore
import { isEmail } from '@/helpers/validations.js'
//@ts-ignore
import coreApi from '@/api/core.api.js'
import MapitDialog from '@/components/dialog/MapitDialog.vue'
import { RegistrationTypes } from '@/utils/constants'
import {ref, watch, computed, defineComponent, onMounted} from "@vue/composition-api"
import { Country } from '@mapit/common-utils/dist/types'
import {mapGetters} from "@/utils/map-store";

export default defineComponent({
  name: 'SearchAccountHondaRegistration',
  components: { FooterActions, MapitDialog },
  props: {
    step: Number
  },
  setup(props, {refs, root, emit}) {

    const {
      getAccount,
    } = mapGetters(root.$store.getters)

    const dialog = ref(false)
    const account = ref(getAccount.value)
    const loadingUser = ref(false)
    const buttonKey = ref(1)
    const errorDemoBike = ref(false)
    const warningEmail = ref(false)
    const dialogWrongCountry = ref(false)
    const searchEmail = ref('')

    const isCreatedFromOffer = computed(() => {
      return root.$store.getters.getForcedType === RegistrationTypes.OFFER_REGISTRATION
    })
    const services = computed(() => {
      return root.$store.getters.getServices
    })
    const isHondaMapit = computed(() => {
      return root.$store.getters.getHasHondaMapitService
    })
    const enabledSearchButton = computed(() => {
      return account.value.withoutEmail || (!account.value.withoutEmail && account.value.email.value && typeof isEmail(account.value.email.value) !== 'string')
    })
    const user = computed(() => {
      return root.$store.getters.getUser
    })
    const country = computed(() => {
      return root.$store.getters.getCountry
    })
    const vehicle = computed(() => {
      return root.$store.getters.getVehicle
    })
    const isTransferVehicleRegistration = computed(() =>{
      return root.$route.query.forcedType === RegistrationTypes.HONDA_MAPIT_TRANSFERRED && root.$store.getters.getSelectedTransfer
    })

    onMounted(() => {
      account.value.email.rules.push((v: string) => {
        if (!v) return false
        return v !== user.value.login || root.$t('honda.registration.account.section_1.error-demobike');
      })
    })

    watch(() => account.value.email.value, function() {
      buttonKey.value++
    })
    watch(() => account.value.withoutEmail, function(val) {
      if(val) {
        const newAccount = accountRegistrationModel();
        newAccount.withoutEmail = val
        root.$store.dispatch('addAccount', newAccount)
      }else {
        root.$store.dispatch('addAccount', account.value)
      }
      errorDemoBike.value = false
      buttonKey.value++
    })

    watch(getAccount, function(val: any) {
      if(val.withoutEmail !== account.value.withoutEmail || account.value.email.value !== val.email.value){
        account.value = val
      }
    }, {deep: true})

    function submitForm(e: any) {
      if (enabledSearchButton.value) {
        checkUserEmail()
      }
      e.preventDefault();
    }
    function initNewAccount() {
      if(!isCreatedFromOffer.value){
        const newAccount = accountRegistrationModel();
        newAccount.email = account.value.email;
        newAccount.withoutEmail = account.value.withoutEmail
        goToSecondPart(newAccount)
      }else{
        goToSecondPart()
      }
    }
    function goToSecondPart(act = account.value) {
      if(act.email.value === user.value.login) {
        errorDemoBike.value = true
      } else {
        errorDemoBike.value = false
        root.$store.dispatch('addAccount', act).then(() => {
          emit('nextStep')
          closeDialog()
          buttonKey.value++
        })
      }
    }
    function backStep() {
      account.value.withoutEmail = false
      errorDemoBike.value = false
      if(!isTransferVehicleRegistration.value) emit('backStep')
      else root.$router.push({ name: 'TransferredVehicles' })
    }
    function checkUserEmail() {
      //@ts-ignore
      if (account.value.withoutEmail || (refs.SearchForm.validate() && enabledSearchButton.value)) {
        handleUserEmail()
      }
    }
    async function handleUserEmail() {
      if (account.value.withoutEmail) {
        account.value.email.value = ''
        return goToSecondPart()
      }
      if(account.value.email.value === user.value.login && !services.value['honda-mapit'].selected){
        errorDemoBike.value = true
        buttonKey.value++
        return
      } else {
        errorDemoBike.value = false
        warningEmail.value = false
        loadingUser.value = true

        if(process.env.VUE_APP_CORE_EMAIL_VALIDATION === 'true') {
          try {
            const {data: emailValidation} = await coreApi.validateEmail({email: account.value.email.value})

            if (!emailValidation.valid && !emailValidation.error) {
              warningEmail.value = true
            }
          } catch (e) {
            console.error("An unexpected error occurred while validating email.", e)
          }
        }

        return coreApi
          .getAccountByEmail(account.value.email.value)
          .then(({data}: any) => {
            const account = data[0]
            if (account) {
              if (account.address && (account.address.country || Country.Spain) !== country.value) {
                dialogWrongCountry.value = true
              } else if (account.id) {
                const registeredAccount = parseRegisteredAccount(account)
                goToSecondPart(registeredAccount)
              }
            } else showDialog()
          })
          .catch((e: any) => {
            console.log('Error signing docs: ', e)
            showDialog()
          })
          .finally(() => {
            loadingUser.value = false
            buttonKey.value++
          })
      }
    }
    function closeDialog() {
      dialog.value = false
    }
    function showDialog() {
      dialog.value = true
    }

    return {
      dialog,
      account,
      loadingUser,
      buttonKey,
      errorDemoBike,
      warningEmail,
      isCreatedFromOffer,
      services,
      isHondaMapit,
      getAccount,
      enabledSearchButton,
      user,
      vehicle,
      isTransferVehicleRegistration,
      dialogWrongCountry,
      searchEmail,
      submitForm,
      initNewAccount,
      backStep,
      checkUserEmail,
      closeDialog,
    }
  }
})
