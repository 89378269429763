











































import Vue, {PropType} from 'vue'
import {Country} from "@mapit/common-utils/dist/types";
import {findSecondLevelRegionByName, getCountry, PostalAddress} from "@/utils/administrative-region-helper";


export default Vue.extend({
  name: "AddressFormGroup",
  props: {
    value: Object as PropType<PostalAddress>,
    translationPrefix: String,
    dealerCountry: String as PropType<Country>
  },
  data() {
    return {
      address: this.value,
      countries: [
        {code: Country.Spain, label: this.$t(`countries.${Country.Spain}`)},
        {code: Country.Portugal, label: this.$t(`countries.${Country.Portugal}`)},
      ],
      region: findSecondLevelRegionByName(this.value.addressRegion, getCountry(this.value, this.dealerCountry)),
      country: getCountry(this.value, this.dealerCountry)
    }
  },
  watch: {
    region: {
      immediate: true,
      handler(val, oldVal) {
        if (val && val !== oldVal) {
          this.address.addressRegion = val.name
        }
      }
    },
  },
})
