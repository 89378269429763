<template>
  <div class="columns m-0 p-20">
    <div class="f-s-22 bold-900 full-width">
      <p class="p-l-10 p-r-10 m-0 align-left font-proxima-light">{{ $t("users.vehicleList.title") }}</p>
    </div>
    <div class="text-center p-40" v-if="loadingDetail">
      {{ $t("users.vehicleList.loading") }} <i class="form-icon loading p-l-20"></i>
    </div>
    <div class="columns m-0 p-0" v-if="selectedUser">
      <div class="col-md-12 columns m-0 p-5"
           v-for="container in selectedUser.containers"
           :key="container.containerId"
           :class="{'col-6': type > 1, 'col-12': type === 1}"
      >
        <container-detail
          :container="container"
          :selectedUser="selectedUser"
          :shop_profile="shop_profile"
          :type="type" />
      </div>
    </div>
  </div>
</template>

<script>
import ContainerDetail from '@/components/users/ContainerDetail';


export default {
  name: 'ContainersList',
  components: {
    ContainerDetail
  },
  props: ["selectedUser", "shop_profile", "loadingDetail"],
  data() {
    return {
      type: 1
    }
  }
};
</script>
<style>
</style>
