<template>
  <div class="opening-hours-schedule">
    <v-row no-gutters>
      <v-col class="col-md-12 margin-bottom-out">
        <label class="form-checkbox">
          <input data-cy="opens-on-saturday" type="checkbox" v-model="opensOnSaturdays" /><i class="form-icon"></i> {{ $t("honda.repair.repairshop.open-sat") }}
        </label>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col class='col-md-12'>
        <label class='label' style="font-size:13px;" for="morning-hours">{{ $t("honda.repair.repairshop.week-morn") }}</label>
        <opening-hours id="morning-hours" v-model="morningHours"/>
      </v-col>
      <v-col class='col-md-12'>
        <label class='label' for="afternoon-hours">{{ $t("honda.repair.repairshop.week-even") }}</label>
        <opening-hours id="afternoon-hours" v-model="afternoonHours"/>
      </v-col>
      <v-col class='col-md-12'>
        <label class='label' for="saturday-hours">{{ $t("honda.repair.repairshop.saturday") }}</label>
        <opening-hours id="saturday-hours" v-model="saturdayHours" :disabled='!opensOnSaturdays'/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import OpeningHours from "@/components/repairshop/OpeningHours"
  import moment from 'moment'

  export default {
    name: "OpeningHoursSchedule",
    components: {OpeningHours},
    props: {
      value: Array,
    },
    data: () => ({
      opensOnSaturdays: false,
      morningHours: null,
      afternoonHours: null,
      saturdayHours: null,
    }),
    created() {
      const weekdayStart = moment().startOf("isoWeek").startOf('day')
      const weekdayEnd = moment().startOf('isoWeek').endOf('day')
      const saturdayStart = weekdayStart.clone().add(5, 'days')
      const saturdayEnd = weekdayEnd.clone().add(5, 'days')

      const openingStr = this.value.join(", ")

      const morningOh = new opening_hours(openingStr)
      const intervals = morningOh.getOpenIntervals(weekdayStart.toDate(), weekdayEnd.toDate())
      this.morningHours = `${moment(intervals[0][0]).format("HH:mm")} - ${moment(intervals[0][1]).format("HH:mm")}`
      this.afternoonHours = `${moment(intervals[1][0]).format("HH:mm")} - ${moment(intervals[1][1]).format("HH:mm")}`

      const satIntervals = morningOh.getOpenIntervals(saturdayStart.toDate(), saturdayEnd.toDate())
      if (satIntervals.length > 0) {
        this.opensOnSaturdays = true
        this.saturdayHours = `${moment(satIntervals[0][0]).format("HH:mm")} - ${moment(satIntervals[0][1]).format("HH:mm")}`
      } else {
        this.opensOnSaturdays = false
      }
    },
    methods: {
      sch() {
        return [
          `Mo-Fr ${this.morningHours.replace(/ /g, '')}`,
          `Mo-Fr ${this.afternoonHours.replace(/ /g, '')}`,
          ...(this.opensOnSaturdays? [`Sa ${this.saturdayHours.replace(/ /g, '')}`] : [])
        ]
      }
    },
    watch: {
      morningHours: function(val) {
        this.$emit('input', this.sch())
      },
      afternoonHours: function(val) {
        this.$emit('input', this.sch())
      },
      saturdayHours: function(val) {
        this.$emit('input', this.sch())
      },
      opensOnSaturdays: function(val) {
        if (!this.saturdayHours) {
          this.saturdayHours = this.morningHours
        }
        this.$emit('input', this.sch())
      },
    }
  }
</script>

<style scoped>
  .label{
    font-family: 'ProximaNovaExCnLight','Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 14px;
    color: #888;
    letter-spacing: 1px;
    background: white;
    font-weight: bold;
  }
  .bold-900{
    font-size: 26px;
  }
  .form-select{
    font-family: 'Roboto', sans-serif;
    color: #888;
  }
</style>
