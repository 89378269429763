






















































































































































import { DealerBikesApi } from '@/api/dealerBikes.api'
import offerApi from '@/api/offers.api'
import { vinValidation } from "@/api/vehicle.api"
import { NUM_CHARACTERS_VALID_VIN } from '@/api/vehicle.api.js'
import MapitInput from '@/components/general/MapitInput.vue'
import { useModels } from '@/composables'
import { toCapitalize } from '@/helpers/utils'
import { isAlphanumeric, isNumberEvent } from '@/helpers/validations'
import i18n from '@/i18n'
import { ModelWithDescription } from '@/types'
import { InputTypes, RegistrationTypes } from '@/utils/constants'
import { mapGetters } from '@/utils/map-store'
import { createModelName } from '@/utils/utils'
import { accountRegistrationModel, subagentRegistrationModel, vehicleRegistrationModel } from '@/vuex/models'
import { SET_EX_DEALER_BIKE } from '@/vuex/mutation-types'
import { DealerBikeStatus } from '@mapit/dealer-bikes-dynamodb/dist/types'
import { OfferType, Status } from '@mapit/honda-offers-builder/dist/types'
import { Predelivery } from '@mapit/honda-offers-dynamodb/dist/types'
import {computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch} from '@vue/composition-api'
import { debounce, head } from 'lodash'
import moment from 'moment'
import FooterActions from '../FooterActions.vue'
import {closeAlert, showAlert} from "@/vuex/modules/ui";

export default defineComponent({
  name: 'Vehicle',
  components: { FooterActions, MapitInput },
  props: {
    step: {
      type: Number,
      required: true,
    },
  },
  setup(props, { root, refs, emit }) {
    const vinValidationError = ref<string>()
    const vinValidationSuccess = ref<string>()
    const vinLoading = ref(false)
    const offerNumberLoading = ref(false)
    const vehicle = ref(vehicleRegistrationModel())
    const subagent = ref(subagentRegistrationModel())
    const fixedModel = ref(false)
    const loading = ref(false)
    const buttonKey = ref(1)
    const forcedVin = ref(false)
    const offerResultSuccessMsg = ref(false)
    const offerResultErrorMsg = ref('')
    const offerModelSuccessMsg = ref('')
    const offerModelErrorMsg = ref('')
    const refreshKey = ref(0)
    const isValidForm = ref(false)
    const yesOrNoList = ref([
      { text: i18n.t(`buttons.yes`), value: true },
      { text: i18n.t(`buttons.no`), value: false },
    ])
    const selectedModel = ref()
    const refreshModelKey = ref(0)
    const abortController = ref(new AbortController())
    const predelivery = ref<Predelivery>()

    const debounceOfferSearch = debounce(handleOfferNumberChange, 100)
    const debounceCheckVinValidation = debounce(checkVinValidation, 100)

    const {
      getCountry: country,
      getBranch: branch,
      getOffer: offer,
      getGroup: group,
      getModelsList: modelsList,
      getOfferNumber: getOfferNumber,
      getSubagentSuggestion: suggestions,
      getExDealerBike: exDealerBike,
      getPermissions: permissions,
      getForcedType: forcedType,
      getOfferNumberValue: offerNumberValue,
    } = mapGetters(root.$store.getters)

    const subagentRules = computed(() => {
      return [(e: any) => e !== undefined || root.$t('honda.registration.vehicle.validations.subagent').toString()]
    })

    const isForcedHondaPlus = computed(() => {
      return forcedType.value === RegistrationTypes.HONDA_PLUS
    })

    const isCreatedFromOffer = computed(() => {
      return forcedType.value === RegistrationTypes.OFFER_REGISTRATION
    })

    const offerNumber = computed({
      get: () => offerNumberValue.value,
      set: (value) => {
        root.$store.commit('changeOfferNumber', value)
      },
    })

    const {
      models,
      loading: isModelsLoading,
      getSummaryByIdMaterial
    } = useModels({
      branch: branch.value,
    })

    onMounted(() => {
      if (root.$route.query.vin) {
        vehicle.value.vin.value = root.$route.query.vin as string
        forcedVin.value = true
      }
      if (!permissions.value.supportsSubagent) {
        // @ts-ignore
        subagent.value.exist = false
      }

      showAlert(root.$store.dispatch, 'info', 'honda.registration.offer-code-reminder', 15000)
    })

    onBeforeUnmount(() => {
      closeAlert(root.$store.dispatch)
    })

    watch(offerNumber, (value) => debounceOfferSearch(value))

    watch(
      () => vehicle.value.vin.value,
      (val) => {
        if (vinLoading.value) {
          abortController.value.abort()
          abortController.value = new AbortController()
          vinLoading.value = false
        }

        vinValidationError.value = ''
        vinValidationSuccess.value = ''
        vehicle.value.vin.status = null
        if (val.length === NUM_CHARACTERS_VALID_VIN) {

          const isAlphanum = isAlphanumeric(val)
          if (typeof isAlphanum !== 'string') {
            //si tenemos un "texto" de error
            debounceCheckVinValidation()
          } else {
            vinValidationError.value = isAlphanum
          }
        }
        if (fixedModel.value) {
          fixedModel.value = false
          vehicle.value.model.value = ''
        }
      },
    )

    watch(
      () => subagent.value.exist,
      (val) => {
        if (!val) {
          subagent.value.name = ''
          nextTick(() => {
            // @ts-ignore
            refs.nextStepBtn.$el.focus()
          })
        } else {
          nextTick(() => {
            // @ts-ignore
            refs.registrationSubagentName.$el.getElementsByTagName('input')[0].focus()
          })
        }
      },
    )

    watch(
      () => offer.value.vehicleModelName,
      (val) => {
        nextTick(() => {
          if (val && models.value && !fixedModel.value) {
            const exactModel = models.value.find((model) => model.description === val)
            if (exactModel) {
              vehicle.value.model.value = exactModel.description
            }
          }
        })
      },
    )
    watch(offer, (val) => {
      nextTick(() => {
        // @ts-ignore
        subagent.value.exist = val.type === OfferType.subagent
        subagent.value.name = val.subagentName || ''
        refreshKey.value++
      })
    })

    function handleVehicleKeyChangeValue(value: string) {
      vehicle.value.key.value = value.toUpperCase()
    }

    function handleSubagentChangeValue(value: any) {
      subagent.value.exist = value
    }

    function handleSubagentNameChangeValue(value: string) {
      subagent.value.name = toCapitalize(value)
    }

    function handleVINInput(value: string) {
      vehicle.value.vin.value = value.toUpperCase()
    }

    function backStep() {
      emit('backStep')
    }

    async function handleOfferNumberChange(value: string) {
      root.$store.dispatch('changeOfferNumber', value)
      if (value.length === 6) {
        offerNumberLoading.value = true
        offerApi
          .getOfferByCode(`${group.value.code}-${value}`, group.value.dealerId)
          .then(async (response) => {
            const { status, predelivery: predeliveryData } = response.data

            if (isOfferInvalid(status)) {
              offerNumberLoading.value = false
              offerResultSuccessMsg.value = false
              offerResultErrorMsg.value = root
                .$t('honda.registration.vehicle.section_1.lbl_offer_number_error_refused', {
                  status: root.$t(`offer-manager.create.step-offer-status.lbl-offer-status-${status}`),
                })
                .toString()
              return
            }

            root.$store.dispatch('setForcedType', RegistrationTypes.OFFER_REGISTRATION)

            if (predeliveryData) {
              predelivery.value = predeliveryData
              if (predelivery.value?.vehicle?.vin) vehicle.value.vin.value = predelivery.value.vehicle.vin
              else {
                const summary = await getSummaryByIdMaterial(predelivery.value?.vehicle?.idMat)

                if (summary) {
                  selectedModel.value = createModelName(summary.model)
                  setVehicleModel(summary?.hpConfig?.HPG?.available || false, selectedModel.value)
                } else {
                  offerModelSuccessMsg.value = ''
                  offerModelErrorMsg.value = root
                    .$t('honda.registration.vehicle.section_1.lbl_offer_number_error_model')
                    .toString()
                }
              }
            }

            const account = accountRegistrationModel()
            account.email.value = offer.value.clientEmail || ''
            account.firstName.value = offer.value.clientName || ''
            account.lastName.value = offer.value.clientSurname || ''
            account.phone.value = offer.value.clientPhone || ''
            account.dob.value = offer.value.clientDob || ''
            account.address.addressRegion.value = offer.value.clientProvince || ''
            root.$store.dispatch('addAccount', account)

            offerNumberLoading.value = false
            offerResultSuccessMsg.value = true
            offerResultErrorMsg.value = ''
          })
          .catch(() => {
            offerResultSuccessMsg.value = false
            offerResultErrorMsg.value = root.$t('honda.registration.vehicle.section_1.lbl_offer_number_error').toString()
            offerNumberLoading.value = false
          })
      } else {
        offerResultSuccessMsg.value = false
        offerResultErrorMsg.value = ''
        offerNumberLoading.value = false
      }
    }

    function submitForm(e: Event) {
      checkValidVehicle()
      e.preventDefault()
    }

    function isOfferInvalid(status: Status) {
      return [Status.Pending, Status.Refused].includes(status)
    }

    async function checkValidVehicle() {
      if (!loading.value) {
        loading.value = true
        const validExDealerBikeRegistrationNumber = exDealerBike.value
          ? true
          : await isRegistrationNumberFree(vehicle.value.registrationNumber.value)
        if (
          isValidForm.value &&
          vinValidationError.value === '' &&
          validExDealerBikeRegistrationNumber &&
          subagent.value.exist !== undefined
        ) {
          vehicle.value.registrationNumber.value = vehicle.value.registrationNumber.value.toUpperCase()
          vehicle.value.vin.value = vehicle.value.vin.value.toUpperCase()
          root.$store
            .dispatch('addVehicle', vehicle.value)
            .then(() => {

              root.$store
                .dispatch('addSubagent', subagent.value)
                .then(() => nextStep())
                .catch((err) => console.log('Error add vehicle', err))
                .finally(() => {
                  loading.value = false
                  buttonKey.value++
                })
            })
            .catch((err) => {
              loading.value = false
              buttonKey.value++
              console.log('Error add vehicle', err)
            })
        } else {
          loading.value = false
          buttonKey.value++
        }
      }
    }
    function nextStep() {
      closeAlert(root.$store.dispatch)
      emit('nextStep')
    }

    async function setupRegistrationUsingVin(vin: string) {
      const dealerBikesApi = new DealerBikesApi()
      const [maybeExDealerBike, vinCheckResult] = await Promise.all([
        dealerBikesApi.getDealerBikeByVin(vin, abortController.value.signal)
          .then(res => head(res.data.data.filter(db => db.status === DealerBikeStatus.Retired))).catch(() => ({})),
        vinValidation(vin, abortController.value.signal)
      ])
      root.$store.commit(SET_EX_DEALER_BIKE, maybeExDealerBike)
      return vinCheckResult
    }

    async function setVehicleModel(hasHondaPlusGo: boolean, modelName: string){
      await root.$store.dispatch('setHasGoModel', hasHondaPlusGo)
      const exactModel = models.value.find((model) => model.description === modelName)
      const forcedModel = { name: modelName, description: modelName, category: '', cc: '', group: '', type: '', kw: '' } as ModelWithDescription
      if (!exactModel) {
        models.value.push(forcedModel)
      }

      selectedModel.value = exactModel?.description || forcedModel.description
      vehicle.value.model.value = selectedModel.value
      fixedModel.value = true
      offerModelSuccessMsg.value = ''
      offerModelErrorMsg.value = ''
    }

    async function checkVinValidation() {
      vinLoading.value = true

      const result = await setupRegistrationUsingVin(vehicle.value.vin.value)

      vehicle.value.vin.status = result.key

      if (result.model) {
        let hasHondaPlusGo = false
        if (result.idMat) {
          const summary = await getSummaryByIdMaterial(result.idMat, abortController.value.signal)
          hasHondaPlusGo = summary?.hpConfig?.HPG?.available || false
        }

        setVehicleModel(hasHondaPlusGo, result.model)
      } else if (predelivery.value) {
        const summary = await getSummaryByIdMaterial(predelivery.value.vehicle.idMat)

        if (summary) {
          selectedModel.value = createModelName(summary.model)
          setVehicleModel(summary?.hpConfig?.HPG?.available || false, selectedModel.value)
        } else {
          selectedModel.value = ''
          vehicle.value.model.value = ''
          offerModelSuccessMsg.value = ''
          offerModelErrorMsg.value = root
            .$t('honda.registration.vehicle.section_1.lbl_offer_number_error_model')
            .toString()
        }
      }

      if (exDealerBike.value) {
        vehicle.value.registrationNumber.value = exDealerBike.value.vehicle.registrationNumber
      }
      if (result.error) {
        vinValidationError.value = i18n.t(result.status).toString()
      } else if (
        vehicle.value.vin.status !== 'NO_VIN_BDD' &&
        exDealerBike.value &&
        moment().diff(exDealerBike.value.vehicle.registrationDate, 'years') > 0
      ) {
        if (isForcedHondaPlus.value) {
          vinValidationError.value = i18n
            .t('honda.registration.vehicle.validations.vin_invalid_ex_demo_more_one_year')
            .toString()
        } else {
          vinValidationSuccess.value = i18n.t('honda.registration.vehicle.validations.vin_invalid_ex_demo').toString()
        }
      } else {
        if (vehicle.value.vin.status === 'NO_VIN_BDD') {
          if (isForcedHondaPlus.value) {
            vinValidationError.value = exDealerBike.value
              ? i18n.t('honda.registration.vehicle.validations.vin_invalid_ex_demo').toString()
              : i18n.t(result.status).toString()
          } else {
            vinValidationError.value = exDealerBike.value
              ? i18n.t('honda.registration.vehicle.validations.vin_invalid_ex_demo').toString()
              : i18n.t(result.status).toString()
          }
        } else {
          vinValidationSuccess.value = exDealerBike.value
            ? i18n.t('honda.registration.vehicle.validations.vin_valid_ex_demo').toString()
            : i18n.t(result.status).toString()
        }
      }

      vinLoading.value = false
      nextTick(() => {
        refreshModelKey.value++
        // @ts-ignore
        refs.vehicleForm.validate()
      })
    }

    function isNumber(evt: Event) {
      return isNumberEvent(evt)
    }

    function setModel(model: ModelWithDescription) {
      vehicle.value.model.value = model.description
      offerModelErrorMsg.value = ''
    }

    async function isRegistrationNumberFree(registrationNumber: string) {
      try {
        const bikeWithPlate = await root.$store.dispatch('loadVehicleByRegistrationNumber', registrationNumber)
        if (bikeWithPlate.data.data.length > 0) {
          root.$store.dispatch('setAlertResult', {
            type: 'error',
            stringId: 'honda.registration.vehicle.validations.registration_number_used',
            open: true,
          })
          return false
        } else {
          return true
        }
      } catch (err) {
        console.error('Error isRegistrationNumberFree:', err)
        return true
      }
    }

    return {
      InputTypes,
      toCapitalize,
      subagentRules,
      vinValidationError,
      vinValidationSuccess,
      vinLoading,
      offerNumberLoading,
      vehicle,
      subagent,
      fixedModel,
      loading,
      buttonKey,
      forcedVin,
      offerNumber,
      offerResultSuccessMsg,
      offerResultErrorMsg,
      offerModelSuccessMsg,
      offerModelErrorMsg,
      refreshKey,
      isValidForm,
      yesOrNoList,
      selectedModel,
      isModelsLoading,
      refreshModelKey,
      isForcedHondaPlus,
      isCreatedFromOffer,
      country,
      branch,
      exDealerBike,
      offer,
      modelsList,
      getOfferNumber,
      suggestions,
      models,
      permissions,
      handleVehicleKeyChangeValue,
      handleSubagentChangeValue,
      handleSubagentNameChangeValue,
      handleVINInput,
      submitForm,
      isOfferInvalid,
      checkValidVehicle,
      nextStep,
      debounceCheckVinValidation,
      isNumber,
      setModel,
      isRegistrationNumberFree,
      backStep
    }
  },
})
