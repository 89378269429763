import ApiGatewayClient from "./aws/aws-gateway-client"
import {OpenSearchListRequest, OpenSearchResponse} from '@/types'
import {RegistrationDoc} from "@mapit/opensearch-client/dist/types";
import {compactObject} from "@/utils/utils";

export class RegistrationsApi {

  private readonly client: ApiGatewayClient

  constructor() {
    this.client = new ApiGatewayClient()
  }

  async getRegistrations(request: OpenSearchListRequest): Promise<OpenSearchResponse<RegistrationDoc>> {
    const {params, dealer, text, pagination} = request

    const queryParams = {
      dealerCode: dealer.code,
      text: text?.split(' ').filter(i => i) || '',
      from: pagination?.from || 0,
      size: pagination?.size || 10,
      sort: pagination?.sort || 'desc',
      column: pagination?.column || 'createDate',
      ...params
    }

    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MUVIO_MIMIR_API,
      method: 'GET',
      path: `/v1/dealers/${dealer.id}/registrations`,
      queryParams: compactObject(queryParams)
    }).then((response: any) => response.data)
  }

  getRegistration(dealerId:string, registrationId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MUVIO_MIMIR_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/registrations/${registrationId}`
    })
  }

  createRegistration(registration: any) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/registrations`,
      data: registration
    })
  }


  deleteRegistration(registrationId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'DELETE',
      path: `/v1/registrations/${registrationId}`
    })
  }

  markRegistrationAsCompleted(registrationId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/registrations/${registrationId}/account-registration-completion`
    })
  }

  signRegistration(registrationId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/registrations/${registrationId}/sign`
    })
  }

  archiveRegistration(registrationId: string, archive = true) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'PATCH',
      path: `/v1/registrations/${registrationId}`,
      data: {archived: archive}
    })
  }

  unarchiveRegistration(registrationId: string) {
    return this.archiveRegistration(registrationId, false)
  }

  getPendingRegistrations(dealerId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/registrations`
    })
  }

  getRegistrationContracts(registrationId: string) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/registrations/${registrationId}/contracts`
    })
  }

}

export default new RegistrationsApi()
