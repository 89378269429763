import ApiGatewayClient from "./aws/aws-gateway-client"
import { getNotificationModel, getArchivedModel, NOTIFICATIONS_PARAMS } from '@/vuex/models/notifications'

const { NOTIFICATION_TYPE_EMAIL, REGISTER_AND_DOWNLOAD_APP_NOTIFICATION_NAME, CONTRACT_HP_NOTIFICATION_NAME } = NOTIFICATIONS_PARAMS
export class CoreApi {

  constructor() {
    this.client = new ApiGatewayClient()
  }

  getLegacyToken() {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
        method: 'GET',
        path: `/v1/legacy/token`,
      })
  }

  createRegistration(registration) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/registrations`,
      data: registration
    })
  }


  deleteRegistration(registrationId) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'DELETE',
      path: `/v1/registrations/${registrationId}`
    })
  }


  sendEmailDownloadApp(registration){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/notifications`,
      data: getNotificationModel({registrationId: registration.id}, NOTIFICATION_TYPE_EMAIL, REGISTER_AND_DOWNLOAD_APP_NOTIFICATION_NAME)
    })
  }


  sendEmailContractHondaPlus(registrationId){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/notifications`,
      data: getNotificationModel({registrationId}, NOTIFICATION_TYPE_EMAIL, CONTRACT_HP_NOTIFICATION_NAME)
    })
  }

  markRegistrationAsCompleted(registrationId){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/registrations/${registrationId}/account-registration-completion`
    })
  }

  signRegistration(registrationId){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/registrations/${registrationId}/sign`
    })
  }

  archiveRegistration(registrationId, archive = true){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'PATCH',
      path: `/v1/registrations/${registrationId}`,
      data: getArchivedModel(archive)
    })
  }

  getPendingRegistrations(dealerId){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/registrations`
    })
  }

  getArchivedPendingRegistrations(dealerId){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/registrations/archived`
    })
  }

  getRegistrationContracts(registrationId){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/registrations/${registrationId}/contracts`
    })
  }

  getAccountByEmail(email){
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: '/v1/accounts',
      queryParams: {
        email
      }
    })
  }

  getDealer(dealerId) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}`
    })
  }

  updateDealer(dealerId, data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'PATCH',
      path: `/v1/dealers/${dealerId}`,
      data
    })
  }

  listVehiclesTransferredByDealer(dealerId, status) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/device-transfers`,
      queryParams: { status }
    })
  }

  addDeviceToVehicle(deviceId, data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/devices/${deviceId}/attachment`,
      data
    })
  }

  getVehicle(vehicleId) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/vehicles/${vehicleId}`,
    })
  }

  getVehicleByRegistrationNumber(regnum) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: '/v1/vehicles/',
      queryParams: { regnum }
    })
  }

  deleteVehicle(vehicleId, data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'DELETE',
      path: `/v1/vehicles/${vehicleId}`,
      data
    })
  }

  getVinStatus(vin, signal) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/vehicle-specs/${vin}`,
      signal
    })
  }

  getDealersList(internal = false) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers`,
      queryParams: { internal }
    })
  }

  getDealerRegistrationStats(dealerId, month) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/reports/registration-counts-by-dealer`,
      queryParams: { month }
    })
  }

  //Users Management
  getUserCredential(dealerId) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/dealers/${dealerId}/cognito-users`
    })
  }
  postUserCredential(dealerId, data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/dealers/${dealerId}/cognito-users`,
      data
    })
  }
  patchUserCredential(dealerId, userId, data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'PATCH',
      path: `/v1/dealers/${dealerId}/cognito-users/${userId}`,
      data
    })
  }
  deleteUserCredential(dealerId, userId) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'DELETE',
      path: `/v1/dealers/${dealerId}/cognito-users/${userId}`
    })
  }
  resendApprovalEmail(dealerId, userId) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'PUT',
      path: `/v1/dealers/${dealerId}/cognito-users/${userId}/approve`
    })
  }

  getDevicesByImei(imei) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'GET',
      path: `/v1/devices`,
      queryParams: {imei}
    })
  }

  validateEmail(data) {
    return this.client.sendRequest({
      endpoint: process.env.VUE_APP_MAPIT_CORE_API,
      method: 'POST',
      path: `/v1/validation`,
      data
    })
  }

}

export default new CoreApi()
